import React, { useState, createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import CostData from '../containers/CostData';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node, PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export const CostDataContext = createContext();

const CostDataProvider = ({ children }) => {
  const [data, setData] = useState({});
  const [unitInfo, setUnitInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const value = useMemo(() => ({
    data,
    unitInfo,
    setData,
    setUnitInfo,
    isLoading,
    setIsLoading,
  }), [data, unitInfo, isLoading]);

  return (
    <CostDataContext.Provider value={value}>
      <CostData>
        {children}
      </CostData>
    </CostDataContext.Provider>
  );
};

CostDataProvider.propTypes = propTypes;

export default CostDataProvider;
