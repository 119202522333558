import React from 'react';
import { SimpleGrid, Text } from '@chakra-ui/react';
import FormInput from '../FormInput';
import { sizes } from '../../theme';

const propTypes = {};
const defaultProps = {};

const ContactInformation = () => (
  <>
    <Text variant="mdBold" marginBottom={sizes._8px}>
      Contact Information
    </Text>
    <SimpleGrid columns={{ sm: 1, md: 2 }} columnGap={4}>
      <FormInput name="firstName" label="First Name" autoComplete="given-name" />
      <FormInput name="lastName" label="Last Name" autoComplete="family-name" />
      <FormInput
        autoComplete="email"
        name="email"
        label="Email address"
      />
      <FormInput
        autoComplete="tel"
        mask="(000) 000-0000"
        type="tel"
        name="phone"
        label="Phone number"
      />
    </SimpleGrid>
  </>
);

ContactInformation.propTypes = propTypes;
ContactInformation.defaultProps = defaultProps;

export default ContactInformation;
