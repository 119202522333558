import React from 'react';
import {
  Box,
  Button,
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { DESKTOP_WIDTH } from '../../theme/media';
import useWidthQuery from '../../hooks/UseWidth';
import * as style from './ProgressBar.module.scss';

const propTypes = {};
const defaultProps = {};

const ProgressBar = ({ step, steps }) => {
  const isLargerThan1280 = useWidthQuery(DESKTOP_WIDTH);
  const currentIndex = steps.findIndex((s) => s.identifier === step.identifier);

  const { preheader, header, onClose } = step;

  const fullHeader = (
    <>
      <Text variant="xsBold" sx={{ textTransform: 'uppercase' }} color="gray.lightText">
        {preheader}
      </Text>
      <Text variant="header">
        {header}
      </Text>
    </>
  );

  const stepper = (
    <div className={style.steps}>
      {steps.map((s, i) => (
        <Box key={s.identifier} className={i > currentIndex ? style.futureStep : style.step} marginBottom={{ lg: '30px' }}>
          <Text
            variant={{ base: 'xxsBold', lg: 'smBold' }}
            color="gray.lightText"
            className={i !== currentIndex && style.currentStepText}
            paddingTop={{ base: '4px', lg: '8px' }}
          >
            {s.progressText}
          </Text>
        </Box>
      ))}
    </div>
  );

  const closeBtn = onClose && (
    <GridItem align="right">
      <Button rightIcon={<CloseIcon />} size="sm" onClick={onClose}>
        Close
      </Button>
    </GridItem>
  );

  return (
    <Box borderBottom="4px solid" borderColor="gray.lightBorder" paddingBottom={{ base: '24px', lg: '0' }} marginBottom="16px">
      {isLargerThan1280 ? (
        <Grid templateColumns="4fr 2fr 1fr" gap={4}>
          <GridItem>
            {fullHeader}
          </GridItem>
          <GridItem sx={{ position: 'relative', top: '30%' }}>
            {stepper}
          </GridItem>
          {closeBtn}
        </Grid>
      ) : (
        <>
          <Grid templateColumns="3fr 1fr">
            <GridItem>
              {fullHeader}
            </GridItem>
            {closeBtn}
          </Grid>
          {stepper}
        </>
      )}
    </Box>
  );
};

ProgressBar.propTypes = propTypes;
ProgressBar.defaultProps = defaultProps;

export default ProgressBar;
