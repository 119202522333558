import React from 'react';
import {
  Box,
  Grid,
  GridItem,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { TooltipIcon } from '../Icons';
import { sizes } from '../../theme';
import useCostData from '../../hooks/UseCostData';
import * as style from './TodaysCharges.module.scss';

const propTypes = {};
const defaultProps = {};

const TodaysCharges = () => {
  const { data: {
    discounts,
    charges,
    dueToday,
    totalTaxes,
  } } = useCostData();

  return (
    <Box border="2px solid" borderColor="gray.lightBorder" borderRadius="md" padding={sizes._12px} position="relative">
      <Text variant="smBold" color="gray.lightText" marginBottom={sizes._12px}>
        {"TODAY'S TOTAL CHARGES"}
      </Text>
      <Grid templateColumns="3fr 1fr" rowGap={sizes._12px}>
        {discounts?.length > 0 && (
          discounts.map((discount) => (
            <React.Fragment key={discount.description}>
              <GridItem>
                <Text variant="sm" color="green.accent">
                  {discount.description}
                  {discount.tooltip && (
                    <Tooltip label={discount.tooltip}>
                      <TooltipIcon className={style.tooltip} />
                    </Tooltip>
                  )}
                </Text>
              </GridItem>
              <GridItem>
                <Text variant="mdBold" align="right" color="red.accent">
                  -${discount.amount}
                </Text>
              </GridItem>
            </React.Fragment>
          ))
        )}
        {(charges?.length > 0) && (
          charges.map((charge) => (
            <React.Fragment key={charge.description}>
              <GridItem>
                <Text variant="sm">
                  {charge.description}
                  {charge.tooltip && (
                    <Tooltip label={charge.tooltip}>
                      <TooltipIcon className={style.tooltip} />
                    </Tooltip>
                  )}
                </Text>
              </GridItem>
              <GridItem>
                <Text variant="mdBold" align="right">
                  ${charge.amount}
                </Text>
              </GridItem>
            </React.Fragment>
          ))
        )}
        {totalTaxes && (
          <>
            <GridItem>
              <Text variant="sm">
                Tax
              </Text>
            </GridItem>
            <GridItem>
              <Text variant="mdBold" align="right">
                ${totalTaxes}
              </Text>
            </GridItem>
          </>
        )}
        <GridItem borderTop="2px solid" borderColor="gray.lightBorder" paddingTop={sizes._14px}>
          <Text variant="md">
            Your Payment Today
          </Text>
        </GridItem>
        <GridItem borderTop="2px solid" borderColor="gray.lightBorder" paddingTop={sizes._14px}>
          <Text variant="lgBold" align="right">
            ${dueToday}
          </Text>
        </GridItem>
      </Grid>
    </Box>
  );
};

TodaysCharges.propTypes = propTypes;
TodaysCharges.defaultProps = defaultProps;

export default TodaysCharges;
