/* eslint-disable max-len */
import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from 'react';
import { fetchConfiguration } from '../modules/api';
import RC2Insurances from './RC2Insurances';

// While Setting this make sure that configuration is updated in the schema as well

const CUSTOM_EVENT_TRIGGER_NAME = process.env.CUSTOM_EVENT_TRIGGER_NAME || 'storableOmi';
const defaultConfig = {
  facilityId: '',
  companyId: '',
  providerId: '',
  name: '',
  description: '',
  schema: '',
  configuration: {
    themification: {
      primaryColor: '#FFCC00',
      secondaryColor: '#FF0064',
      tertiaryColor: '#FE8240',
      fontFamily: 'Helvetica',
      buttonBorderRadius: '6',
      buttonTextStyle: 'capitalize',
    },
    insurance: { enabled: true,
      allowTenantsToBuyInsurance: true,
      name: 'Protection Plan',
      description: '',
      brochureUrl: '',
      insuranceKey: 'insuranceCoverageId',
      autoProtectDisclaimerText: '',
      PrivateInsuranceDisclaimerText: '' },
    accessControl: { showGateCodeAtEnd: false },
    providerConfig: {
      showLock: false,
      lienholderCount: 0,
      vehicleCount: 1,
      contactCount: 1,
      dlImage: false,
      unitsAvailabilityCountDisplayThreshold: 1,
    },
    reservationConfig: { unitId: '', reservationId: '' },
    omiMailConfig: {
      paymentConfirmation: true,
      leaseSignedConfirmation: true,
      enableLogo: true,
      logoImageUrl: 'http://static.sparefoot.com/emails/sparefoot_email_logo.gif',
      logoImageText: 'sparefoot',
    },
  },
};
const defaultConfigSL = {
  facilityId: '',
  companyId: '',
  providerId: '',
  name: '',
  description: '',
  schema: '',
  configuration: {
    themification: {
      primaryColor: '#FFCC00',
      secondaryColor: '#FF0064',
      tertiaryColor: '#FE8240',
      fontFamily: 'Helvetica',
      buttonBorderRadius: '6',
      buttonTextStyle: 'capitalize',
    },
    insurance: {
      enabled: true,
      allowTenantsToBuyInsurance: true,
      name: 'Protection Plan',
      description: '',
      brochureUrl: '',
      insuranceKey: 'insuranceCoverageId',
      autoProtectDisclaimerText: '',
      PrivateInsuranceDisclaimerText: '',
    },
    accessControl: { showGateCodeAtEnd: false },
    providerConfig: {
      showLock: false,
      lienholderCount: 0,
      vehicleCount: 1,
      contactCount: 1,
      dlImage: false,
      unitsAvailabilityCountDisplayThreshold: 1,
    },
    reservationConfig: { unitId: '', reservationId: '' },
    omiMailConfig: {
      paymentConfirmation: true,
      leaseSignedConfirmation: true,
      enableLogo: true,
      logoImageUrl: 'http://static.sparefoot.com/emails/sparefoot_email_logo.gif',
      logoImageText: 'sparefoot',
    },
  },
};

const defaultConfigSE = {
  facilityId: '',
  companyId: '',
  providerId: '',
  name: '',
  description: '',
  schema: '',
  configuration: {
    themification: {
      primaryColor: '#FFCC00',
      secondaryColor: '#FF0064',
      tertiaryColor: '#FE8240',
      fontFamily: 'Helvetica',
      buttonBorderRadius: '6',
      buttonTextStyle: 'capitalize',
    },
    insurance: {
      enabled: true,
      allowTenantsToBuyInsurance: true,
      name: 'Protection Plan',
      description: '',
      brochureUrl: '',
      insuranceKey: 'insuranceId',
      autoProtectDisclaimerText: '',
      PrivateInsuranceDisclaimerText: '',
    },
    accessControl: { showGateCodeAtEnd: false },
    providerConfig: {
      showLock: true,
      lienholderCount: 3,
      vehicleCount: 3,
      contactCount: 3,
      dlImage: false,
      unitsAvailabilityCountDisplayThreshold: 1,
    },
    reservationConfig: { unitId: '', reservationId: '' },
    omiMailConfig: {
      paymentConfirmation: true,
      leaseSignedConfirmation: true,
      enableLogo: true,
      logoImageUrl: 'http://static.sparefoot.com/emails/sparefoot_email_logo.gif',
      logoImageText: 'sparefoot',
    },
  },
};
const OMIConfigContext = createContext({
  configuration: defaultConfig.configuration,
  config: defaultConfig,
  refreshConfig: () => {},
  triggerEvent: () => {},
});

export const useConfig = () => useContext(OMIConfigContext);

const useConfigProvider = (params) => {
  const {
    facilityId,
    organizationId,
    providerId,
    unitId,
    reservationId,
    overrideConfig = {},
  } = params;

  const [config, setConfig] = useState(defaultConfig);

  const fetchConfig = useCallback(() => {
    // if config Id is given we will follow that or we are going to use the existing values
    const payload = { appIdentifier: 'OMI' };
    const configParams = {};
    if (facilityId) { configParams.facilityId = facilityId; }
    if (params.config) { configParams.name = params.config; }
    if (params.source) {
      configParams.source = params.source;
    } else {
      configParams.source = 'sparefoot';
    }
    if (organizationId) { configParams.companyId = organizationId; }
    if (providerId) { configParams.providerId = providerId; }
    payload.configParams = configParams;
    fetchConfiguration(payload)
      .then((configData) => {
        if (configData) {
          const finalConfig = providerId === 'sl' ? { ...defaultConfigSL, ...configData, ...overrideConfig } : { ...defaultConfigSE, ...configData, ...overrideConfig };
          finalConfig.params = params || {};
          if (!params.source) {
            finalConfig.params.source = 'sparefoot';
          }
          let finalVehicleCount = finalConfig.configuration.providerConfig.vehicleCount;
          if (providerId === 'sl' && finalVehicleCount > 1) {
            finalVehicleCount = 1;
          } else if (providerId === 'se' && finalVehicleCount > 3) {
            finalVehicleCount = 3;
          }
          finalConfig.configuration.providerConfig.vehicleCount = finalVehicleCount;
          let finalContactCount = finalConfig.configuration.providerConfig.contactCount;
          if (providerId === 'sl' && finalContactCount > 1) {
            finalContactCount = 1;
          } else if (providerId === 'se' && finalContactCount > 3) {
            finalContactCount = 3;
          }
          finalConfig.configuration.providerConfig.contactCount = finalContactCount;
          let finalLienholderCount = finalConfig.configuration.providerConfig.lienholderCount;
          if (providerId === 'sl' && finalLienholderCount > 1) {
            finalLienholderCount = 1;
          } else if (providerId === 'se' && finalLienholderCount > 3) {
            finalLienholderCount = 3;
          }
          finalConfig.configuration.providerConfig.lienholderCount = finalLienholderCount;
          setConfig(finalConfig);
        } else {
          const updatedConfig = providerId === 'sl' ? { ...defaultConfigSL } : { ...defaultConfigSE };
          updatedConfig.facilityId = facilityId;
          updatedConfig.organizationId = organizationId;
          updatedConfig.providerId = providerId;
          updatedConfig.configuration.reservationConfig.unitId = unitId;
          updatedConfig.configuration.reservationConfig.reservationId = reservationId;
          updatedConfig.params = params || {};
          const insuranceInformation = RC2Insurances.find((x) => x.corpCode === organizationId && x.locCode === facilityId);
          if (insuranceInformation) {
            updatedConfig.configuration.insurance.name = insuranceInformation.insuranceName;
            updatedConfig.configuration.insurance.description = insuranceInformation.insuranceDescription;
          }

          setConfig({ ...updatedConfig, ...overrideConfig });
        }
      })
      .catch((error) => {
        console.error('Error fetching configuration', error);
        const updatedConfig = providerId === 'sl' ? { ...defaultConfigSL } : { ...defaultConfigSE };
        updatedConfig.facilityId = facilityId;
        updatedConfig.organizationId = organizationId;
        updatedConfig.providerId = providerId;
        updatedConfig.configuration.reservationConfig.unitId = unitId;
        updatedConfig.configuration.reservationConfig.reservationId = reservationId;
        updatedConfig.params = params || {};
        const insuranceInformation = RC2Insurances.find((x) => x.corpCode === organizationId && x.locCode === facilityId);
        if (insuranceInformation) {
          updatedConfig.configuration.insurance.name = insuranceInformation.insuranceName;
          updatedConfig.configuration.insurance.description = insuranceInformation.insuranceDescription;
        }

        setConfig({ ...updatedConfig, ...overrideConfig });
      });
  }, [params.configId]);

  useEffect(fetchConfig, [params.configId]);

  const triggerEvent = useCallback((eventName, eventData) => {
    try {
      const customEvent = new CustomEvent(CUSTOM_EVENT_TRIGGER_NAME, {
        detail: { eventName, data: eventData },
      });
      window.dispatchEvent(customEvent);
    } catch (error) {
      console.error('Error in triggerEvent:', error);
    }
  }, []);
  return {
    config,
    configuration: config.configuration,
    refreshConfig: fetchConfig,
    triggerEvent,
  };
};

export const ConfigurationProvider = ({ children, params }) => {
  const providerValue = useConfigProvider(params);
  return (
    <OMIConfigContext.Provider value={providerValue}>
      {children}
    </OMIConfigContext.Provider>
  );
};
