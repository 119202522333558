import React from 'react';
import { useFormContext } from 'react-hook-form';
import { updateContactData } from '../modules/api';
import useUnitData from '../hooks/UseUnitData';
import { SET_IS_LOADING, PASSWORD_SUBMITTED } from '../components/MoveIn/reducer';
import MoveIn from '../components/MoveIn';

const propTypes = {};
const defaultProps = {};

const ContactUpdate = (props) => {
  const { data: { reservation: { contact } } } = useUnitData();
  const { getValues } = useFormContext();
  const {
    password,
    securityQuestionsRequired,
    securityQuestionOne,
    securityQuestionTwo,
    securityAnswerOne,
    securityAnswerTwo,
  } = getValues();

  const onUpdateContact = ({ dispatch, onStepChange }) => {
    dispatch({ type: SET_IS_LOADING, payload: { loading: true } });

    const requestData = {
      contact,
      password,
      securityQuestionsRequired,
      securityQuestionOne,
      securityQuestionTwo,
      securityAnswerOne,
      securityAnswerTwo,
    };

    updateContactData(requestData)
      .then((res) => res.json())
      .then((res) => {
        if (res.contact?.error) {
          dispatch({ type: SET_IS_LOADING, payload: { loading: false, errors: res.errors } });
          window.storableOmi?.onError?.({ action: 'setPassword', errors: res.errors });
        }
        if (res.contact) {
          dispatch({ type: PASSWORD_SUBMITTED });
          onStepChange?.();
        } else {
          dispatch({ type: SET_IS_LOADING, payload: { loading: false, errors: [res.error] } });
          onStepChange?.();
        }
      })
      .catch((e) => {
        dispatch({
          type: SET_IS_LOADING, payload: { loading: false, errors: [e] },
        });
        window.storableOmi?.onError?.({ action: 'setPassword', errors: [e] });
      });
  };

  return <MoveIn {...props} onUpdateContact={onUpdateContact} />;
};

ContactUpdate.propTypes = propTypes;
ContactUpdate.defaultProps = defaultProps;

export default ContactUpdate;
