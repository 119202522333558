import React from 'react';
import { Global } from '@emotion/react';

const css = String.raw;

const vhPolyfill = css`
  :root,
  :host {
    --chakra-vh: 100vh;
  }
  @supports (height: -webkit-fill-available) {
    :root,
    :host {
      --chakra-vh: -webkit-fill-available;
    }
  }
  @supports (height: -moz-fill-available) {
    :root,
    :host {
      --chakra-vh: -moz-fill-available;
    }
  }
  @supports (height: 100dvh) {
    :root,
    :host {
      --chakra-vh: 100dvh;
    }
  }
`;

export const CSSPolyfill = () => <Global styles={vhPolyfill} />;

const CSSReset = ({ scope = '' }) => (
  <Global
    styles={css`
      ${scope} {
        font-family: var(--chakra-fonts-body);
        color: var(--chakra-colors-chakra-body-text);
        background: var(--chakra-colors-chakra-body-bg);
        transition-property: background-color;
        transition-duration: var(--chakra-transition-duration-normal);
        line-height: var(--chakra-lineHeights-base);
      }
      ${scope} *, ${scope} ::before, ${scope} ::after {
        border-color: var(--chakra-colors-chakra-border-color);
      }
      ${scope} :where(html) {
        line-height: 1.5;
        -webkit-text-size-adjust: 100%;
        font-family: system-ui, sans-serif;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        touch-action: manipulation;
      }
      ${scope} :where(body) {
        position: relative;
        min-height: 100%;
        margin: 0;
        font-feature-settings: "kern";
      }
      ${scope} :where(*, *::before, *::after) {
        border-width: 0 ;
        border-style: solid;
        box-sizing: border-box;
        word-wrap: break-word;
      }
      ${scope} :where(main) {
        display: block;
      }
      ${scope} hr {
        border-top-width: 1px;
        box-sizing: content-box;
        height: 0;
        overflow: visible;
      }
      ${scope} :where(pre, code, kbd,samp) {
        font-family: SFMono-Regular, Menlo, Monaco, Consolas, monospace;
        font-size: 1em;
      }
      ${scope} a {
        background-color: transparent;
        color: inherit;
        text-decoration: inherit;
      }
      ${scope} abbr[title] {
        border-bottom: none;
        text-decoration: underline;
        -webkit-text-decoration: underline dotted;
        text-decoration: underline dotted;
      }
      ${scope} :where(b, strong) {
        font-weight: bold;
      }
      ${scope} small {
        font-size: 80%;
      }
      ${scope} :where(sub,sup) {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
      }
      ${scope} sub {
        bottom: -0.25em;
      }
      ${scope} :where(sup) {
        top: -0.5em;
      }
      ${scope} :where(img) {
        border-style: none;
      }
      ${scope} :where(button, input, optgroup, select, textarea) {
        font-family: inherit;
        font-size: 100%;
        line-height: 1.15;
        margin: 0;
      }
      ${scope} :where(button, input) {
        overflow: visible;
      }
      ${scope} :where(button, select) {
        text-transform: none;
      }
      
      ${scope} fieldset {
        padding: 0.35em 0.75em 0.625em;
      }
      ${scope} legend {
        box-sizing: border-box;
        color: inherit;
        display: table;
        max-width: 100%;
        padding: 0;
        white-space: normal;
      }
      ${scope} progress {
        vertical-align: baseline;
      }
      ${scope} textarea {
        overflow: auto;
      }
      ${scope} :where([type="checkbox"], [type="radio"]) {
        box-sizing: border-box;
        padding: 0;
      }
      ${scope} input[type="number"]::-webkit-inner-spin-button,
      ${scope} input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none !important;
      }
      ${scope} input[type="number"] {
        -moz-appearance: textfield;
      }
      ${scope} input[type="search"] {
        -webkit-appearance: textfield;
        outline-offset: -2px;
      }
      ${scope} input[type="search"]::-webkit-search-decoration {
        -webkit-appearance: none !important;
      }
      ${scope} ::-webkit-file-upload-button {
        -webkit-appearance: button;
        font: inherit;
      }
      ${scope} details {
        display: block;
      }
      ${scope} summary {
        display: list-item;
      }
      ${scope} template {
        display: none;
      }
      [hidden] {
        display: none !important;
      }
      ${scope} :where(
          blockquote,
          dl,
          dd,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          hr,
          figure,
          p,
          pre
        ) {
        margin: 0;
      }
      ${scope} :where(button) {
        background: var(--chakra-colors-chakra-button-bg);
        padding: 0;
        border: none;
      }
      ${scope} fieldset {
        margin: 0;
        padding: 0;
      }
      ${scope} :where(ol, ul) {
        margin: 0;
        padding: 0;
      }
      ${scope} textarea {
        resize: vertical;
      }
      ${scope} :where(button, [role="button"]) {
        cursor: pointer;
      }
      ${scope} button::-moz-focus-inner {
        border: 0 ;
      }
      ${scope} table {
        border-collapse: collapse;
      }
      ${scope} :where(h1, h2, h3, h4, h5, h6) {
        font-size: inherit;
        font-weight: inherit;
      }
      ${scope} :where(button, input, optgroup, select, textarea) {
        padding: 0;
        line-height: inherit;
        color: inherit;
      }
      ${scope} :where(img, svg, video, canvas, audio, iframe, embed, object) {
        display: block;
      }
      ${scope} :where(img, video) {
        max-width: 100%;
        height: auto;
      }
      ${scope} [data-js-focus-visible]
        :focus:not([data-focus-visible-added]):not(
          [data-focus-visible-disabled]
        ) {
        outline: none;
        box-shadow: none;
      }
      ${scope} select::-ms-expand {
        display: none;
      }
        
      ${scope} footer button {
        border: none;
      }
      ${vhPolyfill}
    `}
  />
);

export default CSSReset;
