import React from 'react';
import { createIcon } from '@chakra-ui/icons';

const Success = createIcon({
  displayName: 'Success',
  viewBox: '0 0 24 24',
  path: (
    <path d="M12.0424 0.390625L11.425 0.957854L9.10134 3.09321L5.05225 2.54906L4.34648 6.37951L0.734375 8.20156L2.52915 11.6956L0.734375 15.1897L4.34648 17.0117L5.05225 20.8422L9.10134 20.298L12.0424 23.0006L14.9799 20.298L19.029 20.8422L19.7365 17.0117L23.3486 15.1897L21.5538 11.6956L23.3486 8.20156L19.7365 6.37951L19.029 2.54906L14.9799 3.09321L12.0424 0.390625ZM12.0406 2.73869L14.3695 4.88064L17.5776 4.45027L18.1377 7.48428L20.9989 8.92874L19.577 11.6956L20.9989 14.4625L18.1377 15.907L17.5776 18.941L14.3695 18.5106L12.0406 20.6526L9.71347 18.5106L6.50541 18.941L5.9453 15.907L3.08406 14.4625L4.50601 11.6956L3.08406 8.92874L5.9453 7.48428L6.50541 4.45027L9.71347 4.88064L12.0406 2.73869ZM16.4348 8.31864L10.8857 13.5952L8.0002 10.8514L6.75859 12.0337L10.8857 15.9597L17.6781 9.50091L16.4348 8.31864Z" fill="#62B285" />
  ),
});

export default Success;
