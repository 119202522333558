import React from 'react';
import PropTypes from 'prop-types';
import { GridItem, SimpleGrid } from '@chakra-ui/react';
import FormInput from '../FormInput';
import StateSelector from '../StateSelector';

const propTypes = {
  index: PropTypes.number,
};
const defaultProps = {
  index: undefined,
};

const name = 'lienholders';

const LienholderForm = ({ index }) => (
  <>
    <FormInput name={`${name}.${index}.name`} label="Name" />
    <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={2}>
      <FormInput name={`${name}.${index}.address1`} label="Address Line 1" />
      <FormInput name={`${name}.${index}.address2`} label="Address Line 2" />
    </SimpleGrid>
    <SimpleGrid columns={{ sm: 2, md: 3 }} spacing={2}>
      <GridItem colSpan={{ sm: 2, md: 1 }}>
        <FormInput name={`${name}.${index}.city`} label="City" />
      </GridItem>
      <StateSelector name={`${name}.${index}.state`} />
      <FormInput
        name={`${name}.${index}.zipCode`}
        label="ZIP code"
      />
    </SimpleGrid>
  </>
);

LienholderForm.propTypes = propTypes;
LienholderForm.defaultProps = defaultProps;

export default LienholderForm;
