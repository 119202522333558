import React, { useState, createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node, PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export const WidthContext = createContext();

const WidthProvider = ({ children }) => {
  const [width, setWidth] = useState({});
  const value = useMemo(() => ({ width, setWidth }), [width]);

  return (
    <WidthContext.Provider value={value}>
      {children}
    </WidthContext.Provider>
  );
};

WidthProvider.propTypes = propTypes;

export default WidthProvider;
