import React from 'react';
import LienholderForm from '../LienholderForm';
import FormMulti from '../FormMulti';

const propTypes = {};
const defaultProps = {};

const Lienholders = () => (
  <FormMulti
    descriptor="lienholder"
    name="lienholders"
    title="Lienholder"
  >
    <LienholderForm />
  </FormMulti>
);

Lienholders.propTypes = propTypes;
Lienholders.defaultProps = defaultProps;

export default Lienholders;
