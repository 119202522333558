/* eslint-disable import/prefer-default-export */
export const containsProperty = (obj, propertyName) => propertyName in obj;

export const formatMoveInDate = (date = new Date()) => {
  const mm = date.getMonth() + 1;
  const dd = date.getDate();
  return `${mm > 9 ? '' : '0'}${mm}-${
    dd > 9 ? '' : '0'
  }${dd}-${date.getFullYear()}`;
};

export const getMoveInDate = (date, timezone, geo) => {
  if (!geo?.invalidData && timezone?.identifier) {
    const now = new Date();
    const currentHours = now.getHours();
    const currentMinutes = now.getMinutes();
    const currentSeconds = now.getSeconds();
    const currentMilliseconds = now.getMilliseconds();

    const localDate = new Date(date);

    localDate.setHours(currentHours, currentMinutes, currentSeconds, currentMilliseconds);

    const timeZoneIdentifier = timezone?.identifier;
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: timeZoneIdentifier,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).formatToParts(localDate);

    const formattedDate = formatter.reduce((acc, part) => {
      if (part.type === 'year') { acc.year = part.value; }
      if (part.type === 'month') { acc.month = part.value; }
      if (part.type === 'day') { acc.day = part.value; }
      return acc;
    }, {});

    const moveInDate = `${formattedDate.month}-${formattedDate.day}-${formattedDate.year}`;
    return moveInDate;
  }
  return formatMoveInDate(date);
};

export const parseJson = (jsonString) => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return jsonString;
  }
};
