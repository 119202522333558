import React from 'react';
import { createIcon } from '@chakra-ui/icons';

const Location = createIcon({
  displayName: 'Location',
  viewBox: '0 0 49 49',
  path: (
    <>
      <path d="M7.65565 44.1C5.20565 43.12 5.81815 41.2417 6.43065 40.425L16.8431 30.0125L23.8869 39.5062C23.8869 39.5062 28.4807 34.3 31.2683 29.0938C35.6322 33.4186 40.2574 37.9623 41.9557 39.5062C44.6507 41.9562 41.8536 43.5896 40.1182 44.1H7.65565Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16.7494 29.7993C17.0364 30.0863 17.0364 30.5517 16.7494 30.8387L7.15057 40.4375C6.07018 41.5179 6.83536 43.3652 8.36326 43.3652H24.8047C25.2106 43.3652 25.5397 43.6943 25.5397 44.1002C25.5397 44.5062 25.2106 44.8352 24.8047 44.8352H8.36326C5.52573 44.8352 4.10468 41.4045 6.11112 39.3981L15.71 29.7993C15.997 29.5122 16.4624 29.5122 16.7494 29.7993Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M31.3295 29.187C31.0425 29.474 31.0425 29.9394 31.3295 30.2264L41.5408 40.4377C42.6212 41.5181 41.856 43.3654 40.3281 43.3654H23.8867C23.4808 43.3654 23.1517 43.6945 23.1517 44.1004C23.1517 44.5064 23.4808 44.8354 23.8867 44.8354H40.3281C43.1657 44.8354 44.5867 41.4047 42.5803 39.3983L32.3689 29.187C32.0819 28.8999 31.6165 28.8999 31.3295 29.187Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M34.8401 36.8694C34.5675 37.1703 34.5904 37.6351 34.8913 37.9076L38.211 40.9154H32.767C32.361 40.9154 32.032 41.2445 32.032 41.6504C32.032 42.0564 32.361 42.3854 32.767 42.3854H38.5287C39.7615 42.3854 40.347 40.8671 39.4334 40.0393L35.8783 36.8182C35.5774 36.5457 35.1126 36.5686 34.8401 36.8694Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M14.156 36.8694C14.4286 37.1703 14.4056 37.6351 14.1048 37.9076L10.785 40.9154H16.2291C16.6351 40.9154 16.9641 41.2445 16.9641 41.6504C16.9641 42.0564 16.6351 42.3854 16.2291 42.3854H10.4674C9.23462 42.3854 8.64909 40.8671 9.56265 40.0393L13.1178 36.8182C13.4186 36.5457 13.8835 36.5686 14.156 36.8694Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M23.8852 38.6179C30.6935 31.3455 34.3589 22.6471 34.3589 16.8441C34.3589 11.0596 29.6696 6.37039 23.8852 6.37039C18.1007 6.37039 13.4114 11.0596 13.4114 16.8441C13.4114 22.6471 17.0768 31.3455 23.8852 38.6179ZM24.9082 39.6758C31.9223 32.2058 35.8289 23.1407 35.8289 16.8441C35.8289 10.2478 30.4815 4.90039 23.8852 4.90039C17.2888 4.90039 11.9414 10.2478 11.9414 16.8441C11.9414 23.1407 15.848 32.2058 22.8621 39.6758C23.1961 40.0315 23.5371 40.3836 23.8852 40.7316C24.2332 40.3836 24.5742 40.0315 24.9082 39.6758Z" fill="black" />
      <path d="M31.543 18.0693C31.543 22.2978 28.1151 25.7256 23.8867 25.7256C19.6583 25.7256 16.2305 22.2978 16.2305 18.0693C16.2305 13.8409 19.6583 10.4131 23.8867 10.4131C28.1151 10.4131 31.543 13.8409 31.543 18.0693Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M23.8867 24.2556C27.3033 24.2556 30.073 21.4859 30.073 18.0693C30.073 14.6528 27.3033 11.8831 23.8867 11.8831C20.4701 11.8831 17.7005 14.6528 17.7005 18.0693C17.7005 21.4859 20.4701 24.2556 23.8867 24.2556ZM23.8867 25.7256C28.1151 25.7256 31.543 22.2978 31.543 18.0693C31.543 13.8409 28.1151 10.4131 23.8867 10.4131C19.6583 10.4131 16.2305 13.8409 16.2305 18.0693C16.2305 22.2978 19.6583 25.7256 23.8867 25.7256Z" fill="currentColor" />
      <path d="M28.4805 18.0693C28.4805 20.6064 26.4238 22.6631 23.8867 22.6631C21.3497 22.6631 19.293 20.6064 19.293 18.0693C19.293 15.5323 21.3497 13.4756 23.8867 13.4756C26.4238 13.4756 28.4805 15.5323 28.4805 18.0693Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M23.8867 21.1931C25.6119 21.1931 27.0105 19.7945 27.0105 18.0693C27.0105 16.3441 25.6119 14.9456 23.8867 14.9456C22.1615 14.9456 20.763 16.3441 20.763 18.0693C20.763 19.7945 22.1615 21.1931 23.8867 21.1931ZM23.8867 22.6631C26.4238 22.6631 28.4805 20.6064 28.4805 18.0693C28.4805 15.5323 26.4238 13.4756 23.8867 13.4756C21.3497 13.4756 19.293 15.5323 19.293 18.0693C19.293 20.6064 21.3497 22.6631 23.8867 22.6631Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M23.8867 24.2556C27.3033 24.2556 30.073 21.4859 30.073 18.0693C30.073 14.6528 27.3033 11.8831 23.8867 11.8831C20.4701 11.8831 17.7005 14.6528 17.7005 18.0693C17.7005 21.4859 20.4701 24.2556 23.8867 24.2556ZM23.8867 25.7256C28.1151 25.7256 31.543 22.2978 31.543 18.0693C31.543 13.8409 28.1151 10.4131 23.8867 10.4131C19.6583 10.4131 16.2305 13.8409 16.2305 18.0693C16.2305 22.2978 19.6583 25.7256 23.8867 25.7256Z" fill="black" />
    </>
  ),
});

export default Location;
