/* eslint-disable import/prefer-default-export */

import get from 'lodash/get';

export const containsProperty = (obj, propertyName) => propertyName in obj;

export const formatMoveInDate = (date = new Date()) => {
  const mm = date.getMonth() + 1;
  const dd = date.getDate();
  return `${mm > 9 ? '' : '0'}${mm}-${
    dd > 9 ? '' : '0'
  }${dd}-${date.getFullYear()}`;
};

export const getMoveInDate = (date, timezone, geo) => {
  if (!geo?.invalidData && timezone?.identifier) {
    const now = new Date();
    const currentHours = now.getHours();
    const currentMinutes = now.getMinutes();
    const currentSeconds = now.getSeconds();
    const currentMilliseconds = now.getMilliseconds();

    const localDate = new Date(date);

    localDate.setHours(
      currentHours,
      currentMinutes,
      currentSeconds,
      currentMilliseconds,
    );

    const timeZoneIdentifier = timezone?.identifier;
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: timeZoneIdentifier,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).formatToParts(localDate);

    const formattedDate = formatter.reduce((acc, part) => {
      if (part.type === 'year') {
        acc.year = part.value;
      }
      if (part.type === 'month') {
        acc.month = part.value;
      }
      if (part.type === 'day') {
        acc.day = part.value;
      }
      return acc;
    }, {});

    const moveInDate = `${formattedDate.month}-${formattedDate.day}-${formattedDate.year}`;
    return moveInDate;
  }
  return formatMoveInDate(date);
};

export const parseJson = (jsonString) => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return jsonString;
  }
};

export const isEmpty = (value) => value === undefined || value === null || value === '';

export const eventPayload = (fieldName, initialValueObj, currentFieldValue) => {
  if (
    fieldName === 'dlImage'
    && initialValueObj === null
    && currentFieldValue === null
  ) {
    return {
      fieldName,
      action: 'dlImage value removed',
    };
  }

  const initialValue = get(initialValueObj, fieldName);
  let action;

  if (isEmpty(initialValue) && !isEmpty(currentFieldValue)) {
    action = `${fieldName} value added`;
  } else if (!isEmpty(initialValue) && isEmpty(currentFieldValue)) {
    action = `${fieldName} value removed`;
  } else if (initialValue !== currentFieldValue) {
    action = `${fieldName} value updated`;
  } else {
    action = `Clicked but no change detected in ${fieldName}`;
  }

  return {
    fieldName,
    action,
  };
};

export const getUserAction = (currentStep, nextStep) => {
  if (nextStep.number > currentStep.number) {
    if (
      currentStep.identifier === 'personalInfo'
      && nextStep.identifier === 'additionalInfo'
    ) {
      return 'Personal information submitted';
    }
    if (
      nextStep.identifier === 'payment'
      && currentStep.identifier === 'additionalInfo'
    ) {
      return 'Details submitted';
    }
    if (
      currentStep.identifier === 'account'
      && nextStep.identifier === 'payment'
    ) {
      return 'Password submitted';
    }
    if (currentStep.identifier === 'payment') {
      return 'Payment details submitted';
    }
  } else if (
    currentStep.identifier === 'payment'
    && nextStep.identifier === 'signLease'
  ) {
    return 'Payment initiated';
  } else if (
    currentStep.identifier === 'payment'
    && nextStep.identifier === 'T&CModal'
  ) {
    return 'Agree & Submit Payment Opened';
  } else if (
    currentStep.identifier === 'T&CModal'
    && nextStep.identifier === 'payment'
  ) {
    return 'Agree & Submit Payment Closed';
  } else if (nextStep.number < currentStep.number) {
    return 'Back';
  }
  return null;
};
export const isValidTimezone = (tz) => {
  try {
    Intl.DateTimeFormat(undefined, { timeZone: tz });
    return true;
  } catch (e) {
    return false;
  }
};
export const getFormattedDateRange = (timezoneIdentifier) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const tz = isValidTimezone(timezoneIdentifier)
    ? timezoneIdentifier
    : userTimezone;
  const now = new Date(new Date().toLocaleString('en-US', { timeZone: tz }));
  const currentDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
  );
  const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const start = currentDate.toLocaleDateString('en-US', options);
  const end = lastDayOfMonth.toLocaleDateString('en-US', options);

  return `(${start} - ${end})`;
};

export const getNextMonthDateRange = (timezoneIdentifier) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const tz = timezoneIdentifier || userTimezone;

  const now = new Date(new Date().toLocaleString('en-US', { timeZone: tz }));

  const firstDayOfNextMonth = new Date(
    now.getFullYear(),
    now.getMonth() + 1,
    1,
  );
  const lastDayOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 2, 0);

  const formatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  const start = formatter.format(firstDayOfNextMonth);
  const end = formatter.format(lastDayOfNextMonth);

  return `(${start} - ${end})`;
};

export const checkServicePeriod = (day = 0) => {
  const today = new Date();
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const daysRemaining = lastDayOfMonth.getDate() - today.getDate();
  return daysRemaining < day;
};

const lockRegex = /\b(lock|locks|padlock|padlocks|[a-z]+-lock|[a-z]+-locks)\b/i;
const unitRegex = /\b(unit|units|padunit|padunits|[a-z]+-unit|[a-z]+-units)\b/i;

export const showDescription = (charge, showNextMonthPayment, timezone) => {
  let chargeDescription = charge.description;
  if (
    showNextMonthPayment
    && !charge.prorated
    && !lockRegex.test(chargeDescription)
    && unitRegex.test(chargeDescription)
  ) {
    chargeDescription = ` Second Month’s Rent ${getNextMonthDateRange(
      timezone?.identifier,
    )}`;
    // Unit second month price
  } else if (
    showNextMonthPayment
    && charge.prorated
    && !lockRegex.test(chargeDescription)
    && unitRegex.test(chargeDescription)
  ) {
    chargeDescription = ` Prorated First Month’s Rent ${getFormattedDateRange(
      timezone?.identifier,
    )}`;
    // Unit first month price
  }
  return chargeDescription;
};

// Function to compare two objects for shallow equality
// Returns true if both objects have the same keys and corresponding values
// Note: This does not perform deep comparison for nested objects
export const areObjectsEqual = (obj1, obj2) => Object.keys(obj1).length === Object.keys(obj2).length
  && Object.keys(obj1).every((key) => obj1[key] === obj2[key]);

/**
 * Prevents leading and consecutive trailing spaces in an input field.
 * This function is triggered on the `onKeyDown` event of an input element.
 *
 * @param {KeyboardEvent} event - The keyboard event object.
 */
export const preventLeadingAndTrailingSpaces = (event) => {
  const {
    key,
    target: { value },
  } = event;

  // Prevent space key press if:
  // 1. The input is empty (leading space)
  // 2. The last character is already a space (prevents consecutive spaces)
  if (key === ' ' && (value.trim().length === 0 || value.endsWith(' '))) {
    event.preventDefault(); // Stop the space from being entered
  }
};
