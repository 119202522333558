import React from 'react';
import PropTypes from 'prop-types';
import Summary from '../Summary';
import Terms from '../Terms';

const propTypes = {
  closeTAndCModal: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
    }),
  ),
  onSubmit: PropTypes.func.isRequired,
  showTAndCModal: PropTypes.bool.isRequired,
};

const defaultProps = {
  errors: [],
};

const Step5 = ({ errors, showTAndCModal, closeTAndCModal, onSubmit }) => (
  <>
    <Summary editable errors={errors} />
    <Terms isOpen={showTAndCModal} onClose={closeTAndCModal} onSubmit={onSubmit} />
  </>
);

Step5.propTypes = propTypes;
Step5.defaultProps = defaultProps;

export default Step5;
