/* eslint-disable max-len */
import React from 'react';
import { createIcon } from '@chakra-ui/icons';

const Camera = createIcon({
  displayName: 'Camera',
  viewBox: '0 0 26 20',
  path: (
    <path d="M8.5 0L8.1875 0.40625L7 2H0V20H26V2H19L17.8125 0.40625L17.5 0H8.5ZM9.5 2H16.5L17.6875 3.59375L18 4H24V18H2V4H8L8.3125 3.59375L9.5 2ZM5 5C4.44922 5 4 5.44922 4 6C4 6.55078 4.44922 7 5 7C5.55078 7 6 6.55078 6 6C6 5.44922 5.55078 5 5 5ZM13 5C9.69922 5 7 7.69922 7 11C7 14.3008 9.69922 17 13 17C16.3008 17 19 14.3008 19 11C19 7.69922 16.3008 5 13 5ZM13 7C15.2227 7 17 8.77734 17 11C17 13.2227 15.2227 15 13 15C10.7773 15 9 13.2227 9 11C9 8.77734 10.7773 7 13 7Z" fill="black" />
  ),
});

export default Camera;
