import qs from 'qs';
import { getMoveInDate } from './utils';

const buildUrl = (path) => {
  const baseUrl = new URL(process.env.OMI_API_URL);

  if (process.env.OMI_API_PORT) {
    baseUrl.port = process.env.OMI_API_PORT;
  }

  const { href: url } = new URL(path, baseUrl);
  return url;
};

export const buildAuthHeader = () => {
  if (process.env.AUTH_HEADER_TOKEN) {
    return `Basic ${process.env.AUTH_HEADER_TOKEN}`;
  }

  const token = btoa(
    `${process.env.OMI_API_KEY}:${process.env.OMI_API_SECRET}`,
  );
  return `Basic ${token}`;
};

export const fetchConfiguration = (query) => fetch(buildUrl(`config/configuration?${query}`), {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    Authorization: buildAuthHeader(),
  },
}).then((response) => response.json());

export const fetchInitializationData = ({
  facilityId,
  providerId,
  organizationId,
  unitId,
  reservationId,
  returnUrl,
}) => {
  const pathParts = [
    `/providers/${providerId}`,
    `/organizations/${organizationId}`,
    `/facilities/${facilityId}`,
    `/units/${unitId}`,
    `?returnUrl=${returnUrl}`,
  ];

  let url = buildUrl(pathParts.join(''));

  if (reservationId) {
    url = `${url}&reservationId=${reservationId}`;
  }

  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: buildAuthHeader(),
    },
  });
};

export const fetchCostData = (
  { facilityId, unitId, moveInDate, reservationId, insurance, lock },
  insuranceKey,
  timezone,
  geo,
) => {
  const body = {
    facilityId,
    unitId,
    moveInDate: getMoveInDate(moveInDate, timezone, geo),
    reservationId,
  };
  body.fmsSpecificFields = {
    ...(insurance && {
      [insuranceKey]: insurance,
    }),
    ...(lock && {
      retailItems: [lock],
    }),
  };
  const url = buildUrl(`/costs?${qs.stringify(body, { encode: true })}`);

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: buildAuthHeader(),
    },
  });
};

export const updateContactData = ({ contact, password, fmsSpecificFields }) => fetch(buildUrl('/contacts/update'), {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Authorization: buildAuthHeader(),
  },
  body: JSON.stringify({
    contact: {
      contactId: contact.id,
      password,
      fmsSpecificFields,
    },
  }),
});

export const createRental = ({ reservationId, body }) => (
  fetch(buildUrl(`/reservations/${reservationId}/rentals`), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: buildAuthHeader(),
    },
    body: JSON.stringify(body),
  })
);

export const sendLeaseConfirmationMail = ({ rentalAlias, rentalId, configId, contactId }) => {
  let url = `/rentals/${rentalAlias}/lease-agreement?rentalId=${rentalId}`;
  if (configId) {
    url = `${url}&configId=${configId}`;
  }
  if (contactId) {
    url = `${url}&contactId=${contactId}`;
  }
  return fetch(
    buildUrl(url),
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: buildAuthHeader(),
      },
    },
  );
};
