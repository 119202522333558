import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertIcon, Box, Text, VStack } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import useUnitData from '../../hooks/UseUnitData';
import FormInput from '../FormInput';
import { sizes } from '../../theme';
import SecurityFields from './SecurityFields';
import { useConfig } from '../../contexts/ConfigProvider';

const propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isPasswordSubmitted: PropTypes.bool.isRequired,
};
const defaultProps = {};

const Step3 = ({ errors, isPasswordSubmitted, isLoading }) => {
  const {
    data: {
      facility: { displayName },
    },
  } = useUnitData();
  const { configuration } = useConfig();
  const { getValues, trigger } = useFormContext();
  const email = getValues('email');
  const disabled = isPasswordSubmitted || isLoading;

  return (
    <>
      {errors.length > 0 && (
        <>
          <VStack spacing={3} marginBottom={sizes._12px}>
            {errors.map((error) => (
              <Alert status="error" key={error}>
                <AlertIcon />
                {error}
              </Alert>
            ))}
          </VStack>
          <Box
            borderBottom="6px solid"
            borderColor="gray.lightBorder"
            marginBottom="8px"
          />
        </>
      )}
      <Text variant="lgBold" marginBottom={sizes._12px}>
        {`Set Up Your Account with ${displayName}`}
      </Text>
      <Text variant="md" marginBottom={sizes._12px}>
        Create your account password below
      </Text>
      <Box
        borderBottom="2px solid"
        borderColor="gray.lightBorder"
        marginBottom={sizes._12px}
      />
      <Text variant="xs" marginBottom={sizes._2px}>
        Rental Email Address
      </Text>
      <Text variant="lg" marginBottom={sizes._8px}>
        {email}
      </Text>
      <FormInput
        disabled={disabled}
        name="password"
        label="Create Account Password"
        type="password"
        rules={{
          onChange: () => trigger('password'),
        }}
      />
      <FormInput
        disabled={disabled}
        name="confirmPassword"
        label="Confirm Account Password"
        type="password"
        rules={{
          onChange: () => trigger('confirmPassword'),
        }}
      />
      <Text variant="sm" paddingTop={sizes._8px}>
        Password must be at least 8 characters
      </Text>
      <Text variant="md" paddingTop={sizes._8px} paddingBottom={sizes._8px}>
        Once your account is created you can sign your lease and pay your bill
        online.
      </Text>
      {configuration.providerConfig.showSecurityQuestion && (
        <SecurityFields disabled={disabled} />
      )}
    </>
  );
};
Step3.propTypes = propTypes;
Step3.defaultProps = defaultProps;

export default Step3;
