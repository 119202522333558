import React, { useEffect } from 'react';
import { GridItem, SimpleGrid, Text } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { sizes } from '../../theme';
import FormInput from '../FormInput';
import FormCheckbox from '../FormCheckbox';
import StateSelector from '../StateSelector';
import {
  areObjectsEqual,
  preventLeadingAndTrailingSpaces,
} from '../../modules/utils';

const BillingInformation = () => {
  const { setValue, getValues } = useFormContext();
  const { sameAsMailingAddress, ...formValues } = useWatch();

  useEffect(() => {
    const fields = [
      'firstName',
      'lastName',
      'addressOne',
      'addressTwo',
      'city',
      'state',
      'zipCode',
    ];
    if (!sameAsMailingAddress) {
      const billingValues = getValues('billing') || {};
      const mailingValues = getValues('mailing') || {};
      // Function to check if billing fields are empty
      const isBillingInfoEmpty = (billing) => Object.values(billing).every((value) => !value || value.trim() === '');
      if (
        isBillingInfoEmpty(billingValues)
        || areObjectsEqual(billingValues, mailingValues)
      ) {
        // Clear all billing fields if billing info is empty
        fields.forEach((field) => setValue(`billing.${field}`, '', {
          shouldValidate: false,
          shouldDirty: true,
        }));
      } else {
        // Retain existing billing values
        fields.forEach((field) => setValue(`billing.${field}`, billingValues[field] || '', {
          shouldValidate: true,
          shouldDirty: true,
        }));
      }
    } else {
      // Copy mailing address to billing fields
      fields.forEach((field) => {
        setValue(`billing.${field}`, formValues.mailing[field] || '', {
          shouldValidate: true,
          shouldDirty: true,
        });
      });
    }
  }, [sameAsMailingAddress]);

  return (
    <>
      <Text variant="mdBold" marginBottom={sizes._16px}>
        Billing Information
      </Text>
      <FormCheckbox
        name="sameAsMailingAddress"
        marginBottom={sizes._16px}
        label="Same as mailing address"
        onKeyDown={preventLeadingAndTrailingSpaces}
        rules={{ spaceAllowed: false }}
      />
      <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={2}>
        <FormInput
          name="billing.firstName"
          autoComplete="cc-given-name"
          label="First name"
          isDisabled={sameAsMailingAddress}
          onKeyDown={preventLeadingAndTrailingSpaces}
          rules={{ spaceAllowed: false }}
        />
        <FormInput
          name="billing.lastName"
          autoComplete="cc-family-name"
          label="Last name"
          isDisabled={sameAsMailingAddress}
          onKeyDown={preventLeadingAndTrailingSpaces}
          rules={{ spaceAllowed: false }}
        />
        <FormInput
          name="billing.addressOne"
          autoComplete="address-line1"
          label="Street address"
          isDisabled={sameAsMailingAddress}
          onKeyDown={preventLeadingAndTrailingSpaces}
          rules={{ spaceAllowed: false }}
        />
        <FormInput
          name="billing.addressTwo"
          autoComplete="address-line2"
          label="Unit/Apartment number"
          isDisabled={sameAsMailingAddress}
          onKeyDown={preventLeadingAndTrailingSpaces}
          rules={{ spaceAllowed: false }}
        />
      </SimpleGrid>
      <SimpleGrid columns={{ sm: 2, md: 3 }} spacing={2}>
        <GridItem colSpan={{ sm: 2, md: 1 }}>
          <FormInput
            name="billing.city"
            autoComplete="address-level2"
            label="City"
            isDisabled={sameAsMailingAddress}
            onKeyDown={preventLeadingAndTrailingSpaces}
            rules={{ spaceAllowed: false }}
          />
        </GridItem>
        <StateSelector name="billing.state" isDisabled={sameAsMailingAddress} />
        <FormInput
          name="billing.zipCode"
          autoComplete="postal-code"
          label="ZIP code"
          type="number"
          pattern="[0-9]*"
          isDisabled={sameAsMailingAddress}
        />
      </SimpleGrid>
    </>
  );
};

export default BillingInformation;
