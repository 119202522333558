import React from 'react';
import { createIcon } from '@chakra-ui/icons';

const Address = createIcon({
  displayName: 'Address',
  viewBox: '0 0 16 16',
  path: (
    <>
      <path d="M8 7.5C7.17157 7.5 6.5 6.82843 6.5 6C6.5 5.17157 7.17157 4.5 8 4.5C8.82843 4.5 9.5 5.17157 9.5 6C9.5 6.82843 8.82843 7.5 8 7.5Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M2 6C2 8.14813 3.74653 11.2919 7.21969 15.6254C7.62 16.1249 8.38 16.1249 8.78031 15.6254C12.2535 11.2919 14 8.14813 14 6C14 2.68629 11.3137 0 8 0C4.68629 0 2 2.68629 2 6ZM8 2C10.2091 2 12 3.79086 12 6C12 7.40327 10.6717 9.91353 8 13.3821C5.32825 9.91353 4 7.40327 4 6C4 3.79086 5.79086 2 8 2Z" fill="black" />
    </>
  ),
});

export default Address;
