import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from 'react';
import { fetchConfiguration } from '../modules/api';

// While Setting this make sure that configuration is updated in the schema as well

const defaultConfig = {
  facilityId: '',
  companyId: '',
  providerId: '',
  name: '',
  description: '',
  schema: '',
  configuration: {
    themification: {
      primaryColor: '#FFCC00',
      secondaryColor: '#FF0064',
      tertiaryColor: '#FE8240',
      fontFamily: 'Helvetica',
      buttonBorderRadius: '6',
      buttonTextStyle: 'capitalize',
      enableLogo: true,
      logoImageUrl: 'http://static.sparefoot.com/emails/sparefoot_email_logo.gif',
      logoImageText: 'sparefoot',
    },
    insurance: {
      enabled: true,
      allowTenantsToBuyInsurance: true,
      name: 'Protection Plan',
      description: '',
      brochureUrl: '',
      insuranceKey: 'insuranceCoverageId',
    },
    accessControl: { showGateCodeAtEnd: true },
    providerConfig: {
      showLock: false,
      lienholderCount: 0,
      vehicleCount: 1,
      contactCount: 1,
      dlImage: true,
      showSecurityQuestion: false,
    },
    reservationConfig: { unitId: '', reservationId: '' },
    omiMailTrigger: {
      paymentConfirmation: true,
      leaseSignedConfirmation: true,
    },
  },
};

const defaultConfigSL = {
  facilityId: '',
  companyId: '',
  providerId: '',
  name: '',
  description: '',
  schema: '',
  configuration: {
    themification: {
      primaryColor: '#FFCC00',
      secondaryColor: '#FF0064',
      tertiaryColor: '#FE8240',
      fontFamily: 'Helvetica',
      buttonBorderRadius: '6',
      buttonTextStyle: 'capitalize',
      enableLogo: true,
      logoImageUrl: 'http://static.sparefoot.com/emails/sparefoot_email_logo.gif',
      logoImageText: 'sparefoot',
    },
    insurance: {
      enabled: true,
      allowTenantsToBuyInsurance: true,
      name: 'Protection Plan',
      description: '',
      brochureUrl: '',
      insuranceKey: 'insuranceCoverageId',
    },
    accessControl: { showGateCodeAtEnd: true },
    providerConfig: {
      showLock: false,
      lienholderCount: 0,
      vehicleCount: 1,
      contactCount: 1,
      dlImage: true,
      showSecurityQuestion: false,
    },
    reservationConfig: { unitId: '', reservationId: '' },
    omiMailTrigger: {
      paymentConfirmation: true,
      leaseSignedConfirmation: true,
    },
  },
};

const defaultConfigSE = {
  facilityId: '',
  companyId: '',
  providerId: '',
  name: '',
  description: '',
  schema: '',
  configuration: {
    themification: {
      primaryColor: '#FFCC00',
      secondaryColor: '#FF0064',
      tertiaryColor: '#FE8240',
      fontFamily: 'Helvetica',
      buttonBorderRadius: '6',
      buttonTextStyle: 'capitalize',
      enableLogo: true,
      logoImageUrl: 'http://static.sparefoot.com/emails/sparefoot_email_logo.gif',
      logoImageText: 'sparefoot',
    },
    insurance: {
      enabled: true,
      allowTenantsToBuyInsurance: true,
      name: 'Protection Plan',
      description: '',
      brochureUrl: '',
      insuranceKey: 'insuranceId',
    },
    accessControl: { showGateCodeAtEnd: true },
    providerConfig: {
      showLock: true,
      lienholderCount: 3,
      vehicleCount: 3,
      contactCount: 3,
      dlImage: true,
      showSecurityQuestion: true,
    },
    reservationConfig: { unitId: '', reservationId: '' },
    omiMailTrigger: {
      paymentConfirmation: true,
      leaseSignedConfirmation: true,
    },
  },
};
const OMIConfigContext = createContext({
  configuration: defaultConfig.configuration,
  config: defaultConfig,
  refreshConfig: () => {},
});

export const useConfig = () => useContext(OMIConfigContext);

const useConfigProvider = (params) => {
  const {
    facilityId,
    organizationId,
    providerId,
    unitId,
    reservationId,
    overrideConfig = {},
  } = params;
  const [config, setConfig] = useState(defaultConfig);

  const fetchConfig = useCallback(() => {
    // if config Id is given we will follow that or we are going to use the existing values
    let query = '';
    if (params.configId) {
      query = `id=${params.configId}`;
    } else {
      query = `facilityId=${facilityId}`;
    }
    // Fetch from Config Id
    fetchConfiguration(query)
      .then((data) => {
        if (data?.results?.length !== 0) {
          const configData = data.results[0];
          const finalConfig = providerId === 'sl' ? { ...defaultConfigSL, ...configData, ...overrideConfig } : { ...defaultConfigSE, ...configData, ...overrideConfig };
          setConfig(finalConfig);
        // const finalConfig = deepMerge(defaultConfig, data);
        // setConfig(finalConfig);
        } else {
          const updatedConfig = providerId === 'sl' ? { ...defaultConfigSL } : { ...defaultConfigSE };
          updatedConfig.facilityId = facilityId;
          updatedConfig.organizationId = organizationId;
          updatedConfig.providerId = providerId;
          updatedConfig.configuration.reservationConfig.unitId = unitId;
          updatedConfig.configuration.reservationConfig.reservationId = reservationId;
          setConfig({ ...updatedConfig, ...overrideConfig });
        }
      })
      .catch((error) => {
        console.error('Error fetching configuration', error);
        const updatedConfig = providerId === 'sl' ? { ...defaultConfigSL } : { ...defaultConfigSE };
        updatedConfig.facilityId = facilityId;
        updatedConfig.organizationId = organizationId;
        updatedConfig.providerId = providerId;
        updatedConfig.configuration.reservationConfig.unitId = unitId;
        updatedConfig.configuration.reservationConfig.reservationId = reservationId;
        setConfig({ ...updatedConfig, ...overrideConfig });
      });
  }, [params.configId]);

  useEffect(fetchConfig, [params.configId]);

  return {
    config,
    configuration: config.configuration,
    refreshConfig: fetchConfig,
  };
};

export const ConfigurationProvider = ({ children, params }) => {
  const providerValue = useConfigProvider(params);
  return (
    <OMIConfigContext.Provider value={providerValue}>
      {children}
    </OMIConfigContext.Provider>
  );
};
