import React from 'react';
import { createIcon } from '@chakra-ui/icons';

const Mastercard = createIcon({
  displayName: 'Mastercard',
  viewBox: '0 0 152.407 108',
  path: (
    <>
      <path
        d="M 62.4119,54 C 62.411863,42.953814 67.472263,32.516651 76.1618,25.6968 52.541341,7.1305506 17.915802,23.957984 17.915802,54 c 0,30.042016 34.625539,46.86945 58.245998,28.3032 C 67.472323,75.483301 62.411937,65.046186 62.4119,54 Z"
        fill="#eb001b"
        id="path29"
      />
      <path
        d="m 134.407,54 c 10e-4,30.042448 -34.62606,46.86889 -58.2452,28.3032 18.333069,-14.415663 18.333069,-42.190737 0,-56.6064 C 99.78094,7.131108 134.40811,23.957552 134.407,54 Z"
        fill="#f79e1b"
        id="path31"
      />
      <path
        d="m 130.9724,76.3077 v -1.1589 h 0.4673 v -0.2361 h -1.1901 v 0.2361 h 0.4675 v 1.1589 z m 2.3105,0 v -1.3973 h -0.3648 l -0.41959,0.9611 -0.41971,-0.9611 h -0.365 v 1.3973 h 0.2576 v -1.054 l 0.3935,0.9087 h 0.2671 l 0.39351,-0.911 v 1.0563 z"
        fill="#f79e1b"
        id="path33"
      />
      <path
        fill="#ff5f00"
        d="M 62.385956,54.126815 C 62.395279,42.917906 67.633704,32.412929 76.135965,25.7181 85.662872,32.936292 89.909448,44.27628 89.9554,53.493725 89.905825,67.127295 83.413573,76.640727 76.159462,82.297043 67.584348,75.793263 62.395488,65.064792 62.385956,54.126815 Z"
        id="path1755"
      />
    </>
  ),
});

export default Mastercard;
