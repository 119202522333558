const colors = {
  primary: {
    accent: '#FFCC00',
  },
  secondary: {
    accent: '#FF0064',
  },
  tertiary: {
    accent: '#FE8240',
  },
  gray: {
    lightText: '#555555',
    lightBorder: '#EAEAEA',
    hoverColor: '#e2e8f0',
    veryLightBorder: '#7F555555',
    unselectedRadio: '#E7E7E7',
  },
  green: {
    accent: '#2C9A00',
    successText: '#62B285',
  },
  red: {
    accent: '#FF3600',
  },
  // pink: {
  //   accent: '#FF0064',
  // },
  // yellow: {
  //   checkbox: '#FFCC00',
  // },
  // orange: {
  //   accent: '#FE8240',
  // },
  progressBar: {
    50: '#FFCC00',
    100: '#FFCC00',
    200: '#FFCC00',
    300: '#FFCC00',
    400: '#FFCC00',
    500: '#FFCC00',
    600: '#FFCC00',
    700: '#FFCC00',
    800: '#FFCC00',
    900: '#FFCC00',
  },
};

export default colors;
