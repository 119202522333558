import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputLeftElement, Text } from '@chakra-ui/react';
import { useFormContext, useController } from 'react-hook-form';
import { useIMask } from 'react-imask';
import get from 'lodash/get';
import { sizes } from '../../theme';
import useSchema from '../../hooks/UseSchema';

const propTypes = {
  autoComplete: PropTypes.string,
  icon: PropTypes.object,
  label: PropTypes.string,
  mask: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  rules: PropTypes.object,
};

const defaultProps = {
  autoComplete: undefined,
  icon: undefined,
  label: undefined,
  mask: undefined,
  placeholder: undefined,
  width: '100%',
  rules: {},
};

const FormInput = ({
  autoComplete,
  icon,
  label,
  mask,
  name,
  placeholder,
  rules,
  width,
  ...rest
}) => {
  const { formState: { errors }, control } = useFormContext();
  const [inputPlaceholder, updateInputPlaceholder] = useState(' ');
  const { getIsRequired } = useSchema();

  const { field } = useController({ name, control, rules });

  const { ref } = useIMask({ mask, unmask: true }, { onAccept: (value) => field.onChange(value) });

  return (
    <FormControl
      isInvalid={get(errors, name)}
      marginBottom={sizes._12px}
      variant="floating"
      width={width}
      isRequired={getIsRequired(name)}
    >
      <InputGroup>
        {icon ? <InputLeftElement pointerEvents="none" children={icon} /> : <div />}
        <Input
          {...rest}
          {...field}
          autoComplete={autoComplete || 'always-off'} /* Has to be invalid to be truly off */
          id={name}
          onBlur={(e) => {
            updateInputPlaceholder(' ');
            field.onBlur(e);
          }}
          onFocus={() => {
            updateInputPlaceholder(placeholder || inputPlaceholder);
          }}
          placeholder={inputPlaceholder}
          ref={ref}
        />
        <FormLabel onClick={() => ref.current.focus()}>
          <Text variant="md">
            {label}
          </Text>
        </FormLabel>
      </InputGroup>
      <FormErrorMessage>
        <Text variant="sm" marginTop={sizes._4px} color="red.accent">
          {get(errors, name)?.message}
        </Text>
      </FormErrorMessage>
    </FormControl>
  );
};

FormInput.propTypes = propTypes;
FormInput.defaultProps = defaultProps;

export default FormInput;
