import React, { useState } from 'react';
import FormFileUploadDesktop from './FormFileUploadDesktop';
import FormFileUploadMobile from './FormFileUploadMobile';
import { DESKTOP_WIDTH } from '../../theme/media';
import useWidthQuery from '../../hooks/UseWidth';

const propTypes = {};
const defaultProps = {};

const FormFileUpload = (props) => {
  const isLargerThan1280 = useWidthQuery(DESKTOP_WIDTH);
  const [pdfUrl, setPdfUrl] = useState(null);
  return isLargerThan1280 ? (
    <FormFileUploadDesktop {...props} pdfUrl={pdfUrl} setPdfUrl={setPdfUrl} />
  ) : (
    <FormFileUploadMobile {...props} pdfUrl={pdfUrl} setPdfUrl={setPdfUrl} />
  );
};

FormFileUpload.propTypes = propTypes;
FormFileUpload.defaultProps = defaultProps;

export default FormFileUpload;
