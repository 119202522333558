import React from 'react';
import PropTypes from 'prop-types';
import FormInput from '../FormInput';

const propTypes = {
  disabled: PropTypes.bool.isRequired,
};
const defaultProps = {};

const SecurityFields = ({ disabled }) => (
  <>
    <FormInput
      disabled={disabled}
      name="securityQuestionOne"
      label="Security Question 1"
      placeholder="A custom security question of your choosing"
      type="text"
    />
    <FormInput
      disabled={disabled}
      name="securityAnswerOne"
      label="Answer to Security Question 1"
      type="text"
    />
    <FormInput
      disabled={disabled}
      name="securityQuestionTwo"
      label="Security Question 2"
      placeholder="Another security question of your choosing"
      type="text"
    />
    <FormInput
      disabled={disabled}
      name="securityAnswerTwo"
      label="Answer to Security Question 2"
      type="text"
    />
  </>
);
SecurityFields.propTypes = propTypes;
SecurityFields.defaultProps = defaultProps;

export default SecurityFields;
