import React from 'react';
import PropTypes from 'prop-types';
import { SimpleGrid } from '@chakra-ui/react';
import FormInput from '../FormInput';

const ContactForm = ({ index }) => (
  <SimpleGrid columns={{ sm: 1, md: 2 }} spacingX={2}>
    <FormInput name={`contacts.${index}.firstName`} label="First Name" />
    <FormInput name={`contacts.${index}.lastName`} label="Last Name" />
    <FormInput name={`contacts.${index}.relationship`} label="Relationship to You" />
    <FormInput name={`contacts.${index}.phone`} label="Phone Number" mask="(000) 000-0000" type="tel" />
  </SimpleGrid>
);

ContactForm.propTypes = {
  index: PropTypes.number,
};

ContactForm.defaultProps = {
  index: undefined,
};

export default ContactForm;
