import React from 'react';
import { createIcon } from '@chakra-ui/icons';

const Unit = createIcon({
  displayName: 'Unit',
  viewBox: '0 0 49 49',
  path: (
    <>
      <path d="M0 0L49 0V49H0L0 0Z" fill="white" />
      <path d="M3.43018 42.7281V7.74212C3.43018 7.00712 4.01817 6.37012 4.80217 6.37012H43.8062C44.5412 6.37012 45.1782 6.95812 45.1782 7.74212V42.7281H3.43018Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.80182 7.10501C4.44076 7.10501 4.16482 7.39572 4.16482 7.74201V41.993H44.4428V7.74201C44.4428 7.38094 44.1521 7.10501 43.8058 7.10501H4.80182ZM2.69482 7.74201C2.69482 6.6183 3.59488 5.63501 4.80182 5.63501H43.8058C44.9295 5.63501 45.9128 6.53507 45.9128 7.74201V42.728C45.9128 43.1339 45.5838 43.463 45.1778 43.463H3.42982C3.02389 43.463 2.69482 43.1339 2.69482 42.728V7.74201Z" fill="black" />
      <path d="M39.6411 13.6221H8.86914V42.7281H39.6411V13.6221Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.13379 13.622C8.13379 13.216 8.46286 12.887 8.86879 12.887H39.6408C40.0467 12.887 40.3758 13.216 40.3758 13.622V42.728C40.3758 43.1339 40.0467 43.463 39.6408 43.463H8.86879C8.46286 43.463 8.13379 43.1339 8.13379 42.728V13.622ZM9.60379 14.357V41.993H38.9058V14.357H9.60379Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.13379 18.424C8.13379 18.018 8.46286 17.689 8.86879 17.689H39.6408C40.0467 17.689 40.3758 18.018 40.3758 18.424C40.3758 18.8299 40.0467 19.159 39.6408 19.159H8.86879C8.46286 19.159 8.13379 18.8299 8.13379 18.424ZM8.13379 23.226C8.13379 22.82 8.46286 22.491 8.86879 22.491H39.6408C40.0467 22.491 40.3758 22.82 40.3758 23.226C40.3758 23.6319 40.0467 23.961 39.6408 23.961H8.86879C8.46286 23.961 8.13379 23.6319 8.13379 23.226ZM8.13379 27.979C8.13379 27.573 8.46286 27.244 8.86879 27.244H39.6408C40.0467 27.244 40.3758 27.573 40.3758 27.979C40.3758 28.3849 40.0467 28.714 39.6408 28.714H8.86879C8.46286 28.714 8.13379 28.3849 8.13379 27.979ZM8.13379 32.781C8.13379 32.375 8.46286 32.046 8.86879 32.046H39.6408C40.0467 32.046 40.3758 32.375 40.3758 32.781C40.3758 33.1869 40.0467 33.516 39.6408 33.516H8.86879C8.46286 33.516 8.13379 33.1869 8.13379 32.781ZM8.13379 37.583C8.13379 37.177 8.46286 36.848 8.86879 36.848H39.6408C40.0467 36.848 40.3758 37.177 40.3758 37.583C40.3758 37.9889 40.0467 38.318 39.6408 38.318H8.86879C8.46286 38.318 8.13379 37.9889 8.13379 37.583Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M42.2871 13.622C42.2871 13.216 42.6162 12.887 43.0221 12.887H45.1291C45.535 12.887 45.8641 13.216 45.8641 13.622C45.8641 14.0279 45.535 14.357 45.1291 14.357H43.0221C42.6162 14.357 42.2871 14.0279 42.2871 13.622Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M2.69482 13.622C2.69482 13.216 3.02389 12.887 3.42982 12.887H5.04682C5.45275 12.887 5.78182 13.216 5.78182 13.622C5.78182 14.0279 5.45275 14.357 5.04682 14.357H3.42982C3.02389 14.357 2.69482 14.0279 2.69482 13.622Z" fill="black" />
    </>
  ),
});

export default Unit;
