import React, { useState, useRef, useEffect } from 'react';
import { Text, Box } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import LienholderForm from '../LienholderForm';
import FormMulti from '../FormMulti';
import { sizes } from '../../theme';

const propTypes = {};
const defaultProps = {};

const Lienholders = () => {
  const { getValues } = useFormContext();
  const formRef = useRef(null);

  // Get lienholders array from form data
  const { lienholders } = getValues() || {};

  // Function to check if any lienholder has non-empty values
  const hasValidLienholder = (lienholdersArray) => {
    if (!Array.isArray(lienholdersArray) || lienholdersArray.length === 0) { return false; }

    return lienholdersArray.some((lienholder) => Object.values(lienholder || {}).some((value) => value && value.trim() !== ''));
  };

  // Initialize form visibility based on existing data
  const [showForm, setShowForm] = useState(() => hasValidLienholder(lienholders));

  const handleToggleForm = () => {
    setShowForm((prev) => !prev);
    setTimeout(() => {
      if (formRef.current && !showForm) {
        formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);
  };

  const checkIfAnyFormHasData = () => {
    if (!formRef.current) { return false; }
    const inputs = formRef.current.querySelectorAll('input, select');
    return Array.from(inputs).some((input) => input.value.trim() !== '');
  };

  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target) && !checkIfAnyFormHasData()) {
      setShowForm(false);
    }
  };

  useEffect(() => {
    if (showForm) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showForm]);

  return (
    <div>
      <Box>
        <Text variant="mdBold" marginBottom={sizes._8px}>
          Lienholder Information
        </Text>
        <Text variant="md" color="gray.500" marginBottom={sizes._16px}>
          If you&apos;re storing something that is
          financed or has a secured loan, please enter the lienholder details.
        </Text>
        <Text
          as="a"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleToggleForm();
          }}
          variant="mdBold"
          sx={{
            textDecoration: 'underline !important',
            textDecorationThickness: '1px',
            cursor: 'pointer',
            color: 'gray.500 !important',
            _hover: { color: 'gray.600 !important' },
          }}
        >
          {showForm ? '' : 'Add lienholder'}
        </Text>
      </Box>

      {showForm && (
        <Box ref={formRef} marginTop={sizes._16px}>
          <FormMulti descriptor="lienholder" name="lienholders" title="Lienholder">
            <LienholderForm />
          </FormMulti>
        </Box>
      )}
    </div>
  );
};

Lienholders.propTypes = propTypes;
Lienholders.defaultProps = defaultProps;

export default Lienholders;
