import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import Confirmation from '../Confirmation';
import { FacilityIcon, UnitIcon } from '../Icons';
import useUnitData from '../../hooks/UseUnitData';
import { sizes } from '../../theme';

const propTypes = {
  providerId: PropTypes.string.isRequired,
  openEsignPage: PropTypes.func.isRequired,
};
const defaultProps = {};

const Resumption = ({ providerId, openEsignPage }) => {
  const { data:
    { unit: { size, rates: { effectiveRate } },
      facility: { address, displayName, phoneNumber },
      leaseUrl } } = useUnitData();

  return (
    <>
      <Confirmation
        hideSummary
        leaseUrl={leaseUrl}
        providerId={providerId}
        openEsignPage={openEsignPage}
      />
      <Box sx={{ borderTopColor: 'gray.lightBorder !important' }} borderTop="4px solid" py={sizes._16px} mx={sizes._12px}>
        <Text variant="mdBold">
          Here are your storage rental details:
        </Text>
      </Box>
      <Box mx={sizes._12px} py={sizes._12px} borderTop="4px solid" borderTopColor="gray.lightBorder">
        <Box display="inline-block" verticalAlign="middle" mr={sizes._12px}>
          <UnitIcon w="49px" h="49px" />
        </Box>
        <Box display="inline-block" verticalAlign="middle">
          <Text variant="mdBold">Unit Details</Text>
          <Text variant="lg" color="gray.lightText">
            {size}
          </Text>
          <Text
            variant="mdBold"
            sx={{ whiteSpace: 'pre', display: 'inline-block' }}
          >
            {`$${effectiveRate} `}
          </Text>
          <Text variant="sm" sx={{ whiteSpace: 'pre', display: 'inline-block' }}>
            Monthly Rent
          </Text>
        </Box>
      </Box>
      <Box mx={sizes._12px} py={sizes._12px} borderTop="4px solid" borderTopColor="gray.lightBorder">
        <Box display="inline-block" verticalAlign="middle" mr={sizes._12px}>
          <FacilityIcon w="138px" h="62px" marginTop={sizes._16px} />
        </Box>
        <Box display="inline-block" verticalAlign="middle" mr={sizes._12px}>
          <Text variant="mdBold">Facility Details</Text>
          <Text variant="header">{displayName}</Text>
          <Text variant="md">{address.streetAddress1}</Text>
          {address.streetAddress2.length > 0 && (
            <Text variant="md">{address.streetAddress2}</Text>
          )}
          <Text variant="md">
            {`${address.city}, ${address.region} ${address.postalCode}`}
          </Text>
          <Text variant="md">{phoneNumber}</Text>
        </Box>
      </Box>
    </>
  );
};

Resumption.propTypes = propTypes;
Resumption.defaultProps = defaultProps;

export default Resumption;
