import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { sizes } from '../../theme';
import { parseJson } from '../../modules/utils';

const propTypes = {
  leaseUrl: PropTypes.string.isRequired,
  signLease: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
const Esign = ({ leaseUrl, signLease, isLoading }) => {
  const [isLeaseSigned, setIsLeaseSigned] = useState(false);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event?.data?.type === 'sign') {
        setIsLeaseSigned(true);
      }
      return event;
    };

    window.addEventListener('message', handleMessage, false);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={sizes._8px}
    >
      <Flex align="center">
        <Text textAlign="center" variant="lgBold" fontSize={{ base: sizes._18px, md: sizes._20px, lg: sizes._24px }} pt={sizes._24px} pb={sizes._16px} px={sizes._8px}>
        Sign Your Lease to Get Access to Your Unit on Your Move-In Date.
        </Text>
      </Flex>
      <Box flex="1" width="100%" height="100%" position="relative">
        <iframe
          src={parseJson(leaseUrl).embed_url}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
            border: 'none',
          }}
          title="lease-signing"
        />
      </Box>
      <Box>
        <Button
          isDisabled={!isLeaseSigned}
          isLoading={isLoading}
          loadingText="Submitting..."
          w={['90vw', '80vw', '40vw']}
          type="button"
          variant="action"
          mb={2}
          onClick={signLease}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

Esign.propTypes = propTypes;

export default Esign;
