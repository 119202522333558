export const EVENTS_NAME = {
  STEP_CHANGE: 'onStepChange',
  ERROR: 'onError',
  FORM_FIELD_CHANGE: 'onFormFieldChange',
  ON_CLICK: 'onClick',
  ON_FIELD_SELECT: 'onFieldSelect',
  ON_RADIO_SELECT: 'onRadioSelect',
  ON_CHECKBOX_SELECT: 'onCheckboxSelect',
};

export const EVENTS_NAME_VALUE = {
  PAGE_NAME: ['personalInfo', 'additionalInfo', 'account', 'payment', 'cofirm', 'leaseSign'],
  UNIT_VIEW_DETAILS_CLICK: ['View unit details clicked', 'Hide unit details clicked'],
  LOAD: 'OMI loaded',

};
