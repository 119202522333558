import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, FormControl, FormErrorMessage, FormLabel, InputGroup, Text } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import get from 'lodash/get';
import { CameraIcon, CancelIcon } from '../Icons';
import { useConfig } from '../../contexts/ConfigProvider';
import { EVENTS_NAME } from '../../modules/events';
import { eventPayload } from '../../modules/utils';

const propTypes = {
  accept: PropTypes.array.isRequired,
  description: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  pdfUrl: PropTypes.string,
  setPdfUrl: PropTypes.func,
};

const defaultProps = {
  description: undefined,
  required: undefined,
  pdfUrl: undefined,
  setPdfUrl: undefined,
};

const FormFileUpload = ({
  accept,
  description,
  label,
  name,
  required,
  pdfUrl,
  setPdfUrl,
}) => {
  const { register, formState: { errors }, resetField, trigger, getValues } = useFormContext();
  const inputRef = useRef();
  const { ref, onChange } = register(name);
  const { [name]: value } = useWatch();
  const [prevPdf, setPrevPdf] = useState(null);
  const { triggerEvent } = useConfig();

  const handleClick = () => inputRef.current?.click();
  const removeImage = () => {
    resetField(name, { defaultValue: [] });
    setPrevPdf(null);
    triggerEvent(
      EVENTS_NAME.FORM_FIELD_CHANGE,
      eventPayload(name, null, null),
    );
  };

  useEffect(() => {
    if (value && value.length > 0 && value[0].type === 'application/pdf') {
      const newPdfUrl = URL.createObjectURL(value[0]);
      if (
        !prevPdf
        || value[0].name !== prevPdf.name
        || value[0].size !== prevPdf.size
        || value[0].lastModified !== prevPdf.lastModified
      ) {
        setPdfUrl(newPdfUrl);
        setPrevPdf(value[0]);
      }
    }
  }, [value]);

  return (
    <FormControl isInvalid={get(errors, name)} isRequired={required}>
      <FormLabel>
        {label}
      </FormLabel>
      <Text variant="sm" sx={{ marginBottom: '8px !important' }}>
        {description}
      </Text>
      <FormErrorMessage>
        {get(errors, name)?.message}
      </FormErrorMessage>
      {value?.length > 0 && value[0].size > 20000000 && value[0].size < 100000000 && (
        <Text color="tertiary.accent" variant="sm">
          {`Your file size is ${(value[0].size / 1000000).toFixed(2)} MB.  Uploads larger than 20 MB may take longer than expected.`}
        </Text>
      )}
      {value?.length > 0 && (
       <Box
         marginTop="8px"
         marginBottom="8px"
         maxWidth={value[0].type === 'application/pdf' ? '300px' : '350px'}
         maxHeight="300px"
         position="relative"
         display="inline-block"
         overflow="hidden"
       >
       <Button
         backgroundColor="gray.lightBorder"
         size="sm"
         rightIcon={<CancelIcon w="10px" h="10px" />}
         onClick={removeImage}
         sx={{
           borderRadius: '0 0 0 6px',
           position: 'absolute',
           right: '0px',
           top: '0px',
           zIndex: 2,
         }}
       >
         {value[0].type === 'application/pdf' ? 'Delete' : 'Delete Image'}
       </Button>

       {value[0].type === 'application/pdf' ? (
         <object
           data={pdfUrl}
           type="application/pdf"
           style={{ maxWidth: '100%', maxHeight: '250px', width: '100%', height: '100%' }}
         >
           {"Driver's License PDF"}
         </object>
       ) : (
         <img
           src={URL.createObjectURL(value[0])}
           alt={label}
           style={{
             maxWidth: '100%',
             maxHeight: '100%',
             display: 'block',
             margin: '0 auto',
           }}
         />
       )}
       </Box>

      )}
      <InputGroup onClick={handleClick}>
        <input
          name={name}
          onChange={(e) => {
            onChange(e);
            trigger(name);
            triggerEvent(
              EVENTS_NAME.FORM_FIELD_CHANGE,
              eventPayload(name, getValues().name, e.target.files[0]),
            );
          }}
          accept={accept}
          hidden
          type="file"
          ref={(e) => {
            ref(e);
            inputRef.current = e;
          }}
        />
        <Button size="lg" leftIcon={<CameraIcon marginBottom="5px" w="26px" h="20px" />} w="100%" bgColor="primary.accent !important" variant="secondaryAction">
          <Text variant="mdBold">
            Upload Image
          </Text>
        </Button>
      </InputGroup>
    </FormControl>
  );
};

FormFileUpload.propTypes = propTypes;
FormFileUpload.defaultProps = defaultProps;

export default FormFileUpload;
