import React from 'react';
import { createIcon } from '@chakra-ui/icons';

const ChevronDown = createIcon({
  displayName: 'ChevronDown',
  viewBox: '0 0 16 16',
  path: (
    <path d="M2.69603 1.10707C2.0329 0.510257 1.01151 0.564015 0.414692 1.22714C-0.182125 1.89028 -0.128368 2.91166 0.534762 3.50848L5.91937 8.35463C6.5337 8.90753 7.4663 8.90753 8.08063 8.35463L13.4652 3.50848C14.1284 2.91166 14.1821 1.89028 13.5853 1.22714C12.9885 0.564015 11.9671 0.510257 11.304 1.10707L7 4.98065L2.69603 1.10707Z" fill="black" />
  ),
});

export default ChevronDown;
