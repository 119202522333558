import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import InsuranceSelector from '../InsuranceSelector';
import LockSelector from '../LockSelector';
import useCostData from '../../hooks/UseCostData';
import useUnitData from '../../hooks/UseUnitData';
import DriversLicense from '../DriversLicense';
import MailingInformation from '../MailingInformation';
import Vehicles from '../Vehicles';
import MilitaryStatus from '../MilitaryStatus';
import Contacts from '../Contacts';
import Lienholders from '../Lienholders';
import { sizes } from '../../theme';
import { useConfig } from '../../contexts/ConfigProvider';

const propTypes = {};
const defaultProps = {};

const Step2 = ({ step }) => {
  const { unitInfo, setUnitInfo } = useCostData();
  const { lock, insurance } = useWatch();
  const {
    data: {
      facility: { insuranceOptions },
      unit: {
        unitType: { insurable },
      },
    },
  } = useUnitData();
  const { configuration } = useConfig();

  useEffect(() => {
    setUnitInfo({ ...unitInfo, lock, insurance });
  }, [lock, insurance]);

  const insuranceOptionLists = insuranceOptions && insuranceOptions.length > 0;

  return (
    <>
      {(insurable && insuranceOptionLists) && (
        <>
          <InsuranceSelector step={step} />
          <Box
            borderBottom="6px solid"
            borderColor="gray.lightBorder"
            marginBottom="8px"
          />
        </>
      )}
      {configuration.providerConfig.showLock && <LockSelector />}
      <DriversLicense />
      <Box
        borderBottom="6px solid"
        borderColor="gray.lightBorder"
        marginBottom={sizes._16px}
        marginTop={sizes._16px}
      />
      <MailingInformation />
      {
        configuration.providerConfig.vehicleCount > 0
        && (
        <>
        <Box
          borderBottom="6px solid"
          borderColor="gray.lightBorder"
          marginBottom={sizes._16px}
          marginTop={sizes._4px}
        />
        <Vehicles step={step} />
        </>
        )
      }
      <Box
        borderBottom="6px solid"
        borderColor="gray.lightBorder"
        marginBottom={sizes._16px}
        marginTop={sizes._16px}
      />
      <MilitaryStatus />
      { configuration.providerConfig.contactCount > 0
        && (
        <>
        <Box
          borderBottom="6px solid"
          borderColor="gray.lightBorder"
          marginBottom={sizes._16px}
          marginTop={sizes._16px}
        />
        <Contacts />
        </>
        )}
      {configuration.providerConfig.lienholderCount > 0 && (
        <>
          <Box
            borderBottom="6px solid"
            borderColor="gray.lightBorder"
            marginBottom={sizes._16px}
            marginTop={sizes._16px}
          />
          <Lienholders />
        </>
      )}
    </>
  );
};

Step2.propTypes = propTypes;
Step2.defaultProps = defaultProps;

export default Step2;
