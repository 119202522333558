import React from 'react';
import { createIcon } from '@chakra-ui/icons';

const Pencil = createIcon({
  displayName: 'Pencil',
  viewBox: '0 0 24 24',
  path: (
    <path d="M18.9719 0.800049C18.6702 0.800049 18.3683 0.914959 18.1384 1.14544L16.1259 3.15794L20.8416 7.87373L22.8541 5.86123C23.315 5.40026 23.315 4.65392 22.8541 4.19413L19.8054 1.14544C19.575 0.914959 19.2736 0.800049 18.9719 0.800049ZM14.3575 4.92636L3.15766 16.1264C3.15766 16.1264 4.34249 16.1323 4.92606 16.7158C5.50963 17.2994 5.4971 18.4658 5.4971 18.4658C5.4971 18.4658 6.69917 18.489 7.28392 19.0737C7.86867 19.6585 7.87338 20.8422 7.87338 20.8422L19.0732 9.64215L14.3575 4.92636ZM1.5896 18.4843L0.799805 23.2001L5.51552 22.4102L1.5896 18.4843Z" fill="#555555" />
  ),
});

export default Pencil;
