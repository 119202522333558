import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, HStack, Text, useRadio, useMultiStyleConfig } from '@chakra-ui/react';
import { useConfig } from '../../contexts/ConfigProvider';
import { EVENTS_NAME } from '../../modules/events';

const propTypes = {
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired, // Add name prop for form field identification
  customStyles: PropTypes.object,
};

const defaultProps = {
  customStyles: {},
};

const RadioCustom = ({ description = '', name, customStyles = {}, ...props }) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const styles = useMultiStyleConfig('RadioCustom');
  const { triggerEvent } = useConfig();

  const input = getInputProps();
  const radio = getCheckboxProps();

  const handleChange = (e) => {
    const { value } = e.target; // Get the selected value
    input.onChange(e); // Call the original onChange handler
    triggerEvent(
      EVENTS_NAME.ON_RADIO_SELECT,
      { name, value },
    );
  };

  return (
    <Box
      as="label"
      __css={{ ...styles.container, ...customStyles.container }}
    >
      <input {...input} onChange={handleChange} />
      <Box
        {...radio}
        cursor="pointer"
        __css={{ ...styles.lukeTest, ...customStyles.lukeTest }}
      >
        <HStack>
          <Flex
            {...radio}
            __css={{ ...styles.checkboxOutline, ...customStyles.checkboxOutline }}
            _checked={styles.onSelectedOutline}
          >
            <Box
              {...radio}
              as="span"
              __css={{ ...styles.onSelectedCenter, ...customStyles.onSelectedCenter }}
            />
          </Flex>
          <Text
            {...radio}
            as="span"
            __css={{ ...styles.label, ...customStyles.label }}
          >
            {description}
          </Text>
        </HStack>
      </Box>
    </Box>
  );
};

RadioCustom.propTypes = propTypes;
RadioCustom.defaultProps = defaultProps;

export default RadioCustom;
