import React from 'react';
import ContactInformation from '../ContactInformation';
import MoveInDate from '../MoveInDate';

const propTypes = {};
const defaultProps = {};

const Step1 = () => (
  <>
    <ContactInformation />
    <MoveInDate />
  </>
);

Step1.propTypes = propTypes;
Step1.defaultProps = defaultProps;

export default Step1;
