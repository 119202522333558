import { Box, Flex, GridItem, Text } from '@chakra-ui/react';
import React from 'react';
import { sizes } from '../../theme';

const SummaryItem = ({ icon, title, text }) => (
  <GridItem colSpan={{ sm: 2, md: 1 }}>
    <Flex direction="row" marginBottom={sizes._18px}>
      <Box mr={sizes._18px}>
        {icon}
      </Box>
      <Flex direction="column">
        <Text variant="mdBold">{title}</Text>
        {text}
      </Flex>
    </Flex>
  </GridItem>
);

export default SummaryItem;
