import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormErrorMessage, FormLabel, HStack, Text, useRadioGroup } from '@chakra-ui/react';
import { useFormContext, useController } from 'react-hook-form';
import useSchema from '../../hooks/UseSchema';
import { sizes } from '../../theme';
import RadioCustom from '../RadioCustom';

const propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
  subHeader: PropTypes.string,
  title: PropTypes.string,
};

const defaultProps = {
  subHeader: undefined,
  title: undefined,
};

const FormRadio = ({
  name,
  options,
  subHeader,
  title,
}) => {
  const { formState: { errors }, control } = useFormContext();
  const { field } = useController({ name, control });

  const { getIsRequired } = useSchema();
  const isRequired = getIsRequired(name);

  const { getRootProps, getRadioProps } = useRadioGroup({
    ...field,
    inputRef: field.ref,
  });

  const group = getRootProps();

  return (
    <FormControl marginTop={sizes._8px} marginBottom={sizes._8px} isRequired={isRequired}>
      <FormLabel marginBottom={sizes._8px}>
        <Text variant="mdBold" display="inline">
          {title}
        </Text>
      </FormLabel>
      {subHeader ? (
        <Text variant="sm" marginBottom={sizes._8px}>
          {subHeader}
        </Text>
      ) : null}
      <HStack spacing={sizes._16px} {...group}>
        {options.map(({ value, description }) => {
          const radio = getRadioProps({ value });
          return (
            <RadioCustom
              {...radio}
              key={value}
              description={description}
            />
          );
        })}
      </HStack>
      <FormErrorMessage>
        <Text variant="sm" marginTop={sizes._4px} color="red.accent">
          {errors[name]?.message}
        </Text>
      </FormErrorMessage>
    </FormControl>
  );
};

FormRadio.propTypes = propTypes;
FormRadio.defaultProps = defaultProps;

export default FormRadio;
