import React, { useEffect, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { LockIcon } from '@chakra-ui/icons';
import { useFormContext, useWatch } from 'react-hook-form';
import { sizes } from '../../theme';
import useUnitData from '../../hooks/UseUnitData';
import FormInput from '../FormInput';
import FormCheckbox from '../FormCheckbox';

const numberMasks = {
  3: '****-******-*****',
  4: '****-****-****-****',
  5: '****-****-****-****',
  6: '****-****-****-****',
};

const CardInformation = () => {
  const [mask, setMask] = useState();
  const { setValue } = useFormContext();
  const { ccNumber, saveCreditCard, enrollAutoPay } = useWatch();

  const { data: {
    facility: { name, autoPayRequired },
    unit: { rates: { baseRate, effectiveRate } },
  } } = useUnitData();

  const tooltipRate = parseInt(effectiveRate, 10) || parseInt(baseRate, 10);

  useEffect(() => {
    setMask(numberMasks[ccNumber?.charAt(0)]);
  }, [ccNumber]);

  useEffect(() => {
    if (autoPayRequired) {
      setValue('saveCreditCard', true);
      setValue('enrollAutoPay', true);
    }
  }, [autoPayRequired, setValue]);

  useEffect(() => {
    if (saveCreditCard) { return; }
    setValue('enrollAutoPay', false);
  }, [saveCreditCard, enrollAutoPay]);

  return (
    <Box borderBottom="4px solid" borderColor="gray.lightBorder" paddingBottom={sizes._14px} marginBottom={sizes._16px}>
      <Text variant="mdBold" marginBottom={sizes._8px}>Card Information</Text>
      <FormInput
        name="ccNumber"
        label="Credit Card Number"
        inputMode="numeric"
        mask={mask}
        pattern="[0-9]*"
        icon={<LockIcon />}
        message="Not a valid Credit Card Number"
      />
      <Flex gap={sizes._8px} marginBottom={sizes._4px}>
        <FormInput
          name="ccExp"
          label="Expiration Date"
          placeholder="mm/yy"
          mask="00/00"
          pattern="[0-9]*"
        />
        <FormInput
          name="ccCSC"
          label="CVV"
          type="password"
          pattern="[0-9]*"
        />
      </Flex>

    {autoPayRequired
      ? (
      <Text variant="sm" color="gray.dark" marginBottom={sizes._8px}>
          The cardholder/account holder authorizes to charge any amounts due per the
            terms of the agreement for each period during the term of occupancy or until
            the cardholder/account holder cancels this authorization.
      </Text>
      )
      : (
      <>
        <FormCheckbox
          name="saveCreditCard"
          label="Save card on file for future payments"
          marginBottom={sizes._24px}
        />
        <FormCheckbox
          name="enrollAutoPay"
          label="Enroll in auto pay"
          tooltip={`Auto pay is a free service that automatically deducts payments from your credit card. By enrolling in auto pay, you agree to a monthly charge of $${tooltipRate} from ${name}.`}
          isDisabled={!saveCreditCard}
          isChecked={enrollAutoPay}
        />
      </>
      )}

    </Box>
  );
};

export default CardInformation;
