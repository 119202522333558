import React from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Flex,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import get from 'lodash/get';
import { sizes } from '../../theme';
import Tooltip from '../Tooltip';
import useSchema from '../../hooks/UseSchema';
import * as styles from './FormSelect.module.scss';

const propTypes = {
  title: PropTypes.string,
  tooltip: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const defaultProps = {
  title: undefined,
  tooltip: undefined,
  description: undefined,
  label: undefined,
};

const FormSelect = ({
  title,
  tooltip,
  description,
  name,
  label,
  options,
  required,
  ...rest
}) => {
  const { formState: { errors }, register } = useFormContext();
  const { getIsRequired } = useSchema();
  const isRequired = getIsRequired(name);

  return (
    <>
      {title ? (
        <Flex marginBottom={sizes._2px} alignItems="center">
          <Text as="span" variant="mdBold">
            {title}
          </Text>
          <Tooltip label={tooltip} />
        </Flex>
      ) : null}
      {description ? (
        <Text variant="sm" color="gray.lightText" marginBottom={sizes._12px}>
          {description}
        </Text>
      ) : null}
      <FormControl isInvalid={get(errors, name)} marginBottom={sizes._12px} variant="floating" isRequired={required || isRequired}>
        <Select
          {...rest}
          variant="outline"
          placeholder="‎"
          {...register(name)}
        >
          {options.map((option) => (
            <option
              className={styles.selectOption}
              key={option.id}
              value={option.id}
            >
              {option.description}
            </option>
          ))}
        </Select>
        <FormLabel>
          {label}
        </FormLabel>
        <FormErrorMessage>
          <Text variant="sm" marginTop={sizes._4px} color="red.accent">
            {get(errors, name)?.message}
          </Text>
        </FormErrorMessage>
      </FormControl>
    </>
  );
};

FormSelect.propTypes = propTypes;
FormSelect.defaultProps = defaultProps;

export default FormSelect;
