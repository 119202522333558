import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, FormControl, FormErrorMessage, FormLabel, Text } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import get from 'lodash/get';
import { useDropzone } from 'react-dropzone';
import { CancelIcon } from '../Icons';
import { EVENTS_NAME } from '../../modules/events';
import { eventPayload } from '../../modules/utils';
import { useConfig } from '../../contexts/ConfigProvider';

const propTypes = {
  description: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  pdfUrl: PropTypes.string,
  setPdfUrl: PropTypes.func,
};

const defaultProps = {
  description: undefined,
  required: undefined,
  pdfUrl: undefined,
  setPdfUrl: undefined,
};

const FormFileUpload = ({
  acceptDropzone,
  description,
  label,
  name,
  required,
  pdfUrl,
  setPdfUrl,
}) => {
  const {
    formState: { errors },
    resetField,
    register,
    setValue,
  } = useFormContext();
  const { [name]: value } = useWatch();
  const { triggerEvent } = useConfig();

  const [prevPdf, setPrevPdf] = useState(null);

  useEffect(() => {
    if (value && value.length > 0 && value[0].type === 'application/pdf') {
      const newPdfUrl = URL.createObjectURL(value[0]);
      if (
        !prevPdf
        || value[0].name !== prevPdf.name
        || value[0].size !== prevPdf.size
        || value[0].lastModified !== prevPdf.lastModified
      ) {
        setPdfUrl(newPdfUrl);
        setPrevPdf(value[0]);
      }
    }
  }, [value]);

  const removeImage = (e) => {
    e.stopPropagation();
    resetField(name, { defaultValue: [] });
    setPrevPdf(null);
    triggerEvent(
      EVENTS_NAME.FORM_FIELD_CHANGE,
      eventPayload(name, null, null),
    );
  };

  const onDrop = useCallback((files) => {
    setValue(name, files, { shouldValidate: true });
    triggerEvent(
      EVENTS_NAME.FORM_FIELD_CHANGE,
      eventPayload(name, value, files),
    );
  }, [name, setValue, triggerEvent, value]);

  useEffect(() => {
    register(name);
  }, [register, name]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: acceptDropzone });

  return (
    <FormControl isInvalid={get(errors, name)} isRequired={required}>
      <FormLabel>
        {label}
      </FormLabel>
      <Text variant="sm" sx={{ marginBottom: '8px !important' }}>
        {description}
      </Text>
      <FormErrorMessage>
        {get(errors, name)?.message}
      </FormErrorMessage>
      {value?.length > 0 && value[0].size > 20000000 && value[0].size < 100000000 && (
        <Text color="tertiary.accent" variant="sm">
          {`Your file size is ${(value[0].size / 1000000).toFixed(2)} MB.  Uploads larger than 20 MB may take longer than expected.`}
        </Text>
      )}
      {value?.length > 0 ? (
        <Box marginTop="8px" marginBottom="8px" maxWidth={value[0].type === 'application/pdf' ? '300px' : '350px'} maxHeight="300px" position="relative">
          <Button
            backgroundColor="gray.lightBorder"
            size="sm"
            rightIcon={<CancelIcon w="10px" h="10px" />}
            onClick={removeImage}
            sx={{ borderRadius: '0 0 0 6px', position: 'absolute', right: 0 }}
          >
           {value[0].type === 'application/pdf' ? 'Delete' : 'Delete Image' }
          </Button>
          { value[0].type === 'application/pdf' ? (
            <object data={pdfUrl} type="application/pdf" style={{ maxWidth: '300px', maxHeight: '300px', width: '100%', height: '100%' }}>
              {"Driver's License PDF"}
            </object>
          )
            : <img src={URL.createObjectURL(value[0])} alt={label} style={{ maxWidth: '350px', maxHeight: '300px' }} />}
        </Box>
      ) : (
        <Box {...getRootProps()} p="10px" backgroundColor="gray.lightBorder">
          <Box border="2px dashed" borderColor="gray.veryLightBorder" p="32px" textAlign="center">
            <input {...getInputProps()} />
            <>
              <Text variant="lgBold">
                {`Upload ${label}`}
              </Text>
              <Text variant="mdBold" textDecoration="underline" color="gray.lightText">
                Drag and drop your image or click here to select an image
              </Text>
            </>
          </Box>
        </Box>
      )}
    </FormControl>
  );
};

FormFileUpload.propTypes = propTypes;
FormFileUpload.defaultProps = defaultProps;

export default FormFileUpload;
