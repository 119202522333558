.steps {
  display: flex;
  gap: 10px;
}

.currentStepText {
  opacity: .5;
}

.step {
  border-radius: 100px;
  background-color: var(--chakra-colors-primary-accent);
  flex-grow: 1;
  height: 4px;
  width: 25%;
}

.futureStep {
  border-radius: 100px;
  background-color: #EAEAEA;
  flex-grow: 1;
  height: 4px;
  width: 25%;
}
