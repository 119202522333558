import React from 'react';
import { createIcon } from '@chakra-ui/icons';

const Cancel = createIcon({
  displayName: 'Cancel',
  viewBox: '0 0 10 10',
  path: (
    <path d="M0.759421 0.76011C1.22759 0.291942 1.98664 0.291942 2.45481 0.76011L4.99302 3.29901L7.5473 0.744781C8.01547 0.276612 8.77452 0.276612 9.24269 0.744781C9.71086 1.21295 9.71086 1.972 9.24269 2.44017L6.68841 4.9944L9.22546 7.53076C9.69362 7.99893 9.69362 8.75798 9.22546 9.22615C8.75729 9.69431 7.99824 9.69431 7.53007 9.22615L4.99302 6.68979L2.45279 9.23006C1.98462 9.69823 1.22557 9.69823 0.757406 9.23006C0.289238 8.76189 0.289238 8.00284 0.757406 7.53468L3.29764 4.9944L0.759421 2.4555C0.291253 1.98733 0.291253 1.22828 0.759421 0.76011Z" fill="#555555" />
  ),
});

export default Cancel;
