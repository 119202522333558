import React from 'react';
import PropTypes from 'prop-types';
import { SimpleGrid } from '@chakra-ui/react';
import FormInput from '../FormInput';

const propTypes = {
  index: PropTypes.number,
};
const defaultProps = {
  index: undefined,
};

const name = 'contacts';

const ContactForm = ({ index }) => (
  <SimpleGrid columns={{ sm: 1, md: 2 }} spacingX={2}>
    <FormInput name={`${name}.${index}.firstName`} label="First Name" />
    <FormInput name={`${name}.${index}.lastName`} label="Last Name" />
    <FormInput name={`${name}.${index}.relationship`} label="Relationship to You" />
    <FormInput
      name={`${name}.${index}.phone`}
      label="Phone Number"
      mask="(000) 000-0000"
      type="tel"
    />
  </SimpleGrid>
);

ContactForm.propTypes = propTypes;
ContactForm.defaultProps = defaultProps;

export default ContactForm;
