/* eslint-disable no-extra-boolean-cast */
import React from 'react';
import { Text } from '@chakra-ui/react';
import FormDatePicker from '../FormDatePicker';
import { sizes } from '../../theme';
import useUnitData from '../../hooks/UseUnitData';

const propTypes = {};
const defaultProps = {};

const MoveInDate = () => {
  const {
    data: {
      allowedDateMoveInAhead,
    },
  } = useUnitData();
  return (
    <div>
      <Text variant="mdBold" marginBottom={sizes._12px}>
        Move-In Information
      </Text>
      <FormDatePicker name="moveInDate" placeholder="Move-In Date" dateDisabled={!Boolean(allowedDateMoveInAhead)} />
      <Text variant="smLight" marginTop={sizes._8px}>
        If you&#39;re unsure of your move-in date, select
        an estimated date. This can be changed later.
      </Text>
    </div>
  );
};

MoveInDate.propTypes = propTypes;
MoveInDate.defaultProps = defaultProps;

export default MoveInDate;
