import React from 'react';
import { Center, Spinner } from '@chakra-ui/react';
import { colors } from '../../theme';

const propTypes = {};
const defaultProps = {};

const InitialLoadingScreen = () => (
  <Center h="500px" w="100%" bg={colors.gray.lightBorder}>
    <Spinner
      size="xl"
      opacity="0.5"
    />
  </Center>
);

InitialLoadingScreen.propTypes = propTypes;
InitialLoadingScreen.defaultProps = defaultProps;

export default InitialLoadingScreen;
