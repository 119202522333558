import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  Text,
  Divider,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { sizes } from '../../theme';
import FormCheckbox from '../FormCheckbox';

import useUnitData from '../../hooks/UseUnitData';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const defaultProps = {};

const Terms = ({ isOpen, onClose, onSubmit }) => {
  const { getValues } = useFormContext();
  const [disable, setDisable] = useState(false);
  const { insurance, confirmPrivateInsurance } = getValues();
  const {
    data: {
      facility: { insuranceOptions, alias },
    },
  } = useUnitData();

  const providerId = alias.split(':')[2];

  // Check if autoProtect is true for the current insurance in the insuranceOptions array
  const isAutoProtectEnabled = insurance === 'private'
    && providerId === 'se'
    && insuranceOptions?.some((option) => option.id === insurance && option.autoProtect);

  useEffect(() => {
    if (isAutoProtectEnabled) {
      if (!confirmPrivateInsurance) {
        setDisable(true);
      } else {
        setDisable(false);
      }
    } else {
      setDisable(false);
    }
  }, [isAutoProtectEnabled, confirmPrivateInsurance]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <div className="OMI_CSS_CONTAINER">
        <ModalContent>
          <ModalHeader>Terms and Conditions</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isAutoProtectEnabled && (
              <Box>
                <FormCheckbox
                  name="confirmPrivateInsurance"
                  label="Confirm Private Insurance"
                />
                <Text>
                  Our rental agreement requires all occupants to maintain insurance coverage for
                  loss and damage to their stored property. By choosing the private insurance
                   option above,
                  you agree to provide proof of insurance coverage within thirty (30) days.
                  If you do not provide such proof to the facility within thirty (30)
                  days, you agree to be automatically enrolled in the third party insurance plan.
                </Text>
                <Divider my={4} />
              </Box>
            )}

<Text>
  By submitting your credit card information you are confirming your
  desire to rent the unit set forth on this confirmation page and authorizing
  your credit card to be charged {'"Today\'s Total Charges"'} as detailed above.
  To gain access to your rented unit, please review and sign the lease that is
  presented to you in the next step.
  Only proceed with making this payment if your intent is to rent the storage unit.
  If, prior to signing your lease you no longer wish to proceed with your rental,
  please contact the storage facility to discuss next steps. Recurring monthly charges
  and/or refunds are unique to each facility.
</Text>
          </ModalBody>
          <ModalFooter sx={{ p: `${sizes._16px} !important`, boxSizing: 'border-box' }}>
            <Button onClick={onClose} flexGrow="1" variant="inaction" w="unset">
              Cancel
            </Button>
            <Button
              onClick={onSubmit}
              flexGrow="1"
              variant="action"
              w="unset"
              isDisabled={disable}
            >
              Agree & Submit Payment
            </Button>
          </ModalFooter>
        </ModalContent>
      </div>
    </Modal>
  );
};

Terms.propTypes = propTypes;
Terms.defaultProps = defaultProps;

export default Terms;
