import { useContext } from 'react';
import { WidthContext } from '../contexts/WidthProvider';

export const useWidth = () => (
  useContext(WidthContext)
);

const useWidthQuery = (size) => {
  const { width } = useWidth();
  return width >= size;
};

export default useWidthQuery;
