import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { sizes } from '../../theme';
import {
  CalendarIcon,
  ContactIcon,
  CreditCardIcon,
  DriverIcon,
  FacilityIcon,
  InsuranceIcon,
  LocationIcon,
  LockIcon,
  MoneyIcon,
  UnitIcon,
} from '../Icons';
import useUnitData from '../../hooks/UseUnitData';
import useCostData from '../../hooks/UseCostData';
import { TABLET_WIDTH } from '../../theme/media';
import useWidthQuery from '../../hooks/UseWidth';
import SummaryItem from './SummaryItem';
import SummaryRow from './SummaryRow';
import { checkServicePeriod, showDescription } from '../../modules/utils';

const propTypes = {
  isPageReloaded: PropTypes.bool,
};

const defaultProps = {
  isPageReloaded: false,
};

const useSummaryData = (isPageReloaded) => {
  const { getValues } = useFormContext();
  const unitData = useUnitData();
  const costData = useCostData();
  if (isPageReloaded) {
    const {
      email,
      firstName,
      lastName,
      phone,
    } = getValues();

    const {
      data: {
        unit,
        facility,
        reservation: { contact: { address: mailing } },
        insurance,
        moveInDate,
      },
    } = unitData;

    return {
      formData: {
        email,
        firstName,
        lastName,
        phone,
        mailing: mailing ? {
          addressOne: mailing.streetAddress1,
          addressTwo: mailing.streetAddress2,
          city: mailing.city,
          state: mailing.region,
          zipCode: mailing.postalCode,
        } : null,
        moveInDate,
        insurance: insurance
          ? { description: `Cover up to $${parseFloat(insurance?.description?.split('-')[1].trim().replace(/[$,]/g, '')).toLocaleString()} for $${insurance?.premium} / Month` } : null,
      },
      unitData: unit,
      facilityData: facility,
      costData: costData.data,
      driversLicense: null,
      lock: null,
      billing: null,
    };
  }

  const {
    billing,
    ccNumber,
    driversLicense,
    email,
    firstName,
    insurance,
    lastName,
    lock,
    mailing,
    moveInDate,
    phone,
  } = getValues();

  const {
    data: {
      unit,
      facility,
    },
  } = unitData;

  const providerId = facility.alias.split(':')[2];
  const insuranceDesc = facility.insuranceOptions.find((opt) => {
    const insuranceId = providerId === 'sl' ? parseInt(insurance, 10) : insurance;
    return opt.id === insuranceId;
  })?.description;
  const lockDesc = facility.lockOptions.find((opt) => opt.id === lock)?.description;

  return {
    formData: {
      email,
      firstName,
      lastName,
      phone,
      ccNumber,
      mailing,
      moveInDate,
      insurance: insurance ? { description: insuranceDesc } : null,
    },
    unitData: unit,
    facilityData: facility,
    costData: costData.data,
    driversLicense,
    lock: lock ? { description: lockDesc } : null,
    billing,
  };
};

const Summary = ({ isPageReloaded }) => {
  const isLargerThan768 = useWidthQuery(TABLET_WIDTH);
  const {
    formData,
    unitData,
    facilityData,
    costData,
    driversLicense,
    lock,
    billing,
  } = useSummaryData(isPageReloaded);
  const showNextMonthPayment = facilityData.nextMonthPayment
    && facilityData.invoicePeriod === 'monthly'
    && checkServicePeriod(facilityData.nextMonthPaymentDay);

  if (!costData?.charges) {
    return 'Loading...';
  }

  const date = new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const details = (
    <SummaryRow padRow>
      <SummaryItem
        icon={<UnitIcon color="primary.accent" h="49px" w="49px" />}
        title="Unit Details"
        text={(
          <>
            <Text variant="lg" color="gray.lightText">
              {unitData.size}
            </Text>
            <Text
              variant="mdBold"
              sx={{ whiteSpace: 'pre', display: 'inline-block' }}
            >
              {`$${unitData.rates.effectiveRate} `}
            </Text>
            <Text
              variant="sm"
              sx={{ whiteSpace: 'pre', display: 'inline-block' }}
            >
              Monthly Rent
            </Text>
          </>
        )}
      />
    </SummaryRow>
  );

  return (
    <>
      <Flex direction="column" rowGap={sizes._18px}>
        {!isLargerThan768 && details}
        <SummaryRow padRow>
          <SummaryItem
            icon={<ContactIcon color="primary.accent" h="49px" w="49px" />}
            title="Contact Information"
            text={(
              <>
                <Text variant="lg" color="gray.lightText">
                  {`${formData.firstName} ${formData.lastName}`}
                </Text>
                <Text variant="md" color="gray.lightText" lineHeight={sizes._24px}>
                  {formData.email}
                </Text>
                <Text variant="md" color="gray.lightText" lineHeight={sizes._24px}>
                  {formData.phone}
                </Text>
              </>
            )}
          />
          <SummaryItem
            icon={<CalendarIcon color="primary.accent" h="49px" w="49px" />}
            title="Move-In Date"
            text={(
              <Text variant="lg" color="gray.lightText">
                {date.format(formData.moveInDate)}
              </Text>
            )}
          />
        </SummaryRow>

        {(formData.insurance || lock) && (
          <SummaryRow padRow>
            {unitData.unitType.insurable && formData.insurance && (
              <SummaryItem
                icon={<InsuranceIcon color="primary.accent" h="49px" w="49px" />}
                title="Insurance Coverage"
                text={(
                  <Text variant="lg" color="gray.lightText">
                    {formData.insurance.description}
                  </Text>
                )}
              />
            )}
            {lock && (
              <SummaryItem
                icon={<LockIcon color="primary.accent" h="49px" w="49px" />}
                title="Lock Option"
                text={(
                  <Text variant="lg" color="gray.lightText">
                    {lock.description}
                  </Text>
                )}
              />
            )}
          </SummaryRow>
        )}

        { (driversLicense || formData.mailing) && (
          <SummaryRow padRow>
            {driversLicense && (
              <SummaryItem
                icon={<DriverIcon color="primary.accent" h="49px" w="49px" />}
                title="Drivers License Details"
                text={(
                  <>
                    <Text variant="lg" color="gray.lightText">
                      {driversLicense.number}
                    </Text>
                    <Text variant="lg" color="gray.lightText">
                      {`${driversLicense.state} ${driversLicense.expirationDate}`}
                    </Text>
                    <Text variant="lg" color="gray.lightText">
                      {`DOB ${driversLicense.birthDate}`}
                    </Text>
                  </>
                )}
              />
            )}
            {formData.mailing && (
              <SummaryItem
                icon={<LocationIcon color="primary.accent" h="49px" w="49px" />}
                title="Mailing Address"
                text={(
                  <>
                    <Text variant="lg" color="gray.lightText">
                      {formData.mailing.addressOne}
                    </Text>
                    <Text variant="lg" color="gray.lightText">
                      {formData.mailing.addressTwo}
                    </Text>
                    <Text variant="lg" color="gray.lightText">
                      {`${formData.mailing.city}, ${formData.mailing.state} ${formData.mailing.zipCode}`}
                    </Text>
                  </>
                )}
              />
            )}
          </SummaryRow>
        )}

      {(formData.ccNumber || billing) && (
        <SummaryRow padRow>
          {formData.ccNumber && (
          <SummaryItem
            icon={<CreditCardIcon color="primary.accent" h="49px" w="49px" />}
            title="Credit Card"
            text={(
              <Text variant="lg" color="gray.lightText">
                {`${formData.ccNumber
                  .substring(0, formData.ccNumber.length - 4)
                  .replaceAll('-', ' ')
                  .replaceAll(/\d/g, '*')}${formData.ccNumber.substring(
                  formData.ccNumber.length - 4,
                  formData.ccNumber.length,
                )}`}
              </Text>
            )}
          />
          )}
          {billing && (
            <SummaryItem
              icon={<LocationIcon color="primary.accent" h="49px" w="49px" />}
              title="Billing Address"
              text={(
                <>
                  <Text variant="lg" color="gray.lightText">
                    {billing.addressOne}
                  </Text>
                  <Text variant="lg" color="gray.lightText">
                    {billing.addressTwo}
                  </Text>
                  <Text variant="lg" color="gray.lightText">
                    {`${billing.city}, ${billing.state} ${billing.zipCode}`}
                  </Text>
                </>
              )}
            />
          )}
        </SummaryRow>
      )}

        {isLargerThan768 && details}
        <SummaryRow padRow isEditable={false}>
          <SummaryItem
            icon={<MoneyIcon color="primary.accent" h="49px" w="49px" />}
            title="Today's Total Charges"
            text={(
              <Grid templateColumns="1fr 1fr" rowGap={sizes._8px}>
                {costData.charges.map((charge) => (
                  <Fragment key={charge.description + charge.amount}>
                    <GridItem>
                      <Text variant="sm">
                        {!isPageReloaded
                          ? showDescription(charge, showNextMonthPayment, facilityData.timezone)
                          : charge.description}
                      </Text>
                    </GridItem>
                    <GridItem>
                      <Text variant="smBold" align="right">
                        {`$${charge.amount}`}
                      </Text>
                    </GridItem>
                  </Fragment>
                ))}
                {costData.totalTaxes && (
                  <>
                    <GridItem>
                      <Text variant="sm">Tax</Text>
                    </GridItem>
                    <GridItem>
                      <Text variant="smBold" align="right">
                        {`$${costData.totalTaxes}`}
                      </Text>
                    </GridItem>
                  </>
                )}
                <GridItem
                  colSpan={2}
                  borderBottom="2px solid"
                  borderColor="gray.lightBorder"
                />
                <GridItem>
                  <Text variant="md">Your Payment Today</Text>
                </GridItem>
                <GridItem>
                  <Text variant="mdBold" align="right">
                    {`$${costData.dueToday}`}
                  </Text>
                </GridItem>
              </Grid>
            )}
          />
        </SummaryRow>
      </Flex>

      <Box
        px={sizes._16px}
        py={sizes._24px}
      >
        <FacilityIcon color="primary.accent" w="138px" h="62px" marginTop={sizes._16px} />
        <Text variant="mdBold">Facility Details</Text>
        <Text variant="header">{facilityData.displayName}</Text>
        <Text variant="md">{facilityData.address.streetAddress1}</Text>
        {facilityData.address.streetAddress2.length > 0 && (
          <Text variant="md">{facilityData.address.streetAddress2}</Text>
        )}
        <Text variant="md">
          {`${facilityData.address.city}, ${facilityData.address.region} ${facilityData.address.postalCode}`}
        </Text>
        <Text variant="md">{facilityData.phoneNumber}</Text>
      </Box>
    </>
  );
};

Summary.propTypes = propTypes;
Summary.defaultProps = defaultProps;

export default Summary;
