import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import cloneDeep from 'lodash/cloneDeep';
import useCostData from '../../hooks/UseCostData';
import useUnitData from '../../hooks/UseUnitData';
import { fetchCostData } from '../../modules/api';

const propTypes = {
  title: PropTypes.string,
};

const defaultProps = {
  title: '',
};

const EditScreen = ({
  isOpen,
  closeModal,
  title,
  children,
  requiredFields,
  onCloseRef,
}) => {
  const { formState, getFieldState, getValues, reset } = useFormContext();
  const [initialValues, setInitialValues] = useState();
  const { unitInfo, setData, setIsLoading } = useCostData();
  const { data: { formConfig } } = useUnitData();

  useEffect(() => {
    setInitialValues(cloneDeep(getValues()));
  }, [title]);

  const resetValues = () => {
    reset(initialValues);
    if (unitInfo.insurance !== initialValues.insurance || unitInfo.lock !== initialValues.lock) {
      unitInfo.insurance = initialValues.insurance;
      unitInfo.lock = initialValues.lock;
      fetchCostData(unitInfo, formConfig.insuranceKey)
        .then((res) => res.json())
        .then(({ charges }) => {
          setIsLoading(false);
          if (charges.error) {
            window.storableOmi?.onError?.({ action: 'retrieveCosts', errors: [charges.message] });
          } else {
            setData(charges);
          }
        })
        .catch((e) => {
          window.storableOmi?.onError?.({ action: 'retrieveCosts', errors: [e.message] });
          setIsLoading(false);
        });
    }
    closeModal();
  };

  const isDisabled = requiredFields
    .map((field) => {
      const { invalid } = getFieldState(field, formState);
      return !invalid;
    })
    .includes(false);

  return (
    <Modal {...{ isOpen, closeModal }} returnFocusOnClose={onCloseRef} size="full">
      <ModalOverlay />
      <div className="OMI_CSS_CONTAINER">
      <ModalContent>
        <ModalHeader>
          <Text variant="xsBold" color="gray.lightText" sx={{ padding: '0px !important' }}>
            EDIT
          </Text>
          <Text variant="header" sx={{ padding: '0px !important' }}>
            {title}
          </Text>
        </ModalHeader>
        <ModalBody>{isOpen && children}</ModalBody>
        <ModalFooter>
          <Button onClick={resetValues} variant="inaction">
            Discard Changes
          </Button>
          <Button isDisabled={isDisabled} onClick={closeModal} variant="action">
            Save Changes
          </Button>
        </ModalFooter>
      </ModalContent>
      </div>
    </Modal>
  );
};

EditScreen.propTypes = propTypes;
EditScreen.defaultProps = defaultProps;

export default EditScreen;
