import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@chakra-ui/react';
import FormInput from '../FormInput';
import StateSelector from '../StateSelector';
import { sizes } from '../../theme';

const propTypes = {
  index: PropTypes.number,
};
const defaultProps = {
  index: undefined,
};

const name = 'vehicles';

const VehicleForm = ({ index }) => (
  <>
    <Flex gap={sizes._8px}>
      <Box flex="1">
        <FormInput name={`${name}.${index}.make`} label="Make" />
      </Box>
      <Box flex="1">
        <FormInput name={`${name}.${index}.model`} label="Model" />
      </Box>
    </Flex>
    <Flex gap={sizes._8px}>
      <Box flex="1">
        <FormInput name={`${name}.${index}.license`} label="License Plate" />
      </Box>
      <Box flex="1">
        <StateSelector name={`${name}.${index}.state`} />
      </Box>
    </Flex>
    <FormInput name={`${name}.${index}.VIN`} label="VIN" />
    <FormInput name={`${name}.${index}.description`} label="Description" />
  </>
);

VehicleForm.propTypes = propTypes;
VehicleForm.defaultProps = defaultProps;

export default VehicleForm;
