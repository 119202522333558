import React, { useEffect } from 'react';
import { useForm, FormProvider as HookFormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import defaultValues from '../../modules/defaultFormValues';
import useSchema from '../../hooks/UseSchema';
import useUnitData from '../../hooks/UseUnitData';
import getSchema from './schema';
import { useConfig } from '../ConfigProvider';

// export const LOCAL_STORAGE_KEY = 'omiUserInputs'; // Key for persisting OMI user inputs

const FormProvider = ({ children, initialValues = defaultValues }) => {
  const {
    data: { facility: { cardsAccepted, insuranceSettings, alias } = {}, formConfig },
  } = useUnitData();
  const providerId = alias?.split(':')[2];
  const { configuration } = useConfig();
  const { setSchema } = useSchema();

  const schema = getSchema({
    cardsAccepted,
    insuranceName: configuration.insurance.name || insuranceSettings?.name,
    providerId,
    formConfig: {
      // ...formConfig,
      vehicleCount: configuration.providerConfig.vehicleCount,
      contactCount: configuration.providerConfig.contactCount,
      lienholderCount: configuration.providerConfig.lienholderCount,
    },
  });

  useEffect(
    () => setSchema(schema),
    [cardsAccepted, insuranceSettings, providerId, formConfig, configuration],
  );

  /* // Load persisted data from localStorage
  // WA-549 revert
  const persistedData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {}; */

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: {
      ...defaultValues,
      ...initialValues,
    },
    resolver: yupResolver(
      getSchema({
        cardsAccepted,
        insuranceName: configuration.insurance.name || insuranceSettings?.name,
        providerId,
        formConfig: {
          // ...formConfig,
          vehicleCount: configuration.providerConfig.vehicleCount,
          contactCount: configuration.providerConfig.contactCount,
          lienholderCount: configuration.providerConfig.lienholderCount,
        },
      }),
    ),
  });

  /* const { setValue } = methods;

  useEffect(() => {
    if (persistedData && Object.keys(persistedData).length > 0) {
      // Function to handle setting values dynamically
      const setFormValues = (prefix, data) => {
        Object.entries(data).forEach(([key, value]) => {
          const fieldKey = `${prefix}.${key}`;
          if (value) {
            // If the value is an object, recursively handle it
            if (typeof value === 'object' && !Array.isArray(value)) {
              setFormValues(fieldKey, value);
            } else {
              // If the value is not an empty string or null, set it
              setValue(fieldKey, value, { shouldDirty: true });
            }
          }
        });
      };

      // Call the function for top-level properties
      Object.entries(persistedData).forEach(([key, value]) => {
        if (value) {
          if (typeof value === 'object' && !Array.isArray(value)) {
            setFormValues(key, value); // Handle nested objects
          } else if (key === 'moveInDate') {
            setValue(key, new Date(value), { shouldDirty: true });
          } else {
            setValue(key, value, { shouldDirty: true });
          }
        }
      });
    }
  }, [formConfig]); */ // WA-549 revert

  return <HookFormProvider {...methods}>{children}</HookFormProvider>;
};

export default FormProvider;
