import React from 'react';
import { createIcon } from '@chakra-ui/icons';

const Amex = createIcon({
  displayName: 'Amex',
  viewBox: '0 0 80 80',
  path: (
    <>
      <polygon
        fill="#FFFFFF"
        points="15,16 15,40.9219055 15,66 39.9992676,66 64.9999847,66 64.9999847,41.0007019 64.9999847,16
        40.0089722,16 15,16"
      />
      <path
        fill="#006FCF"
        d="M56.0832977,28.2666931l1.5999908-4.3500061h7.3166962V16H15v50h49.9999847v-7.8999939h-6.9000092
        l-2.5499725-2.9833069l-2.6500244,2.9833069H33.2999878V42.2167053h-6.4667053L34.916687,23.916687h7.8665771l1.9000244,4.1499939
        V23.916687h9.7667084L56.0832977,28.2666931L56.0832977,28.2666931z M50.5666962,31.1500244l-0.0167084-1.7500305
        l0.6667175,1.7500305l3.2499847,8.6832886h3.2332916l3.2667084-8.6832886l0.6332855-1.7333374v10.416626h3.4000092V26.5
        h-5.6500092l-2.5666962,6.7667236l-0.6832886,1.8332825l-0.6999969-1.8332825L52.8166962,26.5H47.166687v13.333313h3.4000092
        V31.1500244L50.5666962,31.1500244z M43.25,39.833313h3.916687L41.2999878,26.5H36.75l-5.9000244,13.333313h3.8667297
        L35.75,37.2666931h6.4667053L43.25,39.833313L43.25,39.833313z M38.3167114,31.0167236l0.6665649-1.6667175l0.6667175,1.6667175
        l1.3833008,3.3665771h-4.1000061L38.3167114,31.0167236L38.3167114,31.0167236z M36.0332642,42.2332764v13.2834167H47.166687
        v-2.8833923h-7.7333984v-2.3165894h7.5834045v-2.8666992h-7.5834045v-2.333313h7.7333984v-2.8834229H36.0332642
        L36.0332642,42.2332764z M59.5499878,55.5166931h4.4167175l-6.2334137-6.6667175l6.2334137-6.6166992h-4.3500061
        l-4.0167236,4.3167419l-3.9999847-4.3167419H47.166687l6.2165985,6.6667175l-6.2165985,6.6166992h4.3000031l4.0500031-4.333374
        L59.5499878,55.5166931L59.5499878,55.5166931z M61.2332916,48.833313l3.7666931,3.833374v-7.6166687L61.2332916,48.833313
        L61.2332916,48.833313z"
      />
    </>
  ),
});

export default Amex;
