import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  Grid,
  GridItem,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { sizes } from '../../theme';
import {
  CalendarIcon,
  ContactIcon,
  CreditCardIcon,
  DriverIcon,
  FacilityIcon,
  InsuranceIcon,
  LocationIcon,
  LockIcon,
  MoneyIcon,
  UnitIcon,
} from '../Icons';
import useUnitData from '../../hooks/UseUnitData';
import useCostData from '../../hooks/UseCostData';
import EditScreen from '../EditScreen';
import { baseSteps } from '../MoveIn/reducer';
import { TABLET_WIDTH } from '../../theme/media';
import useWidthQuery from '../../hooks/UseWidth';
import SummaryItem from './SummaryItem';
import SummaryRow from './SummaryRow';

const propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
    }),
  ),
  editable: PropTypes.bool,
  confirmationScreen: PropTypes.bool,
};

const defaultProps = {
  errors: [],
  editable: false,
  confirmationScreen: false,
};

const removeLastPassFrame = () => {
  // https://support.lastpass.com/s/question/0D5TP00000AqMy90AF/i-have-a-html-div-created-by-lastpass-extensions-that-creates-an-iframe-that-blocks-my-page-to-the-user?language=en_US
  const lastpass = document.querySelector('[data-lastpass-root]');
  if (lastpass) {
    lastpass.remove();
  }
};

const Summary = ({ errors, editable, confirmationScreen }) => {
  const { getValues } = useFormContext();
  const isLargerThan768 = useWidthQuery(TABLET_WIDTH);
  const {
    billing: {
      addressOne: billingAddressOne,
      addressTwo: billingAddressTwo,
      city: billingCity,
      state: billingState,
      zipCode: billingZip,
    },
    ccNumber,
    driversLicense,
    email,
    firstName,
    insurance,
    lastName,
    lock,
    mailing,
    moveInDate,
    phone,
  } = getValues();

  const {
    data: {
      unit: {
        size,
        rates: { effectiveRate },
        unitType: { insurable },
      },
      facility: {
        address,
        displayName,
        insuranceOptions,
        lockOptions,
        phoneNumber,
        alias,
      },
    },
  } = useUnitData();
  const {
    data: { dueToday, charges, totalTaxes },
  } = useCostData();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentEditPage, updateEditPage] = useState();
  const providerId = alias.split(':')[2];
  const insuranceDesc = insuranceOptions.find((opt) => {
    const insuranceId = providerId === 'sl' ? parseInt(insurance, 10) : insurance;
    return opt.id === insuranceId;
  })?.description;
  const lockDesc = lockOptions.find((opt) => opt.id === lock)?.description;
  const date = new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  const openModal = (step) => {
    updateEditPage(step);
    onOpen();
  };

  // Last Pass Browser Creates Iframe and doesnt go away .. WA-322 issue
  useEffect(() => {
    removeLastPassFrame();
    document.addEventListener('scrollend', removeLastPassFrame);
    return () => {
      document.removeEventListener('scrollend', removeLastPassFrame);
    };
  }, []);

  const closeModal = () => {
    updateEditPage();
    onClose();
  };

  const details = (
    <SummaryRow padRow={confirmationScreen} isEditable={false}>
      <SummaryItem
        icon={<UnitIcon color="primary.accent" h="49px" w="49px" />}
        title="Unit Details"
        text={(
          <>
            <Text variant="lg" color="gray.lightText">
              {size}
            </Text>
            <Text
              variant="mdBold"
              sx={{ whiteSpace: 'pre', display: 'inline-block' }}
            >
              {`$${effectiveRate} `}
            </Text>
            <Text
              variant="sm"
              sx={{ whiteSpace: 'pre', display: 'inline-block' }}
            >
              Monthly Rent
            </Text>
          </>
        )}
      />
    </SummaryRow>
  );

  if (!charges) {
    return 'Loading...';
  }

  const parentComponentRef = useRef();

  return (
    <div ref={parentComponentRef}>
      {currentEditPage ? (
        <EditScreen
          onCloseRef={parentComponentRef}
          title={currentEditPage.editHeader}
          {...{ isOpen, closeModal, ...currentEditPage }}
        >
          <div className="OMI_CSS_CONTAINER">
            <currentEditPage.component />
          </div>
        </EditScreen>
      ) : null}
      {errors.length > 0 && (
        <>
          <VStack spacing={3} marginBottom={sizes._12px}>
            {errors.map((error) => (
              <Alert status="error" key={error}>
                <AlertIcon />
                {error}
              </Alert>
            ))}
          </VStack>
          <Box
            borderBottom="6px solid"
            borderColor="gray.lightBorder"
            marginBottom="8px"
          />
        </>
      )}
      <Flex direction="column" rowGap={sizes._18px}>
        {!isLargerThan768 && details}
        <SummaryRow
          padRow={confirmationScreen}
          isEditable={editable}
          editOnClick={() => openModal(baseSteps[0])}
          editLabel="Edit contact information"
        >
          <SummaryItem
            icon={<ContactIcon color="primary.accent" h="49px" w="49px" />}
            title="Contact Information"
            text={(
              <>
                <Text variant="lg" color="gray.lightText">
                  {`${firstName} ${lastName}`}
                </Text>
                <Text
                  variant="md"
                  color="gray.lightText"
                  lineHeight={sizes._24px}
                >
                  {email}
                </Text>
                <Text
                  variant="md"
                  color="gray.lightText"
                  lineHeight={sizes._24px}
                >
                  {phone}
                </Text>
              </>
            )}
          />
          <SummaryItem
            icon={<CalendarIcon color="primary.accent" h="49px" w="49px" />}
            title="Move-In Date"
            text={(
              <Text variant="lg" color="gray.lightText">
                {date.format(moveInDate)}
              </Text>
            )}
          />
        </SummaryRow>

        <SummaryRow
          padRow={confirmationScreen}
          isEditable={editable}
          editLabel="Edit insurance information"
          editOnClick={() => openModal(baseSteps[1])}
        >
          {insurable && (
            <SummaryItem
              icon={<InsuranceIcon color="primary.accent" h="49px" w="49px" />}
              title="Insurance Coverage"
              text={(
                <Text variant="lg" color="gray.lightText">
                  {insuranceDesc}
                </Text>
              )}
            />
          )}
          {lock && (
            <SummaryItem
              icon={<LockIcon color="primary.accent" h="49px" w="49px" />}
              title="Lock Option"
              text={(
                <Text variant="lg" color="gray.lightText">
                  {lockDesc}
                </Text>
              )}
            />
          )}
        </SummaryRow>
        <SummaryRow
          padRow={confirmationScreen}
          isEditable={editable}
          editLabel="Edit mailing information"
          editOnClick={() => openModal(baseSteps[1])}
        >
          <SummaryItem
            icon={<DriverIcon color="primary.accent" h="49px" w="49px" />}
            title="Drivers License Details"
            text={(
              <>
                <Text variant="lg" color="gray.lightText">
                  {driversLicense.number}
                </Text>
                <Text variant="lg" color="gray.lightText">
                  {`${driversLicense.state} ${driversLicense.expirationDate}`}
                </Text>
                <Text variant="lg" color="gray.lightText">
                  {`DOB ${driversLicense.birthDate}`}
                </Text>
              </>
            )}
          />
          <SummaryItem
            icon={<LocationIcon color="primary.accent" h="49px" w="49px" />}
            title="Mailing Address"
            text={(
              <>
                <Text variant="lg" color="gray.lightText">
                  {mailing.addressOne}
                </Text>
                <Text variant="lg" color="gray.lightText">
                  {mailing.addressTwo}
                </Text>
                <Text variant="lg" color="gray.lightText">
                  {`${mailing.city}, ${mailing.state} ${mailing.zipCode}`}
                </Text>
              </>
            )}
          />
        </SummaryRow>
        <SummaryRow
          padRow={confirmationScreen}
          isEditable={editable}
          editLabel="Edit billing information"
          editOnClick={() => openModal(baseSteps[3])}
        >
          <SummaryItem
            icon={<CreditCardIcon color="primary.accent" h="49px" w="49px" />}
            title="Credit Card"
            text={(
              <Text variant="lg" color="gray.lightText">
                {`${ccNumber
                  .substring(0, ccNumber.length - 4)
                  .replaceAll('-', ' ')
                  .replaceAll(/\d/g, '*')}${ccNumber.substring(
                  ccNumber.length - 4,
                  ccNumber.length,
                )}`}
              </Text>
            )}
          />
          <SummaryItem
            icon={<LocationIcon color="primary.accent" h="49px" w="49px" />}
            title="Billing Address"
            text={(
              <>
                <Text variant="lg" color="gray.lightText">
                  {billingAddressOne}
                </Text>
                <Text variant="lg" color="gray.lightText">
                  {billingAddressTwo}
                </Text>
                <Text variant="lg" color="gray.lightText">
                  {`${billingCity}, ${billingState} ${billingZip}`}
                </Text>
              </>
            )}
          />
        </SummaryRow>
        {isLargerThan768 && details}
        <SummaryRow padRow={confirmationScreen} isEditable={false}>
          <SummaryItem
            icon={<MoneyIcon color="primary.accent" h="49px" w="49px" />}
            title="Today's Total Charges"
            text={(
              <Grid templateColumns="1fr 1fr" rowGap={sizes._8px}>
                {charges.map((charge) => (
                  <Fragment key={charge.description}>
                    <GridItem>
                      <Text variant="sm">{charge.description}</Text>
                    </GridItem>
                    <GridItem>
                      <Text variant="smBold" align="right">
                        {`$${charge.amount}`}
                      </Text>
                    </GridItem>
                  </Fragment>
                ))}
                {totalTaxes && (
                  <>
                    <GridItem>
                      <Text variant="sm">Tax</Text>
                    </GridItem>
                    <GridItem>
                      <Text variant="smBold" align="right">
                        {`$${totalTaxes}`}
                      </Text>
                    </GridItem>
                  </>
                )}
                <GridItem
                  colSpan={2}
                  borderBottom="2px solid"
                  borderColor="gray.lightBorder"
                />
                <GridItem>
                  <Text variant="md">Your Payment Today</Text>
                </GridItem>
                <GridItem>
                  <Text variant="mdBold" align="right">
                    {`$${dueToday}`}
                  </Text>
                </GridItem>
              </Grid>
            )}
          />
        </SummaryRow>
      </Flex>
      <Box
        px={confirmationScreen ? sizes._16px : ''}
        py={confirmationScreen ? sizes._24px : ''}
      >
        <FacilityIcon color="primary.accent" w="138px" h="62px" marginTop={sizes._16px} />
        <Text variant="mdBold">Facility Details</Text>
        <Text variant="header">{displayName}</Text>
        <Text variant="md">{address.streetAddress1}</Text>
        {address.streetAddress2.length > 0 && (
          <Text variant="md">{address.streetAddress2}</Text>
        )}
        <Text variant="md">
          {`${address.city}, ${address.region} ${address.postalCode}`}
        </Text>
        <Text variant="md">{phoneNumber}</Text>
      </Box>
    </div>
  );
};

Summary.propTypes = propTypes;
Summary.defaultProps = defaultProps;

export default Summary;
