import React from 'react';
import PropTypes from 'prop-types';
import { GridItem, SimpleGrid } from '@chakra-ui/react';
import FormInput from '../FormInput';
import StateSelector from '../StateSelector';

const LienholderForm = ({ index }) => (
  <>
    <FormInput name={`lienholders.${index}.name`} label="Name" />
    <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={2}>
      <FormInput name={`lienholders.${index}.address1`} label="Address Line 1" />
      <FormInput name={`lienholders.${index}.address2`} label="Address Line 2" />
    </SimpleGrid>
    <SimpleGrid columns={{ sm: 2, md: 3 }} spacing={2}>
      <GridItem colSpan={{ sm: 2, md: 1 }}>
        <FormInput name={`lienholders.${index}.city`} label="City" />
      </GridItem>
      <StateSelector name={`lienholders.${index}.state`} />
      <FormInput name={`lienholders.${index}.zipCode`} label="ZIP code" />
    </SimpleGrid>
  </>
);

LienholderForm.propTypes = {
  index: PropTypes.number,
};

LienholderForm.defaultProps = {
  index: undefined,
};

export default LienholderForm;
