import {
  baseSteps,
  DECREMENT_STEP,
  INCREMENT_STEP,
  TOGGLE_T_AND_C_MODAL,
} from '../components/MoveIn/reducer';
import { EVENTS_NAME } from './events';
import { getUserAction } from './utils';

export const getOnStepChangePayload = (currentStep, nextStep) => {
  try {
    return {
      totalSteps: baseSteps.length,
      navigatingFrom: {
        step: currentStep.number,
        identifier: currentStep.identifier,
      },
      navigatingTo: {
        step: nextStep.number,
        identifier: nextStep.identifier,
      },
      pageView: nextStep.identifier,
      action: getUserAction(currentStep, nextStep),
    };
  } catch (error) {
    console.error('Error in getOnStepChangePayload:', error);
    return {
      totalSteps: 0,
      navigatingFrom: {
        step: 0,
        identifier: '',
      },
      navigatingTo: {
        step: 0,
        identifier: '',
      },
      pageView: '',
      action: '',
    };
  }
};

const onForwardMap = [
  ({ dispatch, getValues, setValue, triggerEvent }) => {
    triggerEvent(EVENTS_NAME.STEP_CHANGE, getOnStepChangePayload(baseSteps[0], baseSteps[1]));
    const { firstName, lastName } = getValues();
    setValue('mailing.firstName', firstName, { shouldDirty: true });
    setValue('mailing.lastName', lastName, { shouldDirty: true });
    dispatch({ type: INCREMENT_STEP });
  },
  ({ dispatch, triggerEvent }) => {
    triggerEvent(EVENTS_NAME.STEP_CHANGE, getOnStepChangePayload(baseSteps[1], baseSteps[2]));

    dispatch({ type: INCREMENT_STEP });
  },
  ({ dispatch, isPasswordSubmitted, onUpdateContact, triggerEvent }) => {
    if (isPasswordSubmitted) {
      dispatch({ type: INCREMENT_STEP });
      triggerEvent(EVENTS_NAME.STEP_CHANGE, getOnStepChangePayload(baseSteps[2], baseSteps[3]));
    } else {
      onUpdateContact({
        dispatch,
        triggerEvent,
        onStepChange: triggerEvent(
          EVENTS_NAME.STEP_CHANGE,
          getOnStepChangePayload(baseSteps[2], baseSteps[3]),
        ),
      });
    }
  },
  ({ dispatch, triggerEvent }) => {
    dispatch({ type: TOGGLE_T_AND_C_MODAL, show: true });
    triggerEvent(EVENTS_NAME.STEP_CHANGE, getOnStepChangePayload(baseSteps[3], { identifier: 'T&CModal' }));
  },
];

const addStepChange = () => {
  const steps = baseSteps.map((step, i) => {
    if (step.initial) {
      return { ...step, onForward: onForwardMap[i] };
    }

    const onBack = ({ dispatch, triggerEvent }) => {
      dispatch({ type: DECREMENT_STEP });

      triggerEvent(EVENTS_NAME.STEP_CHANGE, getOnStepChangePayload(step, steps[step.number - 2]));
    };

    return { ...step, onBack, onForward: onForwardMap[i] };
  });

  return steps;
};

export default addStepChange;
