/**
   * useEffect Hook - Handles the required field validations based on the selected insurance option.
   *
   * Logic:
   * - If `showInsuranceOptions` is "yes":
   *   - Makes the insurance field required.
   *   - Ensures the user selects an insurance plan.
   *   - Resets the insurance field if it's not selected or marked as "opt-out" or "private."
   *
   * - If `showInsuranceOptions` is "no" and provider is 'se' (specific provider logic):
   *   - Disables insurance requirement.
   *   - If private insurance is available, sets insurance to "private";
   * otherwise, sets it to "opt-out."
   *   - If insurance is still required, makes `confirmPrivateInsuranceRequired` a mandatory field.
   *
   * - If `showInsuranceOptions` is "no" and provider is 'sl':
   *   - Disables insurance requirement.
   *   - Resets the insurance field.
   *   - Adjusts the requirement for `confirmPrivateInsuranceRequired`
   *  based on whether insurance is required.
   *
   * Dependencies:
   * - Runs whenever `showInsuranceOptions` changes.
   */

import React, { useEffect } from 'react';
import {
  Box,
  VStack,
  Text,
  HStack,
  Link,
  Icon,
  Flex,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { sizes } from '../../theme';
import FormRadioCustom from '../FormRadioCustom';
import FormSelect from '../FormSelect';
import useUnitData from '../../hooks/UseUnitData';
import { ShieldCheckIcon } from '../Icons';
import Tooltip from '../Tooltip';
import { useConfig } from '../../contexts/ConfigProvider';
import { makeFieldsRequired } from '../MoveIn/reducer';
import FormCheckbox from '../FormCheckbox';
import { EVENTS_NAME } from '../../modules/events';

const InsuranceSelector = ({ step }) => {
  const { setValue, watch, getValues, resetField } = useFormContext();
  const selectedOption = watch('showInsuranceOptions', 'yes'); // Default to 'yes'
  const { insurance, showInsuranceOptions } = getValues();

  const {
    data: {
      facility: { insuranceOptions, displayName, insuranceSettings, alias,
        isInsuranceRequired, autoProtect, privateInsurance, brochureUrl },
      unit: { unitType: { insurable } },
    },
  } = useUnitData();
  const { configuration, triggerEvent } = useConfig();
  const providerId = alias.split(':')[2];
  const insuranceOptionLists = insuranceOptions && insuranceOptions.length > 0;

  const insuranceRequired = providerId === 'se' ? isInsuranceRequired && insurable && insuranceOptionLists : ((configuration?.insurance?.allowTenantsToBuyInsurance || insuranceSettings.required) && insurable) && insuranceOptionLists;

  useEffect(() => {
    if (showInsuranceOptions === 'yes') {
      makeFieldsRequired({ stepModifiers: { confirmPrivateInsuranceRequired: false }, step });
      makeFieldsRequired({ stepModifiers: { insuranceRequired: true }, step });
      if (!insurance || insurance === 'opt-out' || insurance === 'private') {
        resetField('insurance');
      }
    }

    if (showInsuranceOptions === 'no' && providerId === 'se') {
      makeFieldsRequired({ stepModifiers: { insuranceRequired: false }, step });

      if (privateInsurance) {
        setValue('insurance', 'private', { shouldDirty: true, shouldValidate: true });
      } else {
        setValue('insurance', 'opt-out', { shouldDirty: true, shouldValidate: true });
      }

      if (insuranceRequired) {
        makeFieldsRequired({ stepModifiers: { confirmPrivateInsuranceRequired: true }, step });
      } else {
        makeFieldsRequired({ stepModifiers: { confirmPrivateInsuranceRequired: false }, step });
      }
    } else if (showInsuranceOptions === 'no' && providerId === 'sl') {
      makeFieldsRequired({ stepModifiers: { insuranceRequired: false }, step });
      resetField('insurance');
      if (insuranceRequired) {
        makeFieldsRequired({ stepModifiers: { confirmPrivateInsuranceRequired: true }, step });
      } else {
        makeFieldsRequired({ stepModifiers: { confirmPrivateInsuranceRequired: false }, step });
      }
    }
  }, [showInsuranceOptions]);

  const handleBrochureClick = () => {
    triggerEvent(EVENTS_NAME.ON_CLICK, 'Download Policy Clicked');
  };

  const displayCheckBox = () => {
    if (!insuranceRequired) { return null; }

    if (showInsuranceOptions === 'yes') { return null; }
    return (
      <Box paddingLeft={sizes._32px} paddingRight={sizes._12px} paddingTop={sizes._12px}>
        {autoProtect && privateInsurance ? (
          <Flex align="flex-start" gap="8px">
            <Box>
              <FormCheckbox name="confirmPrivateInsurance" />
            </Box>
            <Box display="inline">
              <Text display={'inline'}>
              I agree to provide proof of insurance to the operator confirming coverage of my
              stored goods. If I do not provide proof of insurance within 14 days,
              I agree to be enrolled in an insurance plan made available to tenant for the minimum
              amount of coverage permitted by the operator.
              If I have authorized automatic payment of my rent,
              I also authorize automatic payment of the premium insurance plan.
              </Text>
              <Text display={'inline'} variant="mdBold" color={'red.500'} ml={sizes._4px}>*</Text>
            </Box>
          </Flex>
        ) : (
          <Flex align="flex-start" gap="8px">
            <Box>
              <FormCheckbox name="confirmPrivateInsurance" />
            </Box>
            <Box display={'inline'}>
              <Text display={'inline'}>
                Check here to confirm you understand that you will be required to share your
                proof of insurance or your protection plan with the storage facility.
              </Text>
              <Text display={'inline'} variant="mdBold" color={'red.500'} ml={sizes._4px}>*</Text>
            </Box>
          </Flex>
        )}
      </Box>
    );
  };

  return (
    <Box w="full" bg="white" paddingBottom={sizes._16px}>
      <Text variant="mdBold">
        {configuration.insurance.name
          ? `Pick Your ${configuration.insurance.name} Plan`
          : insuranceSettings.header}
        <Tooltip label={'Your property will not be insured or protected by the self-storage facility you are renting at. Most storage facilities require insurance or protection on stored property.'} />
      </Text>
      <Text variant="sm">
        {`${displayName} requires that your items to be covered by the ${configuration.insurance.name || insuranceSettings.name} plan`}
      </Text>
      <Box display="flex" flexDirection="row" paddingTop={sizes._12px}>
        <Text variant="mdBold" display="inline">
          Would you like to select a plan?<Text display={'inline'} sx={{ color: 'red', marginLeft: sizes._4px }}>*</Text>
        </Text>
      </Box>
      <Box gap={sizes._20px}>
        <VStack
          paddingTop={sizes._16px}
          paddingBottom={sizes._16px}
        >
          {selectedOption === 'yes' ? (
            <Box
              align="start"
              border="2px solid"
              borderColor="primary.accent"
              borderRadius={sizes._4px}
              padding={sizes._16px}
              w="full"
            >
              <FormRadioCustom
                name="showInsuranceOptions"
                options={[{ value: 'yes' }]} // Only Yes is visible here
                title="Yes"
              />
              <Box paddingTop={sizes._16px}>
                <FormSelect
                  name="insurance"
                  label={`Select ${configuration.insurance.name || insuranceSettings.name} plan`}
                  options={insuranceOptions}
                  required
                />
                <VStack align="start">
                  <HStack align="center">
                    <Icon as={ShieldCheckIcon} color="green.500" />
                    <Text variant="sm">Protection to keep your valuables safe.</Text>
                  </HStack>
                  <HStack align="center">
                    <Icon as={ShieldCheckIcon} color="green.500" />
                    <Text variant="sm">Knowing your stored items are covered.</Text>
                  </HStack>
                  <HStack align="center">
                    <Icon as={ShieldCheckIcon} color="green.500" />
                    <Text variant="sm">Affordable plans to match your needs.</Text>
                  </HStack>
                </VStack>
                {brochureUrl && (
                  <Box paddingTop={sizes._4px}>
                    <Text as="span" variant="smBold">
                      <Link
                        href={brochureUrl.startsWith('http') ? brochureUrl : `http://${brochureUrl}`}
                        isExternal
                        textDecoration="underline"
                        sx={{ color: 'black !important', textDecoration: 'underline !important' }}
                        onClick={handleBrochureClick} // Add onClick handler
                      >
                        Download Policy
                      </Link>
                    </Text>
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            <Box
              border="2px solid"
              borderColor={selectedOption === 'yes' ? 'primary.accent' : 'gray.200'}
              borderRadius={sizes._4px}
              padding={sizes._16px}
              w="full"
              cursor="pointer"
            >
              <FormRadioCustom
                name="showInsuranceOptions"
                options={[{ value: 'yes' }]}
                title="Yes"
              />
            </Box>
          )}
        </VStack>
      </Box>
      <Box
        border="2px solid"
        borderColor={selectedOption === 'no' ? 'primary.accent' : 'gray.200'}
        borderRadius="md"
        padding={sizes._16px}
        w="full"
        cursor="pointer"
      >
        <Box>
          <FormRadioCustom
            name="showInsuranceOptions"
            options={[{ value: 'no' }]}
            title={insuranceRequired ? 'No, I already have a plan' : "No, I don't want coverage"}
          />
          {displayCheckBox()}
        </Box>
      </Box>
    </Box>
  );
};

export default InsuranceSelector;
