import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import FormInput from '../FormInput';
import StateSelector from '../StateSelector';
import { sizes } from '../../theme';
import FormFileUpload from '../FormFileUpload';
import { useConfig } from '../../contexts/ConfigProvider';
import useUnitData from '../../hooks/UseUnitData';

const propTypes = {};
const defaultProps = {};

const DriversLicense = () => {
  const {
    data: { facility: { alias } = {} },
  } = useUnitData();
  const providerId = alias?.split(':')[2];
  const { configuration } = useConfig();
  const acceptedImageFormats = providerId === 'sl' ? ['image/jpeg', 'image/png', 'image/bmp'] : ['image/jpeg', 'image/png', 'image/tiff', 'application/pdf'];
  const acceptedImageFormatsDropzone = providerId === 'sl' ? {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
    'image/bmp': ['.bmp'],
  } : {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
    'image/tiff': ['.tiff', '.tif'],
    'application/pdf': ['.pdf'],
  };

  return (
    <>
      <Text variant="mdBold">Provider Driver&apos;s License Details</Text>
      <Text variant="sm" marginBottom={sizes._12px}>
        We collect driver&apos;s license information from all tenants to better
        serve you and heighten facility security.
      </Text>
      <FormInput name="driversLicense.number" label="Driver's License Number" />
      <Flex gap={sizes._8px}>
        <Box flex="1">
          <StateSelector name="driversLicense.state" />
        </Box>
        <Box flex="1">
          <FormInput
            label="Expiration Date"
            mask="00/00/0000"
            name="driversLicense.expirationDate"
            placeholder="mm/dd/yyyy"
            pattern="[0-9]*"
          />
        </Box>
      </Flex>
      <FormInput
        autoComplete="bday"
        label="Birth Date"
        mask="00/00/0000"
        name="driversLicense.birthDate"
        placeholder="mm/dd/yyyy"
        pattern="[0-9]*"
      />
      {configuration.providerConfig.dlImage && (
        <FormFileUpload
          name="dlImage"
          label="Driver's License Image"
          accept={acceptedImageFormats}
          acceptDropzone={acceptedImageFormatsDropzone}
          description="Please upload a photo of your driver's license for us to keep on file."
        />
      )}
    </>
  );
};

DriversLicense.propTypes = propTypes;
DriversLicense.defaultProps = defaultProps;

export default DriversLicense;
