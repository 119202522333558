/* eslint-disable no-underscore-dangle */
const _2px = `${2 / 16}rem`;
const _4px = `${4 / 16}rem`;
const _8px = `${8 / 16}rem`;
const _10px = `${10 / 16}rem`;
const _12px = `${12 / 16}rem`;
const _14px = `${14 / 16}rem`;
const _16px = `${16 / 16}rem`;
const _18px = `${18 / 16}rem`;
const _20px = `${20 / 16}rem`;
const _22px = `${22 / 16}rem`;
const _24px = `${24 / 16}rem`;
const _32px = `${32 / 16}rem`;
const _48px = `${48 / 16}rem`;

export const themeSizes = {
  _2px, _4px, _8px, _12px, _14px, _16px, _18px, _20px, _22px, _24px, _32px, _48px,
};
export const sizes = {
  _2px: `${_2px} !important`,
  _4px: `${_4px} !important`,
  _8px: `${_8px} !important`,
  _12px: `${_12px} !important`,
  _14px: `${_14px} !important`,
  _16px: `${_16px} !important`,
  _18px: `${_18px} !important`,
  _20px: `${_20px} !important`,
  _22px: `${_22px} !important`,
  _24px: `${_24px} !important`,
  _32px: `${_32px} !important`,
  _48px: `${_48px} !important`,
};

const text = {
  baseStyle: {
    fontFamily: 'Helvetica, Sans-Serif !important',
    fontSize: '16px !important',
  },
  variants: {
    xxsBold: {
      fontSize: `${_10px} !important`,
      fontWeight: '700 !important',
      lineHeight: `${_14px} !important`,
    },
    xs: {
      fontSize: `${_12px} !important`,
      lineHeight: `${_16px} !important`,
    },
    xsBold: {
      fontSize: `${_12px} !important`,
      fontWeight: '700 !important',
      lineHeight: `${_16px} !important`,
    },
    sm: {
      fontWeight: '400 !important',
      fontSize: `${_14px} !important`,
      lineHeight: `${_18px} !important`,
    },
    smBold: {
      fontSize: `${_14px} !important`,
      lineHeight: `${_18px} !important`,
      fontWeight: '700 !important',
    },
    smLight: {
      fontWeight: '300 !important',
      fontSize: `${_14px} !important`,
      lineHeight: `${_18px} !important`,
    },
    md: {
      fontSize: `${_16px} !important`,
      lineHeight: `${_20px} !important`,
    },
    mdBold: {
      fontWeight: '700 !important',
      fontSize: `${_16px} !important`,
      lineHeight: `${_20px} !important`,
    },
    mdLight: {
      fontWeight: '300 !important',
      fontSize: `${_16px} !important`,
      lineHeight: `${_20px} !important`,
    },
    lg: {
      fontSize: `${_20px} !important`,
      lineHeight: `${_24px} !important`,
    },
    lgBold: {
      fontWeight: '700 !important',
      fontSize: `${_20px} !important`,
      lineHeight: `${_24px} !important`,
    },
    lgLight: {
      fontWeight: '300 !important',
      fontSize: `${_20px} !important`,
      lineHeight: `${_24px} !important`,
    },
    xlBold: {
      fontWeight: '700 !important',
      fontSize: `${_32px} !important`,
      lineHeight: `${_32px} !important`,
    },
    // if this is the only 18px font, ask Rob to change it?
    header: {
      fontWeight: '700 !important',
      fontSize: `${_18px} !important`,
      lineHeight: `${_22px} !important`,
      paddingBottom: `${_12px} !important`,
    },
  },
};

export default text;
