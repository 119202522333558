import React, { useEffect, useReducer, useRef } from 'react';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import reducer, { getInitialState, SET_IS_SUBMITTING, TOGGLE_T_AND_C_MODAL, SET_IS_ESIGN_OPENED, SET_IS_ESIGN_COMPLETED, SET_IS_LOADING, baseSteps } from './reducer';
import ProgressBar from '../ProgressBar';
import UnitDetails from '../UnitDetails';
import Footer from '../Footer';
import LoadingScreen from '../LoadingScreen';
import InitialLoadingScreen from '../InitialLoadingScreen';
import useUnitData from '../../hooks/UseUnitData';
import useCostData from '../../hooks/UseCostData';
import { DESKTOP_WIDTH } from '../../theme/media';
import useWidthQuery, { useWidth } from '../../hooks/UseWidth';
import useSize from '../../hooks/UseSize';
import Confirmation from '../Confirmation';
import Esign from '../Esign';
import Complete from '../Complete';
import { sendLeaseConfirmationMail } from '../../modules/api';
import { getOnStepChangePayload } from '../../modules/onStepChange';
import applyReservation from '../../modules/applyReservation';
import { useConfig } from '../../contexts/ConfigProvider';
import { LOCAL_STORAGE_KEY } from '../../contexts/FormProvider/FormProvider';
import { EVENTS_NAME, EVENTS_NAME_VALUE } from '../../modules/events';

const propTypes = {};
const defaultProps = {};

const MoveIn = ({ onSubmit }) => {
  const { register, getValues } = useFormContext();
  const { password } = useWatch();
  const { data: {
    reservation,
    paymentSubmitted,
    leaseSigned,
    leaseUrl: leaseAgreementUrl,
    facility: { alias, insuranceOptions },
    gateAccessCode: gateCode,
    unit: { unitType: { insurable }, alias: unitAlias },
  } } = useUnitData();

  const { contact: { phoneNumber: contactPhoneNumber } } = reservation;
  const {
    data: { charges },
  } = useCostData();

  const { setUnitInfo, unitInfo } = useCostData();
  const { configuration, config, triggerEvent } = useConfig();
  const { params } = config;
  const sourceProviderId = alias.split(':')[2];
  const companyId = alias.split(':')[3].split(';')[0];
  const reservationAlias = reservation?.alias;
  const insuranceOptionLists = insuranceOptions && insuranceOptions.length > 0;

  const insuranceRequired = sourceProviderId === 'se' ? insurable && insuranceOptionLists : insurable && insuranceOptionLists;

  const [state, dispatch] = useReducer(
    reducer,
    {
      initialStep: reservation ? 1 : 0,
      stepModifiers: {
        insuranceRequired,
        dlImageEnabled: configuration.providerConfig.dlImage,
        showLock: configuration.providerConfig.showLock,
      },
    },
    getInitialState,
  );

  const {
    checkDirtyFields,
    isLoading,
    isPasswordSubmitted,
    isSubmitted,
    isSubmitting,
    isEsignOpened,
    isLeaseSigned,
    leaseUrl,
    rentalAlias,
    rentalId,
    gateAccessCode,
    step,
    steps,
    errors,
    showTAndCModal,
  } = state;

  register('insuranceRequired', { value: insuranceRequired });
  register('showLock', { value: configuration.providerConfig.showLock });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const payload = {
        totalSteps: state.steps.length,
        navigatingFrom: null,
        action: EVENTS_NAME_VALUE.LOAD,
      };

      if (leaseSigned || isLeaseSigned) {
        payload.action = 'Rental Completed';
        payload.navigatingTo = { identifier: 'leaseAlreadySigned' };
      } else if (paymentSubmitted) {
        payload.action = 'Payment Completed';
        payload.navigatingTo = { identifier: 'paymentConfirmation' };
      } else {
        payload.navigatingTo = {
          number: state.step.number,
          identifier: state.step.identifier,
        };
      }
      payload.pageView = payload?.navigatingTo?.identifier;
      triggerEvent(EVENTS_NAME.STEP_CHANGE, payload);
    }
  }, []);

  const { setValue } = useFormContext();
  const providerId = alias.split(':')[2];
  const { setWidth } = useWidth();
  const ref = useRef();
  const size = useSize(ref);
  useEffect(() => {
    setWidth(size?.width);
  }, [size?.width]);

  const isLargerThan1280 = useWidthQuery(DESKTOP_WIDTH);

  const scrollToTop = () => {
    ref.current.scrollIntoView();
  };

  useEffect(() => {
    if (reservation) {
      applyReservation({ reservation, setValue, setUnitInfo, unitInfo });
    }
  }, [reservation]);

  const onBack = step && step.initial
    ? null
    : () => {
      scrollToTop();
      step.onBack({ dispatch, triggerEvent });
    };

  const onForward = () => {
    scrollToTop();

    // Update localStorage with current form values
    const formData = getValues();
    // Remove sensitive fields from the formData
    const {
      password: accPassword,
      confirmPassword,
      ccCSC,
      ccNumber,
      ccExp,
      saveCreditCard,
      securityAnswerOne,
      securityAnswerTwo,
      securityQuestionOne,
      securityQuestionTwo,
      securityQuestionsRequired,
      moveInDate,
      ...filteredData
    } = formData;

    // Store the filtered data in localStorage
    localStorage.setItem(`${LOCAL_STORAGE_KEY}-${reservationAlias}`, JSON.stringify(filteredData));

    step.onForward({
      dispatch,
      getValues,
      isPasswordSubmitted,
      password,
      setValue,
      triggerEvent,
    });
  };

  const openEsignPage = () => dispatch({ type: SET_IS_ESIGN_OPENED, payload: true });

  const signLease = () => {
    dispatch({ type: SET_IS_LOADING, payload: { loading: true } });
    sendLeaseConfirmationMail({
      rentalAlias: rentalAlias || reservation?.rentalAlias,
      rentalId: rentalId || reservation?.rentalId,
      companyId,
      configName: params.config,
      source: params.source,
      unitAlias,
      contactPhoneNumber,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          dispatch({
            type: SET_IS_ESIGN_COMPLETED,
            payload: { success: false, errors: [res.error], loading: false },
          });
        } else {
          dispatch({
            type: SET_IS_ESIGN_COMPLETED,
            payload: {
              success: true,
              gateAccessCode: res.gateAccessCode,
              loading: false,
            },
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: SET_IS_ESIGN_COMPLETED,
          payload: { success: false, errors: [e.message], loading: false },
        });
      });
  };

  if (leaseSigned || isLeaseSigned) {
    return <Complete gateAccessCode={gateAccessCode || gateCode} />;
  }

  if (isEsignOpened) {
    return (
      <Esign
        leaseUrl={leaseUrl || leaseAgreementUrl}
        signLease={signLease}
        isLoading={isLoading}
      />
    );
  }

  if (isSubmitting) {
    return <LoadingScreen />;
  }

  if (isSubmitted) {
    return (
      <Confirmation
        leaseUrl={leaseUrl || leaseAgreementUrl}
        providerId={providerId}
        openEsignPage={openEsignPage}
      />
    );
  }

  if (paymentSubmitted && charges) {
    return (
      <Confirmation
        isPageReloaded
        leaseUrl={leaseAgreementUrl}
        providerId={providerId}
        openEsignPage={openEsignPage}
      />
    );
  }
  if (paymentSubmitted && !charges) {
    return <InitialLoadingScreen />;
  }

  const closeTAndCModal = () => {
    dispatch({ type: TOGGLE_T_AND_C_MODAL, show: false });
    triggerEvent(EVENTS_NAME.STEP_CHANGE, getOnStepChangePayload({ identifier: 'T&CModal' }, baseSteps[2]));
  };

  const onClickSubmit = () => {
    dispatch({ type: SET_IS_SUBMITTING, payload: true });
    onSubmit({
      dispatch,
      formValues: getValues(),
      onStepChange: triggerEvent(
        EVENTS_NAME.STEP_CHANGE,
        getOnStepChangePayload(baseSteps[2], { identifier: 'signLease' }),
      ),
    });
  };

  const stepProps = {
    errors,
    isLoading,
    isPasswordSubmitted,
    showTAndCModal,
    dispatch,
    closeTAndCModal,
    onSubmit: onClickSubmit,
    steps,
    step,
  };

  const footerProps = {
    checkDirtyFields,
    isLoading,
    onBack,
    onForward,
  };

  return (
    <form ref={ref}>{
      isLargerThan1280 ? (
        <Box minHeight="calc(100vh - 72px)">
          <ProgressBar steps={steps} step={step} />
          <Grid templateColumns="1fr 360px" gap={4}>
            <GridItem marginBottom="10px">
              <step.component {...stepProps} />
              <Footer {...step} {...footerProps} />
            </GridItem>
            <GridItem>
              <UnitDetails />
            </GridItem>
          </Grid>
        </Box>
      ) : (
        <>
          <Box minHeight="calc(100vh - 72px)">
            <ProgressBar steps={steps} step={step} />
            <UnitDetails step={step} />
            <step.component {...stepProps} />
          </Box>
          <Footer {...step} {...footerProps} />
        </>
      )
    }
    </form>
  );
};

MoveIn.propTypes = propTypes;
MoveIn.defaultProps = defaultProps;

export default MoveIn;
