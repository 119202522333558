/* eslint-disable max-len */
import React from 'react';
import { useConfig } from '../../contexts/ConfigProvider';

const CalendarIcon = (props) => {
  const { configuration } = useConfig();
  return (
    <svg width="49" height="49" viewBox="0 0 49 49" fill="none" {...props}>
      <rect width="49" height="49" fill="white" />
      <path
        d="M41.6499 43.5556H7.07769C5.55325 43.5556 4.35547 42.3578 4.35547 40.8334V11.7056C4.35547 10.1812 5.55325 8.9834 7.07769 8.9834H41.6499C43.1744 8.9834 44.3721 10.1812 44.3721 11.7056V40.8334C44.3721 42.3578 43.1744 43.5556 41.6499 43.5556Z"
        fill="#FDFCFB"
      />
      <path
        d="M44.3721 19.2734V40.8334C44.3721 42.3579 43.1199 43.6101 41.5954 43.6101H7.4043L44.3721 19.2734Z"
        fill={configuration?.themification?.primaryColor}
      />
      <path
        d="M44.3721 11.8143V18.402H4.35547V11.8143C4.35547 10.2898 5.60769 9.0376 7.13214 9.0376H41.6499C43.1199 9.0376 44.3721 10.2898 44.3721 11.8143Z"
        fill={configuration?.themification?.primaryColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M41.9224 43.2832H7.35015C5.82571 43.2832 4.62793 42.0854 4.62793 40.5609V11.4332C4.62793 9.90872 5.82571 8.71094 7.35015 8.71094H41.9224C43.4468 8.71094 44.6446 9.90872 44.6446 11.4332V40.5609C44.6446 42.0854 43.4468 43.2832 41.9224 43.2832Z"
        stroke="black"
        stroke-width="2.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M44.6446 18.0754H4.62793"
        stroke="black"
        stroke-width="2.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.4822 5.44434V11.4877"
        stroke="black"
        stroke-width="2.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M34.7898 5.44434V11.4877"
        stroke="black"
        stroke-width="2.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <g opacity="0.1">
        <path
          opacity="0.1"
          d="M11.7601 24.9356H10.1267C9.69117 24.9356 9.31006 24.5545 9.31006 24.1189V23.03C9.31006 22.5945 9.69117 22.2134 10.1267 22.2134H11.7601C12.1956 22.2134 12.5767 22.5945 12.5767 23.03V24.1189C12.5767 24.5545 12.1956 24.9356 11.7601 24.9356Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M18.3479 24.9356H16.7146C16.2791 24.9356 15.8979 24.5545 15.8979 24.1189V23.03C15.8979 22.5945 16.2791 22.2134 16.7146 22.2134H18.3479C18.7835 22.2134 19.1646 22.5945 19.1646 23.03V24.1189C19.1646 24.5545 18.7835 24.9356 18.3479 24.9356Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M24.9354 24.9356H23.302C22.8665 24.9356 22.4854 24.5545 22.4854 24.1189V23.03C22.4854 22.5945 22.8665 22.2134 23.302 22.2134H24.9354C25.3709 22.2134 25.752 22.5945 25.752 23.03V24.1189C25.752 24.5545 25.3709 24.9356 24.9354 24.9356Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M31.5232 24.9356H29.8899C29.4544 24.9356 29.0732 24.5545 29.0732 24.1189V23.03C29.0732 22.5945 29.4544 22.2134 29.8899 22.2134H31.5232C31.9588 22.2134 32.3399 22.5945 32.3399 23.03V24.1189C32.3399 24.5545 31.9588 24.9356 31.5232 24.9356Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M38.0569 24.9356H36.4236C35.988 24.9356 35.6069 24.5545 35.6069 24.1189V23.03C35.6069 22.5945 35.988 22.2134 36.4236 22.2134H38.0569C38.4925 22.2134 38.8736 22.5945 38.8736 23.03V24.1189C38.8736 24.5545 38.5469 24.9356 38.0569 24.9356Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M11.7601 32.0679H10.1267C9.69117 32.0679 9.31006 31.6868 9.31006 31.2513V30.1624C9.31006 29.7268 9.69117 29.3457 10.1267 29.3457H11.7601C12.1956 29.3457 12.5767 29.7268 12.5767 30.1624V31.2513C12.5767 31.6868 12.1956 32.0679 11.7601 32.0679Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M18.3479 32.0679H16.7146C16.2791 32.0679 15.8979 31.6868 15.8979 31.2513V30.1624C15.8979 29.7268 16.2791 29.3457 16.7146 29.3457H18.3479C18.7835 29.3457 19.1646 29.7268 19.1646 30.1624V31.2513C19.1646 31.6868 18.7835 32.0679 18.3479 32.0679Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M24.9354 32.0679H23.302C22.8665 32.0679 22.4854 31.6868 22.4854 31.2513V30.1624C22.4854 29.7268 22.8665 29.3457 23.302 29.3457H24.9354C25.3709 29.3457 25.752 29.7268 25.752 30.1624V31.2513C25.752 31.6868 25.3709 32.0679 24.9354 32.0679Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M31.5232 32.0679H29.8899C29.4544 32.0679 29.0732 31.6868 29.0732 31.2513V30.1624C29.0732 29.7268 29.4544 29.3457 29.8899 29.3457H31.5232C31.9588 29.3457 32.3399 29.7268 32.3399 30.1624V31.2513C32.3399 31.6868 31.9588 32.0679 31.5232 32.0679Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M38.0569 32.0679H36.4236C35.988 32.0679 35.6069 31.6868 35.6069 31.2513V30.1624C35.6069 29.7268 35.988 29.3457 36.4236 29.3457H38.0569C38.4925 29.3457 38.8736 29.7268 38.8736 30.1624V31.2513C38.8736 31.6868 38.5469 32.0679 38.0569 32.0679Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M11.7601 39.2002H10.1267C9.69117 39.2002 9.31006 38.8191 9.31006 38.3836V37.2947C9.31006 36.8591 9.69117 36.478 10.1267 36.478H11.7601C12.1956 36.478 12.5767 36.8591 12.5767 37.2947V38.3836C12.5767 38.8191 12.1956 39.2002 11.7601 39.2002Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M18.3479 39.2002H16.7146C16.2791 39.2002 15.8979 38.8191 15.8979 38.3836V37.2947C15.8979 36.8591 16.2791 36.478 16.7146 36.478H18.3479C18.7835 36.478 19.1646 36.8591 19.1646 37.2947V38.3836C19.1646 38.8191 18.7835 39.2002 18.3479 39.2002Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M24.9354 39.2002H23.302C22.8665 39.2002 22.4854 38.8191 22.4854 38.3836V37.2947C22.4854 36.8591 22.8665 36.478 23.302 36.478H24.9354C25.3709 36.478 25.752 36.8591 25.752 37.2947V38.3836C25.752 38.8191 25.3709 39.2002 24.9354 39.2002Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M31.5232 39.2002H29.8899C29.4544 39.2002 29.0732 38.8191 29.0732 38.3836V37.2947C29.0732 36.8591 29.4544 36.478 29.8899 36.478H31.5232C31.9588 36.478 32.3399 36.8591 32.3399 37.2947V38.3836C32.3399 38.8191 31.9588 39.2002 31.5232 39.2002Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M38.0569 39.2002H36.4236C35.988 39.2002 35.6069 38.8191 35.6069 38.3836V37.2947C35.6069 36.8591 35.988 36.478 36.4236 36.478H38.0569C38.4925 36.478 38.8736 36.8591 38.8736 37.2947V38.3836C38.8736 38.8191 38.5469 39.2002 38.0569 39.2002Z"
          fill="black"
        />
      </g>
      <path
        d="M8.32985 42.8476C8.05763 43.011 7.73096 42.7388 7.83985 42.4666L17.531 13.0121C18.1299 11.2155 19.9265 10.3444 21.6143 10.9432L43.7732 18.8921C44.2632 19.0554 44.3721 19.7088 43.9365 19.981L8.32985 42.8476Z"
        fill="white"
        stroke="black"
        stroke-width="2.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.8088 42.8477C14.591 43.011 14.3188 42.7388 14.3733 42.521L22.3221 18.4021C22.8121 16.9321 24.2821 16.2244 25.6433 16.7144L43.8277 23.1932C44.2088 23.3566 44.3177 23.8466 43.991 24.0644L14.8088 42.8477Z"
        fill="white"
        stroke="black"
        stroke-width="2.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default CalendarIcon;
