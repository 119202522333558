import React from 'react';
import { Box } from '@chakra-ui/react';
import FormSelect from '../FormSelect';
import useUnitData from '../../hooks/UseUnitData';
import { sizes } from '../../theme';

const propTypes = {};
const defaultProps = {};

const LockSelector = () => {
  const {
    data: {
      facility: { lockOptions },
      reservation: { formConfig: { showLock } },
    },
  } = useUnitData();

  if (!lockOptions || !lockOptions.length) {
    return null;
  }

  return (
    <>
      <FormSelect
        title="Select a Lock Option"
        tooltip="Some facilities require special locks on their units or offer the ability for you to purchase a lock with them."
        description="This facility offers locks to secure your unit."
        name="lock"
        label="Lock option"
        options={lockOptions}
        required={showLock}
      />
      <Box
        borderBottom="6px solid"
        borderColor="gray.lightBorder"
        marginBottom={sizes._16px}
        marginTop={sizes._16px}
      />
    </>
  );
};

LockSelector.propTypes = propTypes;
LockSelector.defaultProps = defaultProps;

export default LockSelector;
