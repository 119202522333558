import React from 'react';
import { createIcon } from '@chakra-ui/icons';

const CreditCard = createIcon({
  displayName: 'CreditCard',
  viewBox: '0 0 49 49',
  path: (
    <>
      <path d="M41.6968 36.75H6.68911C4.33998 36.75 2.44922 34.8471 2.44922 32.483V13.4545C2.44922 11.0904 4.33998 9.1875 6.68911 9.1875H41.6968C44.046 9.1875 45.9367 11.0904 45.9367 13.4545V32.483C45.9367 34.8471 44.046 36.75 41.6968 36.75Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.68973 9.92215C4.75094 9.92215 3.18484 11.4915 3.18484 13.4541V32.4827C3.18484 34.4453 4.75094 36.0146 6.68973 36.0146H41.6975C43.6362 36.0146 45.2023 34.4453 45.2023 32.4827V13.4541C45.2023 11.4915 43.6362 9.92215 41.6975 9.92215H6.68973ZM1.71484 13.4541C1.71484 10.6885 3.93027 8.45215 6.68973 8.45215H41.6975C44.4569 8.45215 46.6723 10.6885 46.6723 13.4541V32.4827C46.6723 35.2483 44.4569 37.4846 41.6975 37.4846H6.68973C3.93027 37.4846 1.71484 35.2483 1.71484 32.4827V13.4541Z" fill="black" />
      <path d="M45.9367 15.3125H2.44922V20.825H45.9367V15.3125Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1.71484 15.3121C1.71484 14.9062 2.04391 14.5771 2.44984 14.5771H45.9373C46.3433 14.5771 46.6723 14.9062 46.6723 15.3121V20.8246C46.6723 21.2306 46.3433 21.5596 45.9373 21.5596H2.44984C2.04391 21.5596 1.71484 21.2306 1.71484 20.8246V15.3121ZM3.18484 16.0471V20.0896H45.2023V16.0471H3.18484Z" fill="black" />
      <path d="M28.1758 25.7248C28.1758 25.3865 27.9016 25.1123 27.5633 25.1123H6.12578C5.78751 25.1123 5.51328 25.3865 5.51328 25.7248V28.7873C5.51328 29.1256 5.78751 29.3998 6.12578 29.3998H27.5633C27.9016 29.3998 28.1758 29.1256 28.1758 28.7873V25.7248Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M26.7058 26.5823V27.9298H6.98328V26.5823H26.7058ZM27.5633 25.1123C27.9016 25.1123 28.1758 25.3865 28.1758 25.7248V28.7873C28.1758 29.1256 27.9016 29.3998 27.5633 29.3998H6.12578C5.78751 29.3998 5.51328 29.1256 5.51328 28.7873V25.7248C5.51328 25.3865 5.78751 25.1123 6.12578 25.1123H27.5633Z" fill="black" />
      <path d="M33.3805 42.2619C36.9324 42.2619 39.8117 39.3826 39.8117 35.8307C39.8117 32.2788 36.9324 29.3994 33.3805 29.3994C29.8286 29.3994 26.9492 32.2788 26.9492 35.8307C26.9492 39.3826 29.8286 42.2619 33.3805 42.2619Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M26.2148 35.8313C26.2148 31.8735 29.4233 28.665 33.3811 28.665C37.3389 28.665 40.5473 31.8735 40.5473 35.8313C40.5473 39.7891 37.3389 42.9975 33.3811 42.9975C29.4233 42.9975 26.2148 39.7891 26.2148 35.8313ZM33.3811 30.135C30.2351 30.135 27.6848 32.6853 27.6848 35.8313C27.6848 38.9772 30.2351 41.5275 33.3811 41.5275C36.5271 41.5275 39.0773 38.9772 39.0773 35.8313C39.0773 32.6853 36.5271 30.135 33.3811 30.135Z" fill="black" />
      <path d="M29.0953 38.5871C32.6472 38.5871 35.5266 35.7077 35.5266 32.1559C35.5266 28.604 32.6472 25.7246 29.0953 25.7246C25.5434 25.7246 22.6641 28.604 22.6641 32.1559C22.6641 35.7077 25.5434 38.5871 29.0953 38.5871Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M21.9258 32.1555C21.9258 28.1977 25.1342 24.9893 29.092 24.9893C33.0498 24.9893 36.2583 28.1977 36.2583 32.1555C36.2583 36.1133 33.0498 39.3218 29.092 39.3218C25.1342 39.3218 21.9258 36.1133 21.9258 32.1555ZM29.092 26.4593C25.9461 26.4593 23.3958 29.0095 23.3958 32.1555C23.3958 35.3015 25.9461 37.8518 29.092 37.8518C32.238 37.8518 34.7883 35.3015 34.7883 32.1555C34.7883 29.0095 32.238 26.4593 29.092 26.4593Z" fill="black" />
    </>
  ),
});

export default CreditCard;
