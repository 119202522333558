import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Flex,
  GridItem,
  Text,
} from '@chakra-ui/react';
import { sizes } from '../../theme';
import { SuccessIcon } from '../Icons';
import Summary from '../Summary';

const propTypes = {
  hideSummary: PropTypes.bool,
  leaseUrl: PropTypes.string.isRequired,
  providerId: PropTypes.string.isRequired,
  openEsignPage: PropTypes.func.isRequired,
};

const defaultProps = {
  hideSummary: undefined,
};

const Confirmation = ({ hideSummary, leaseUrl, providerId, openEsignPage }) => {
  const onClick = () => {
    window.storableOmi?.onStepChange?.({
      navigatingTo: { identifier: 'openingLease' },
      navigatingFrom: { identifier: 'confirmation' },
    });
    if (providerId === 'sl') {
      window.open(leaseUrl);
    } else {
      openEsignPage();
    }
  };

  return (
    <>
      <Box>
        <Flex align="center">
          <SuccessIcon align="left" w="24px" h="24px" pr={sizes._4px} />
          <Text variant="smBold" color="green.successText">
            Payment Complete
          </Text>
        </Flex>
        <Text variant="xlBold" py={sizes._8px}>
          {"You're Almost Done!"}
        </Text>
        <Text variant="lg" py={sizes._8px}>
          Sign your lease to get access to your storage unit.
        </Text>
        <Button variant="secondaryAction" type="button" py={sizes._14px} px={sizes._24px} onClick={onClick} mb={sizes._16px}>
          Sign Lease & Save Time Later
        </Button>
      </Box>
      {!hideSummary && (
        <>
          <GridItem
            colSpan={{ sm: 6, md: 6 }}
            borderBottom="6px solid"
            borderColor="gray.lightBorder"
          />
          <Text variant="header" py={sizes._24px} px={sizes._16px}>Here are your storage rental details:</Text>
          <Box
            borderBottom="6px solid"
            borderColor="gray.lightBorder"
            marginBottom={sizes._24px}
          />
          <Summary confirmationScreen />
        </>
      )}
    </>
  );
};

Confirmation.propTypes = propTypes;
Confirmation.defaultProps = defaultProps;

export default Confirmation;
