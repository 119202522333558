import React, { useEffect } from 'react';
import { GridItem, SimpleGrid, Text } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { sizes } from '../../theme';
import FormInput from '../FormInput';
import FormCheckbox from '../FormCheckbox';
import StateSelector from '../StateSelector';

const BillingInformation = () => {
  const { setValue } = useFormContext();
  const { sameAsMailingAddress, ...formValues } = useWatch();

  useEffect(() => {
    if (!sameAsMailingAddress) { return; }
    const updateFields = [
      ['billing.firstName', formValues.mailing.firstName],
      ['billing.lastName', formValues.mailing.lastName],
      ['billing.addressOne', formValues.mailing.addressOne],
      ['billing.addressTwo', formValues.mailing.addressTwo],
      ['billing.city', formValues.mailing.city],
      ['billing.state', formValues.mailing.state],
      ['billing.zipCode', formValues.mailing.zipCode],
    ];
    updateFields.forEach(([fieldName, fieldValue]) => {
      setValue(fieldName, fieldValue || '', { shouldValidate: true, shouldDirty: true });
    });
  }, [sameAsMailingAddress]);

  return (
    <>
      <Text variant="mdBold" marginBottom={sizes._16px}>Billing Information</Text>
      <FormCheckbox
        name="sameAsMailingAddress"
        marginBottom={sizes._16px}
        label="Same as mailing address"
      />
      <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={2}>
        <FormInput
          name="billing.firstName"
          autoComplete="cc-given-name"
          label="First name"
          isDisabled={sameAsMailingAddress}
        />
        <FormInput
          name="billing.lastName"
          autoComplete="cc-family-name"
          label="Last name"
          isDisabled={sameAsMailingAddress}
        />
        <FormInput
          name="billing.addressOne"
          autoComplete="address-line1"
          label="Street address"
          isDisabled={sameAsMailingAddress}
        />
        <FormInput
          name="billing.addressTwo"
          autoComplete="address-line2"
          label="Unit/Apartment number"
          isDisabled={sameAsMailingAddress}
        />
      </SimpleGrid>
      <SimpleGrid columns={{ sm: 2, md: 3 }} spacing={2}>
        <GridItem colSpan={{ sm: 2, md: 1 }}>
          <FormInput
            name="billing.city"
            autoComplete="address-level2"
            label="City"
            isDisabled={sameAsMailingAddress}
          />
        </GridItem>
        <StateSelector
          name="billing.state"
          isDisabled={sameAsMailingAddress}
        />
        <FormInput
          name="billing.zipCode"
          autoComplete="postal-code"
          label="ZIP code"
          type="number"
          pattern="[0-9]*"
          isDisabled={sameAsMailingAddress}
        />
      </SimpleGrid>
    </>
  );
};

export default BillingInformation;
