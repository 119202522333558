import { Flex, Grid, IconButton } from '@chakra-ui/react';
import React from 'react';
import { PencilIcon } from '../Icons';

const EditButton = ({ label, onClick }) => (
  <IconButton
    aria-label={label}
    onClick={onClick}
    border="2px solid"
    borderColor="gray.lightBorder"
    borderRadius="100px"
    icon={<PencilIcon />}
    size="lg"
    variant="outline"
  />
);

const SummaryRow = ({ children, isEditable, editLabel = '', editOnClick = () => {}, hasBottomBorder = true, padRow }) => (
  <Flex
    direction="row"
    justifyContent="space-between"
    borderBottom={hasBottomBorder ? '6px solid' : ''}
    borderColor={hasBottomBorder ? 'gray.lightBorder' : ''}
    px={padRow ? '16px' : ''}
  >
    <Grid templateColumns="repeat(2, 1fr)" w="100%">
      {children}
    </Grid>
    {isEditable && <EditButton label={editLabel} onClick={editOnClick} />}
  </Flex>
);

export default SummaryRow;
