import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormErrorMessage,
  HStack,
  Box,
  Text,
  useRadioGroup,
} from '@chakra-ui/react';
import { useFormContext, useController } from 'react-hook-form';
import RadioCustom from '../RadioCustom';

const propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
};

const FormRadioCustom = ({ name, options, title }) => {
  const { formState: { errors }, control } = useFormContext();
  const { field } = useController({ name, control });

  const { getRootProps, getRadioProps } = useRadioGroup({
    ...field,
    inputRef: field.ref,
  });

  const group = getRootProps();

  return (
    <FormControl isInvalid={!errors[name]}>
      <HStack
        {...group}
        borderRadius="md"
      >
        {options.map(({ value }) => {
          const radio = getRadioProps({ value });
          const isSelected = field.value === value;
          return (
            <Box
              key={value}
              as="label"
              display="flex"
              alignItems="center"
              width="full"
            >
              <RadioCustom
                {...radio}
                customStyles={{
                  container: {
                    width: 'auto !important',
                  },

                }}
              />
              <Text
                fontWeight={isSelected ? 'bold' : 'normal'}
                fontSize="md"
                color="gray.800"
                marginLeft={0}
              >
                {title}
              </Text>
            </Box>
          );
        })}
      </HStack>
      {errors[name]?.message ? (
      <FormErrorMessage>
        <Text variant="sm" color="red.500">
          {errors[name]?.message}
        </Text>
      </FormErrorMessage>
      ) : null}
    </FormControl>

  );
};

FormRadioCustom.propTypes = propTypes;

export default FormRadioCustom;
