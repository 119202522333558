import React, { useState, createContext, useMemo } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node, PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export const SchemaContext = createContext();

const SchemaProvider = ({ children }) => {
  const [schema, setSchema] = useState({});
  const getIsRequired = (name) => {
    if (!schema) { return false; }
    const { fields } = schema.describe();
    const topLevel = fields[name.split('.')[0]];
    if (topLevel.type === 'array') {
      return !topLevel.optional;
    }

    return !get(fields, name.split('.').join('.fields.')).optional;
  };

  const value = useMemo(() => ({ schema, setSchema, getIsRequired }), [schema]);

  return (
    <SchemaContext.Provider value={value}>
      {children}
    </SchemaContext.Provider>
  );
};

SchemaProvider.propTypes = propTypes;

export default SchemaProvider;
