import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
// https://chakra-ui.com/getting-started#chakrabaseprovider
import { ChakraBaseProvider, extendTheme } from '@chakra-ui/react';
import { colors, global, sizes, themeSizes, text as Text, media } from '../theme';
import CSSReset from '../theme/CSSReset';

import '@fontsource/open-sans';
import '@fontsource/montserrat';
import '@fontsource/lato';
import '@fontsource/inter';
import '@fontsource/roboto';

import 'react-day-picker/dist/style.css';
import { useConfig } from './ConfigProvider';

function OMITheme(configuration) {
  const fontFamily = `${configuration?.themification?.fontFamily || 'Helvetica'}, San-Serif !important`;
  const labelTransform = 'translateY(-20px) translateX(-2px) !important';
  const footerStyles = {
    backgroundColor: 'white !important',
    borderColor: 'gray.lightBorder !important',
    bottom: 0,
    gap: `${themeSizes._16px} !important`,
    height: '72px !important',
    position: 'sticky !important',
    width: '100% !important',
    'z-index': '3 !important',
  };
  return extendTheme({
    breakpoints: {
      sm: '22.5em', // 360px
      md: media.TABLET_WIDTH,
      lg: media.DESKTOP_WIDTH,
    },
    colors,
    components: {
      Button: {
        baseStyle: {
          fontFamily,
        },
        variants: {
          action: {
            background: 'secondary.accent !important',
            color: 'white !important',
            height: '48px !important',
            width: '100% !important',
            borderRadius: `${configuration?.themification?.buttonBorderRadius || 6}px !important`,
            textTransform: `${configuration?.themification?.buttonTextStyle || 'capitalize'} !important`,
            _hover: {
              bg: 'secondary.accent !important',
            },
          },
          inaction: {
            bg: 'gray.lightBorder !important',
            height: '48px !important',
            width: '100% !important',
            borderRadius: `${configuration?.themification?.buttonBorderRadius || 6}px !important`,
            textTransform: `${configuration?.themification?.buttonTextStyle || 'capitalize'} !important`,
          },
          link: {
            color: 'gray.lightText !important',
            fontWeight: '400 !important',
            textDecoration: 'underline !important',
          },
          secondaryAction: {
            background: 'primary.accent !important',
            borderRadius: `${configuration?.themification?.buttonBorderRadius || 6}px !important`,
            textTransform: `${configuration?.themification?.buttonTextStyle || 'capitalize'} !important`,
            _hover: {
              bg: 'gray.hoverColor !important',
            },
          },
        },
      },
      Checkbox: {
        baseStyle: {
          control: {
            padding: '2.5px',
            border: '2px solid',
            borderColor: 'secondary.accent',
            borderRadius: '3px',
            _checked: {
              bg: 'white',
              borderColor: 'secondary.accent',
            },
            _invalid: {
              borderColor: 'red.accent',
            },
          },
          icon: {
            color: 'secondary.accent',
          },
          label: {
            fontFamily,
            color: 'gray.lightText',
          },
        },
      },
      Footer: {
        baseStyle: {
          ...footerStyles,
        },
        variants: {
          sm: {
            ...footerStyles,
            padding: `${themeSizes._12px} ${themeSizes._16px} !important`,
          },
          md: {
            ...footerStyles,
          },
        },
      },
      Form: {
        variants: {
          // float label inside input or select on focus/when not empty
          floating: {
            container: {
              position: 'relative',
              _focusWithin: {
                label: {
                  color: 'black',
                  transform: labelTransform,
                  cursor: 'default',
                  fontSize: sizes._14px,
                  '> p': {
                    fontSize: `${themeSizes._14px} !important`,
                  },
                },
              },
              'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label':
                {
                  transform: labelTransform,
                  color: 'black',
                  fontSize: sizes._14px,
                  '> p': {
                    fontSize: `${themeSizes._14px} !important`,
                  },
                },
              label: {
                color: 'gray.lightText',
                top: '2px',
                left: 0,
                zIndex: 2,
                position: 'absolute',
                backgroundColor: 'white',
                mx: 3,
                px: 1,
                my: 2,
                cursor: 'text',
                '> p': {
                  display: 'inline !important',
                },
              },
            },
          },
        },
      },
      Input: {
        defaultProps: {
          errorBorderColor: 'red.accent',
        },
        baseStyle: {
          field: {
            border: '2px solid gray.lightBorder',
            fontFamily,
          },
        },
      },
      Modal: {
        baseStyle: {
          header: {
            borderBottom: '4px solid !important',
            borderColor: 'gray.lightBorder !important',
            padding: `${themeSizes._12px} ${themeSizes._16px} ${themeSizes._14px} ${themeSizes._16px} !important`,
          },
          body: {
            padding: `${themeSizes._16px} !important`,
          },
          footer: {
            ...footerStyles,
          },
        },
      },
      RadioCustom: {
        baseStyle: {
          checkboxOutline: {
            border: '2px solid',
            borderColor: 'gray.unselectedRadio',
            borderRadius: '50%',
            position: 'relative',
            _checked: {
              borderColor: 'secondary.accent',
            },
            _focus: {
              border: '2px solid',
              borderColor: 'secondary.accent',
            },
            _hover: {
              borderColor: 'secondary.accent',
            },
          },
          onSelectedCenter: {
            _checked: {
              background: 'secondary.accent',
              position: 'absolute',
              borderRadius: '50%',
              top: '20%',
              bottom: '20%',
              left: '20%',
              right: '20%',
            },
          },
          label: {
            _checked: {
              fontWeight: 'bold',
            },
            '> p': {
              display: 'inline !important',
            },
          },
        },
        sizes: {
          md: {
            container: {
              width: '150px',
            },
            checkboxOutline: {
              width: '24px',
              height: '24px',
            },
          },
        },
        defaultProps: {
          size: 'md',
        },
      },
      Select: {
        baseStyle: {
          field: {
            fontFamily,
            border: '2px solid gray.lightBorder',
            borderRadius: '6px',
          },
        },
      },
      Text, // Edit in theme/text
      Tooltip: {
        baseStyle: {
          fontFamily,
          backgroundColor: 'white',
          color: 'black',
        },
      },
    },
    // insertion point for global css
    styles: {
      global,
    },
  });
}

const propTypes = {
  children: PropTypes.node.isRequired,
};

const ChakraProvider = ({ children }) => {
  const { configuration } = useConfig();
  const memoizedTheme = useMemo(() => {
    const updatedTheme = { ...OMITheme(configuration) };
    updatedTheme.colors.primary.accent = configuration.themification.primaryColor;
    updatedTheme.colors.secondary.accent = configuration.themification.secondaryColor;
    updatedTheme.colors.tertiary.accent = configuration.themification.tertiaryColor;
    updatedTheme.styles.global = { ...global,
      ':root': {
        '--chakra-colors-primary-accent': `${configuration.themification.primaryColor}`,
        '--chakra-colors-secondary-accent': `${configuration.themification.secondaryColor}`,
        '--chakra-colors-tertiary-accent': `${configuration.themification.tertiaryColor}`,
      } };
    updatedTheme.components.Text.baseStyle.fontFamily = `${configuration?.themification?.fontFamily || 'Helvetica'}, San-Serif !important`;
    return updatedTheme;
  }, [
    configuration.themification.primaryColor,
    configuration.themification.secondaryColor,
  ]);

  return (
    <ChakraBaseProvider theme={memoizedTheme} resetCSS disableGlobalStyle>
      <CSSReset scope=".OMI_CSS_CONTAINER" />
          {children}
    </ChakraBaseProvider>
  );
};

ChakraProvider.propTypes = propTypes;

export default ChakraProvider;

/**
 * Tater tots, broccoli, bbq chicken. chick nugs
 */
