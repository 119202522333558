/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@chakra-ui/react';
import { AddressIcon } from '../Icons';
import * as style from './Address.module.css';

const propTypes = {
  address: PropTypes.shape({
    streetAddress1: PropTypes.string.isRequired,
    streetAddress2: PropTypes.string,
    city: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: undefined,
};

const Address = ({ address, className }) => (
  <Text variant="sm" className={className}>
    <AddressIcon className={style.icon} />{address.streetAddress1}, {address.city}, {address.region}, {address.postalCode}
  </Text>
);

Address.propTypes = propTypes;
Address.defaultProps = defaultProps;

export default Address;
