import Step1 from '../Step1';
import Step2 from '../Step2';
import Step3 from '../Step3';
import Step4 from '../Step4';
import addStepChange from '../../modules/onStepChange';

export const INCREMENT_STEP = 'INCREMENT_STEP';
export const DECREMENT_STEP = 'DECREMENT_STEP';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const PASSWORD_SUBMITTED = 'PASSWORD_SUBMITTED';
export const SET_IS_SUBMITTING = 'SET_IS_SUBMITTING';
export const SET_IS_SUBMITTED = 'SET_IS_SUBMITTED';
export const SET_IS_ESIGN_OPENED = 'SET_IS_ESIGN_OPENED';
export const SET_IS_ESIGN_CLOSED = 'SET_IS_ESIGN_CLOSED';
export const SET_IS_ESIGN_COMPLETED = 'SET_IS_ESIGN_COMPLETED';
export const TOGGLE_T_AND_C_MODAL = 'TOGGLE_T_AND_C_MODAL';

export const baseSteps = [
  {
    number: 1,
    component: Step1,
    editHeader: 'Edit Personal Information',
    forwardText: 'Continue',
    header: 'Add Personal Information',
    identifier: 'personalInfo',
    initial: true,
    optionalFields: [],
    preheader: 'Rental Step 1',
    progressText: 'Reservation',
    requiredFields: ['firstName', 'lastName', 'email', 'phone', 'moveInDate'],
  },
  {
    number: 2,
    component: Step2,
    backText: 'Back',
    editHeader: 'Edit Additional Information',
    forwardText: 'Continue',
    header: 'Additional Information',
    identifier: 'additionalInfo',
    optionalFields: [],
    preheader: 'Rental Step 2',
    progressText: 'Details',
    requiredFields: [
      'driversLicense.number',
      'driversLicense.state',
      'driversLicense.expirationDate',
      'driversLicense.birthDate',
      'hasVehicle',
      'mailing.addressOne',
      'mailing.city',
      'mailing.state',
      'mailing.zipCode',
      'militaryStatus',
    ],
  },
  {
    number: 3,
    component: Step3,
    backText: 'Back',
    forwardText: 'Continue',
    header: 'Create Your Account',
    identifier: 'account',
    loadingText: 'Submitting...',
    optionalFields: [],
    preheader: 'Rental Step 3',
    progressText: 'Account',
    requiredFields: ['password', 'confirmPassword'],
  },
  {
    number: 4,
    component: Step4,
    backText: 'Back',
    forwardText: 'Complete Rental',
    header: 'Make Your Payment',
    editHeader: 'Payment Details',
    identifier: 'payment',
    optionalFields: [],
    preheader: 'Rental Step 4',
    progressText: 'Payment',
    requiredFields: [
      'ccNumber',
      'ccExp',
      'ccCSC',
      'billing.firstName',
      'billing.lastName',
      'billing.addressOne',
      'billing.city',
      'billing.state',
      'billing.zipCode',
    ],
  },
];

export const makeFieldsRequired = ({ stepModifiers = {}, step, formState }) => {
  if (step) {
    if ('insuranceRequired' in stepModifiers) {
      if (stepModifiers.insuranceRequired) {
        if (!step.requiredFields.includes('insurance')) {
          step.requiredFields.push('insurance');
        }
      } else {
        // eslint-disable-next-line no-param-reassign
        step.requiredFields = step.requiredFields.filter((field) => field !== 'insurance');
      }
    }

    if ('confirmPrivateInsuranceRequired' in stepModifiers) {
      if (stepModifiers.confirmPrivateInsuranceRequired) {
        if (!step.requiredFields.includes('confirmPrivateInsurance')) {
          step.requiredFields.push('confirmPrivateInsurance');
        }
      } else {
        // eslint-disable-next-line no-param-reassign
        step.requiredFields = step.requiredFields.filter((field) => field !== 'confirmPrivateInsurance');
      }
    }
  }

  // Add dynamic vehicle fields for Step 2 if hasVehicle is true
  if (step && step.number === 2 && stepModifiers.hasVehicle) {
    const vehicleArray = formState.vehicles || [];
    // Flatten required fields for all existing vehicles
    const newRequiredFields = vehicleArray.flatMap((_, index) => [
      `vehicles[${index}].description`,
      `vehicles[${index}].make`,
      `vehicles[${index}].model`,
      `vehicles[${index}].VIN`,
      `vehicles[${index}].state`,
      `vehicles[${index}].license`,
    ]);
    /* eslint-disable no-param-reassign */
    // Merge new fields into requiredFields
    step.requiredFields = [
      ...new Set([
        ...step.requiredFields.filter((field) => !field.startsWith('vehicles[')),
        ...newRequiredFields,
      ]),
    ];
  } else if (step && step.number === 2 && !stepModifiers.hasVehicle) {
    // Remove vehicle fields if hasVehicle is false
    step.requiredFields = step.requiredFields.filter(
      (field) => !field.startsWith('vehicles['), // Remove all vehicle-related fields
    );
  }
};
/* eslint-enable no-param-reassign */
export const getInitialState = ({ initialStep = 0, stepModifiers = {} }) => {
  const steps = addStepChange();
  if (stepModifiers.insuranceRequired) {
    steps[1].requiredFields.push('insurance');
  }

  if (stepModifiers.dlImageEnabled) {
    steps[1].optionalFields.push('dlImage');
  }

  if (stepModifiers.showLock) {
    steps[1].requiredFields.push('lock');
  }

  if (stepModifiers.showSecurityQuestion) {
    steps[2].requiredFields.push('securityQuestionOne', 'securityQuestionTwo', 'securityAnswerOne', 'securityAnswerTwo');
  }

  return {
    currentStep: initialStep,
    checkDirtyFields: true,
    errors: [],
    furthestStep: initialStep - 1,
    isLoading: false,
    isPasswordSubmitted: false,
    isSubmitting: false,
    isSubmitted: false,
    isEsignOpened: false,
    isLeaseSigned: false,
    showTAndCModal: false,
    step: steps[initialStep],
    steps,
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case INCREMENT_STEP: {
      const step = state.currentStep + 1;
      const furthestStep = state.furthestStep > state.currentStep
        ? state.furthestStep : state.currentStep;
      return {
        ...state,
        step: state.steps[step],
        currentStep: step,
        errors: [],
        furthestStep,
        checkDirtyFields: true,
      };
    }
    case DECREMENT_STEP: {
      const step = state.currentStep - 1;
      return {
        ...state,
        step: state.steps[step],
        currentStep: step,
        errors: [],
        checkDirtyFields: true,
      };
    }
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload.loading,
        errors: action.payload.errors || [],
      };
    case PASSWORD_SUBMITTED: {
      const step = state.currentStep + 1;
      return {
        ...state,
        isPasswordSubmitted: true,
        isLoading: false,
        step: state.steps[step],
        currentStep: step,
        errors: [],
        furthestStep: state.currentStep,
        checkDirtyFields: state.furthestStep < step,
      };
    }
    case SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.payload,
        errors: [],
      };
    case SET_IS_SUBMITTED:
      return {
        ...state,
        leaseUrl: action.payload.leaseUrl,
        rentalAlias: action.payload.rentalAlias,
        rentalId: action.payload.rentalId,
        gateAccessCode: action.payload.gateAccessCode,
        isSubmitting: false,
        isSubmitted: action.payload.success,
        errors: action.payload.errors || [],
        showTAndCModal: false,
      };
    case SET_IS_ESIGN_OPENED:
      return {
        ...state,
        isEsignOpened: action.payload,
        errors: [],
      };
    case SET_IS_ESIGN_CLOSED:
      return {
        ...state,
        isEsignOpened: false,
        errors: [],
      };
    case SET_IS_ESIGN_COMPLETED:
      return {
        ...state,
        isLoading: action.payload.loading,
        isEsignOpened: false,
        isLeaseSigned: true,
        gateAccessCode: action.payload.gateAccessCode,
        errors: [],
      };
    case TOGGLE_T_AND_C_MODAL:
      return {
        ...state,
        showTAndCModal: action.show,
      };
    default:
      return state;
  }
};

export default reducer;
