import React from 'react';
import { createIcon } from '@chakra-ui/icons';

const Contact = createIcon({
  displayName: 'Contact',
  viewBox: '0 0 70 66',
  path: (
    <>
      <path d="M8.5 11.5H62.5V44.5H8.5V11.5Z" fill="currentColor" />
      <path d="M21.6396 44.5C22.6227 37.6007 28.5306 32.4749 35.4996 32.4749C42.4687 32.4749 48.3765 37.6007 49.3596 44.5" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M35.4998 33.9749C29.2775 33.9749 24.0026 38.5515 23.1248 44.7116C23.008 45.5317 22.2484 46.1019 21.4282 45.985C20.6081 45.8681 20.038 45.1085 20.1548 44.2884C21.2433 36.6499 27.7842 30.9749 35.4998 30.9749C43.2155 30.9749 49.7564 36.6499 50.8448 44.2884C50.9617 45.1085 50.3916 45.8681 49.5714 45.985C48.7513 46.1019 47.9917 45.5317 47.8748 44.7116C46.9971 38.5515 41.7222 33.9749 35.4998 33.9749Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.5 6C0.5 5.17157 1.17157 4.5 2 4.5H68C68.8284 4.5 69.5 5.17157 69.5 6V50C69.5 50.8284 68.8284 51.5 68 51.5H2C1.17157 51.5 0.5 50.8284 0.5 50V6ZM3.5 7.5V48.5H66.5V7.5H3.5Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.5 12C6.5 11.1716 7.17157 10.5 8 10.5H62C62.8284 10.5 63.5 11.1716 63.5 12V45C63.5 45.8284 62.8284 46.5 62 46.5H8C7.17157 46.5 6.5 45.8284 6.5 45V12ZM9.5 13.5V43.5H60.5V13.5H9.5Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M21 60C21 59.1716 21.6716 58.5 22.5 58.5H48.5C49.3284 58.5 50 59.1716 50 60C50 60.8284 49.3284 61.5 48.5 61.5H22.5C21.6716 61.5 21 60.8284 21 60Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M26.5 51C26.5 50.1716 27.1716 49.5 28 49.5H43C43.8284 49.5 44.5 50.1716 44.5 51V60C44.5 60.8284 43.8284 61.5 43 61.5H28C27.1716 61.5 26.5 60.8284 26.5 60V51ZM29.5 52.5V58.5H41.5V52.5H29.5Z" fill="black" />
      <path d="M43.5 24.5C43.5 28.9183 39.9183 32.5 35.5 32.5C31.0817 32.5 27.5 28.9183 27.5 24.5C27.5 20.0817 31.0817 16.5 35.5 16.5C39.9183 16.5 43.5 20.0817 43.5 24.5Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M35.5 18C31.9101 18 29 20.9101 29 24.5C29 28.0899 31.9101 31 35.5 31C39.0899 31 42 28.0899 42 24.5C42 20.9101 39.0899 18 35.5 18ZM26 24.5C26 19.2533 30.2533 15 35.5 15C40.7467 15 45 19.2533 45 24.5C45 29.7467 40.7467 34 35.5 34C30.2533 34 26 29.7467 26 24.5Z" fill="black" />
    </>
  ),
});

export default Contact;
