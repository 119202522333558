import React, { useState, useRef, useEffect } from 'react';
import { Text, Box } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import ContactForm from '../ContactForm';
import FormMulti from '../FormMulti';
import { sizes } from '../../theme';

const propTypes = {};
const defaultProps = {};

const Contacts = () => {
  const { getValues } = useFormContext();
  const formRef = useRef(null);

  // Get contacts array from form data on page load
  const { contacts } = getValues() || {};

  // Function to check if any field inside contacts has a non-empty value
  const hasAnyValue = (contactsArray) => {
    if (!Array.isArray(contactsArray) || contactsArray.length === 0) {
      return false;
    }

    return contactsArray.some((contact) => Object.values(contact || {}).some((value) => value && value.trim() !== ''));
  };

  // Initialize form visibility based on existing data
  const [showForm, setShowForm] = useState(() => hasAnyValue(contacts));

  const handleToggleForm = () => {
    setShowForm((prev) => !prev);
    setTimeout(() => {
      if (formRef.current && !showForm) {
        formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);
  };

  const checkIfAnyFormHasData = () => {
    if (!formRef.current) { return false; }
    const inputs = formRef.current.querySelectorAll('input, select');
    return Array.from(inputs).some((input) => input.value.trim() !== '');
  };

  const handleClickOutside = (event) => {
    if (
      formRef.current
      && !formRef.current.contains(event.target)
      && !checkIfAnyFormHasData()
    ) {
      setShowForm(false);
    }
  };

  useEffect(() => {
    if (showForm) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showForm]);

  return (
    <div>
      <Box>
        <Text variant="mdBold" marginBottom={sizes._8px}>
          Additional Contact
        </Text>
        <Text variant="md" color="gray.500" marginBottom={sizes._16px}>
          An additional contact will be used for
          important updates or notifications regarding your storage unit.
        </Text>
        <Text
          as="a"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleToggleForm();
          }}
          variant="mdBold"
          sx={{
            textDecoration: 'underline !important',
            textDecorationThickness: '1px',
            cursor: 'pointer',
            color: 'gray.500 !important',
            _hover: { color: 'gray.600 !important' },
          }}
        >
          {showForm ? '' : 'Add additional contact'}
        </Text>
      </Box>

      {showForm && (
        <Box ref={formRef} marginTop={sizes._16px}>
          <FormMulti descriptor="contact" name="contacts" title="Additional Contact">
            <ContactForm />
          </FormMulti>
        </Box>
      )}
    </div>
  );
};

Contacts.propTypes = propTypes;
Contacts.defaultProps = defaultProps;

export default Contacts;
