import React, { useState, createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node, PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export const UnitDataContext = createContext();

const UnitDataProvider = ({ children }) => {
  const [data, setData] = useState({});

  const setDataWithConfig = (newData) => setData({
    ...newData,
    formConfig: newData?.reservation?.formConfig,
  });

  const value = useMemo(() => ({ data, setData: setDataWithConfig }), [data]);

  return (
    <UnitDataContext.Provider value={value}>
      {children}
    </UnitDataContext.Provider>
  );
};

UnitDataProvider.propTypes = propTypes;

export default UnitDataProvider;
