import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControl, FormErrorMessage, Text } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { sizes } from '../../theme';
import Tooltip from '../Tooltip';
import { EVENTS_NAME } from '../../modules/events';
import { useConfig } from '../../contexts/ConfigProvider';

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  isDisabled: PropTypes.bool,
};

const defaultProps = {
  label: undefined,
  required: undefined,
  tooltip: undefined,
  isDisabled: false,
};

const FormCheckbox = ({ name, required, label, tooltip, isDisabled, ...rest }) => {
  const { formState: { errors }, register, setValue } = useFormContext();

  const { triggerEvent } = useConfig();
  const handleChange = (e) => {
    const newValue = e.target.checked;
    setValue(name, newValue);
    triggerEvent(EVENTS_NAME.ON_CHECKBOX_SELECT, { name, value: newValue });
  };

  return (
    <FormControl isInvalid={errors[name]} isRequired={required}>
      <Checkbox
        isDisabled={isDisabled}
        {...rest}
        size="lg"
        {...register(name, {
          required: required && `${label} is required`,
          onChange: handleChange,
        })}
      >
        {label}
      </Checkbox>
      {tooltip ? (
        <Tooltip label={tooltip} />
      ) : null}
      <FormErrorMessage>
        <Text variant="sm" marginTop={sizes._4px} color="red.accent">
          {errors[name]?.message}
        </Text>
      </FormErrorMessage>
    </FormControl>
  );
};

FormCheckbox.propTypes = propTypes;
FormCheckbox.defaultProps = defaultProps;

export default FormCheckbox;
