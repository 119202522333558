import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Flex,
} from '@chakra-ui/react';
import { useFormContext, useController } from 'react-hook-form';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { sizes } from '../../theme';
import Tooltip from '../Tooltip';
import useSchema from '../../hooks/UseSchema';
import * as styles from './FormSelect.module.scss';
import { useConfig } from '../../contexts/ConfigProvider';
import { EVENTS_NAME } from '../../modules/events';
import { eventPayload } from '../../modules/utils';

const propTypes = {
  title: PropTypes.string,
  tooltip: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onBlur: PropTypes.func, // Accept onBlur as a prop
};

const defaultProps = {
  title: undefined,
  tooltip: undefined,
  description: undefined,
  label: undefined,
  onBlur: undefined, // Default onBlur to undefined
};

const FormSelect = ({
  title,
  tooltip,
  description,
  name,
  label,
  options,
  required,
  onBlur, // Destructure onBlur from props
  ...rest
}) => {
  const { formState: { errors }, register, getValues } = useFormContext();
  const { field } = useController({ name });
  const { getIsRequired } = useSchema();
  const isRequired = getIsRequired(name);

  const [lastValues, setLastValues] = useState({}); // Track last known values

  const { triggerEvent } = useConfig();

  // Initialize last values on form mount
  useEffect(() => {
    setLastValues(cloneDeep(getValues()));
  }, [getValues]);

  // Handle blur and update last values
  const handleBlur = (e) => {
    field.onBlur(e);
    const fieldName = e.target.name;
    // Check if the value has changed from the last known value
    triggerEvent(
      EVENTS_NAME.ON_FIELD_SELECT,
      eventPayload(fieldName, lastValues, field.value), // Use lastValues here
    );

    // Update lastValues state
    setLastValues((prev) => ({
      ...prev,
      [fieldName]: field.value,
    }));
  };
  return (
    <>
      {title ? (
        <Flex marginBottom={sizes._2px} alignItems="center">
          <Text as="span" variant="mdBold">{title}</Text>
          <Tooltip label={tooltip} />
        </Flex>
      ) : null }

      {description ? (
        <Text variant="sm" color="gray.lightText" marginBottom={sizes._12px}>
          {description}
        </Text>
      ) : null}

      <FormControl
        isInvalid={get(errors, name)}
        marginBottom={sizes._12px}
        variant="floating"
        isRequired={required || isRequired}
      >
        <Select
          {...rest}
          variant="outline"
          width={(field.name === 'lock' || field.name === 'insurance') ? { base: '100%', md: '70%' } : 'auto'} // Adjust width for mobile and full screen
          placeholder="‎"
          {...register(name)}
          onBlur={handleBlur} // Bind the passed onBlur handler
        >
          {options.map((option) => (
            <option
              className={styles.selectOption}
              key={option.id}
              value={option.id}
            >
              {option.description}
            </option>
          ))}
        </Select>

        <FormLabel>{label}</FormLabel>

        <FormErrorMessage>
          <Text variant="sm" marginTop={sizes._4px} color="red.accent">
            {get(errors, name)?.message}
          </Text>
        </FormErrorMessage>
      </FormControl>
    </>
  );
};

FormSelect.propTypes = propTypes;
FormSelect.defaultProps = defaultProps;

export default FormSelect;
