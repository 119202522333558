import React from 'react';
import ContactForm from '../ContactForm';
import FormMulti from '../FormMulti';

const propTypes = {};
const defaultProps = {};

const Contacts = () => (
  <FormMulti
    descriptor="contact"
    name="contacts"
    title="Additional Contact"
  >
    <ContactForm />
  </FormMulti>
);

Contacts.propTypes = propTypes;
Contacts.defaultProps = defaultProps;

export default Contacts;
