import React from 'react';
import PropTypes from 'prop-types';
import states from '../../modules/geography';
import FormSelect from '../FormSelect';

const propTypes = {
  name: PropTypes.string.isRequired,
};

const StateSelector = ({ name, ...rest }) => {
  const statesArr = Object.entries(states).map(([key, value]) => ({
    id: key,
    description: value,
  }));

  return (
    <FormSelect
      name={name}
      autoComplete="address-level1"
      label="State"
      options={statesArr}
      {...rest}
    />
  );
};

StateSelector.propTypes = propTypes;

export default StateSelector;
