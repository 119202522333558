import React from 'react';
import { Link, Box, Text } from '@chakra-ui/react';

import { useFormContext } from 'react-hook-form';
import FormSelect from '../FormSelect';
import { sizes } from '../../theme';
import useUnitData from '../../hooks/UseUnitData';
import { useConfig } from '../../contexts/ConfigProvider';

const propTypes = {};
const defaultProps = {};

const InsuranceSelector = () => {
  const { getValues } = useFormContext();

  const { insurance } = getValues();
  const {
    data: {
      facility: { insuranceOptions, insuranceSettings, alias, isInsuranceRequired },
    },
  } = useUnitData();

  const { configuration } = useConfig();
  const providerId = alias.split(':')[2];
  const insuranceRequired = providerId === 'se'
    ? isInsuranceRequired
    : insuranceSettings.required || configuration?.insurance?.allowTenantsToBuyInsurance;

  const selectedInsurance = insuranceOptions.find(
    (option) => option.id.toString() === insurance.toString(),
  );

  return (
    <>
      <FormSelect
        title={
          configuration.insurance.name
            ? `Select Your ${configuration.insurance.name}`
            : insuranceSettings.header
        }
        tooltip="Your property will not be insured by the self-storage facility you are renting at. Most storage facilities require insurance on stored property."
        description={configuration.insurance.description || insuranceSettings.description}
        name="insurance"
        label={configuration.insurance.name || insuranceSettings.name}
        options={insuranceOptions}
        required={insuranceRequired || false}
      />

      {selectedInsurance?.brochureUrl && (
          <Box mt={sizes._12px} mb={sizes._12px}>
            <Text as="span" variant={'smBold'}>
              Review Documents Here {' - '}
              <Link
                href={selectedInsurance.brochureUrl.startsWith('http') ? selectedInsurance.brochureUrl : `http://${selectedInsurance.brochureUrl}`}
                isExternal
                textDecoration="underline"
                sx={{ color: 'primary.accent !important' }}
              >
                Brochure Link
              </Link>
            </Text>
          </Box>
      )}
    </>
  );
};

InsuranceSelector.propTypes = propTypes;
InsuranceSelector.defaultProps = defaultProps;

export default InsuranceSelector;
