import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, HStack, useStyleConfig } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { DESKTOP_WIDTH } from '../../theme/media';
import useWidthQuery from '../../hooks/UseWidth';

const propTypes = {
  backText: PropTypes.string,
  checkDirtyFields: PropTypes.bool,
  forwardText: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadingText: PropTypes.string,
  onForward: PropTypes.func,
  onBack: PropTypes.func,
  optionalFields: PropTypes.arrayOf(PropTypes.string),
  requiredFields: PropTypes.arrayOf(PropTypes.string),
};
const defaultProps = {
  backText: undefined,
  checkDirtyFields: undefined,
  loadingText: undefined,
  onBack: undefined,
  onForward: undefined,
  optionalFields: [],
  requiredFields: [],
};

const Footer = ({
  backText,
  checkDirtyFields,
  forwardText,
  isLoading,
  loadingText,
  onForward,
  onBack,
  optionalFields,
  requiredFields,
}) => {
  const { getFieldState, formState } = useFormContext();
  const isLargerThan1280 = useWidthQuery(DESKTOP_WIDTH);
  const styles = useStyleConfig('Footer', { variant: isLargerThan1280 ? 'md' : 'sm' });

  const isDisabled = requiredFields.some((field) => {
    const { invalid, isDirty } = getFieldState(field, formState);
    if (invalid || (checkDirtyFields && !isDirty)) {
      return true;
    }
    return false;
  });

  const hasInvalidFields = optionalFields.some((field) => {
    const { invalid } = getFieldState(field, formState);
    return invalid;
  });

  return (
    <HStack __css={styles}>
      {backText && (
        <Box flex="1">
          <Button isDisabled={isLoading} onClick={onBack} variant="inaction">
            {backText}
          </Button>
        </Box>
      )}
      {forwardText && (
        <Box flex="1">
          <Button
            isDisabled={isDisabled || hasInvalidFields}
            onClick={onForward}
            isLoading={isLoading}
            loadingText={loadingText}
            type="button"
            variant="action"
          >
            {forwardText}
          </Button>
        </Box>
      )}
    </HStack>
  );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
