import React from 'react';
import FormSelect from '../FormSelect';
import useUnitData from '../../hooks/UseUnitData';

const propTypes = {};
const defaultProps = {};

const InsuranceSelector = () => {
  const { data: { facility: { insuranceOptions, insuranceSettings } } } = useUnitData();

  return (
    <FormSelect
      title={insuranceSettings.header}
      tooltip="Your property will not be insured by the self-storage facility you are renting at. Most storage facilities require insurance on stored property."
      description={insuranceSettings.description}
      name="insurance"
      label={insuranceSettings.name}
      options={insuranceOptions}
      required={insuranceSettings.required}
    />
  );
};

InsuranceSelector.propTypes = propTypes;
InsuranceSelector.defaultProps = defaultProps;

export default InsuranceSelector;
