import React from 'react';
import PropTypes from 'prop-types';
import {
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Input,
  InputGroup,
  InputLeftElement,
  useDisclosure,
} from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import { CalendarIcon } from '@chakra-ui/icons';
import useUnitData from '../../hooks/UseUnitData';
import useCostData from '../../hooks/UseCostData';
import { useConfig } from '../../contexts/ConfigProvider';
import { EVENTS_NAME } from '../../modules/events';

const propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  dateDisabled: PropTypes.bool,
};

const defaultProps = {
  dateDisabled: false,
};

const FormDatePicker = ({ name, placeholder, dateDisabled }) => {
  const { data: { maxDate, disabledDaysOfWeek } } = useUnitData();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { control, getValues } = useFormContext();
  const { unitInfo, setUnitInfo } = useCostData();
  const { triggerEvent } = useConfig();
  const dateValue = getValues(name) && format(getValues(name), 'MM/dd/yyyy');

  const disabledDates = [
    { before: new Date() },
    { after: maxDate },
  ];

  if (disabledDaysOfWeek?.length) {
    disabledDates.push(...disabledDaysOfWeek.map((dayOfWeek) => ({ dayOfWeek })));
  }

  const onSelect = ({ onChange, onBlur }) => (date) => {
    onChange(date);
    onBlur();
    setUnitInfo({ ...unitInfo, moveInDate: date });
    onClose();
  };

  const handleBlur = () => {
    triggerEvent(EVENTS_NAME.ON_FIELD_SELECT, { name, action: 'Move-in Date Changed' });
  };

  return (
    <>
      <InputGroup>
        <InputLeftElement
          children={<CalendarIcon />}
          pointerEvents="none"
        />
        <Input
          placeholder={placeholder}
          onClick={onOpen}
          value={dateValue}
          onChange={onOpen}
          onBlur={handleBlur}
          disabled={dateDisabled}
        />
      </InputGroup>
      <Drawer isOpen={isOpen} size="sm" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <Center>
              <Controller
                control={control}
                name={name}
                render={({ field: { onChange, value, onBlur } }) => (
                  <DayPicker
                    mode="single"
                    selected={value}
                    onSelect={onSelect({ onChange, onBlur })}
                    disabled={disabledDates}
                  />
                )}
              />
            </Center>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

FormDatePicker.propTypes = propTypes;
FormDatePicker.defaultProps = defaultProps;

export default FormDatePicker;
