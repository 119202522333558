import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip as TooltipChakra } from '@chakra-ui/react';
import { TooltipIcon } from '../Icons';

const propTypes = {
  label: PropTypes.string.isRequired,
};

// https://github.com/chakra-ui/chakra-ui/issues/2691

const Tooltip = ({ label }) => {
  const [isLabelOpen, updateIsLabelOpen] = useState(false);

  return (
    <TooltipChakra
      placement="top-start"
      isOpen={isLabelOpen}
      label={label}
    >
      <TooltipIcon
        marginLeft="5px"
        fill="gray.lightText"
        opacity={0.5}
        onMouseEnter={() => updateIsLabelOpen(true)}
        onMouseLeave={() => updateIsLabelOpen(false)}
        onClick={() => updateIsLabelOpen(true)}
      />
    </TooltipChakra>
  );
};

Tooltip.propTypes = propTypes;

export default Tooltip;
