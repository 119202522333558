import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { sizes } from './theme';

const FallBackComponent = () => (
  <Box py={sizes._12px} px={sizes._12px}>
    <Text variant="mdBold">
      There was an issue loading the online move-in application and it has been
      informed to the team. Please check your email for instructions to move-in
      online or contact the facility for help. We apologize for the
      inconvenience.
    </Text>
  </Box>
);

export default FallBackComponent;
