import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { Box, Button, Text } from '@chakra-ui/react';
import { sizes } from '../../theme';
import useSchema from '../../hooks/UseSchema';

const propTypes = {
  children: PropTypes.node.isRequired,
  descriptor: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const defaultProps = {};

const FormMulti = ({ children, descriptor, name, title }) => {
  const { schema } = useSchema();
  const max = schema.describe().fields[name].tests.find((test) => test.name === 'max')?.params.max;
  if (max < 1) { return null; }

  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name });

  const addButton = (
    <Button variant="link" onClick={() => append()}>
      <Text variant="md" color="gray.lightText" sx={{ textDecoration: 'underline' }}>
        {`Add another ${descriptor}`}
      </Text>
    </Button>
  );

  return (
    <>
      {fields.map((field, index) => (
        <React.Fragment key={field.id}>
          <Text variant="mdBold" marginBottom={sizes._8px}>
            {`${title} ${fields.length > 1 ? index + 1 : ''}`}
          </Text>
          {React.cloneElement(children, { index })}
          {fields.length > 1 ? (
            <Box marginBottom={sizes._8px}>
              <Button variant="link" onClick={() => remove(index)}>
                <Text variant="md" color="gray.lightText" sx={{ textDecoration: 'underline' }}>
                  {`Remove ${descriptor}`}
                </Text>
              </Button>
            </Box>
          ) : null}
          {fields.length !== (index + 1) ? (
            <Box borderBottom="3px solid" borderColor="gray.lightBorder" marginBottom={sizes._12px} />
          ) : null}
        </React.Fragment>
      ))}
      {max ? (fields.length < max && addButton) : addButton}
    </>
  );
};

FormMulti.propTypes = propTypes;
FormMulti.defaultProps = defaultProps;

export default FormMulti;
