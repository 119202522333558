import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, HStack, Text, useRadio, useMultiStyleConfig } from '@chakra-ui/react';

const propTypes = {
  description: PropTypes.string.isRequired,
};

const RadioCustom = ({ description, ...props }) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const styles = useMultiStyleConfig('RadioCustom');

  const input = getInputProps();
  const radio = getCheckboxProps();

  return (
    <Box
      as="label"
      __css={styles.container}
    >
      <input {...input} />
      <Box
        {...radio}
        cursor="pointer"
        __css={styles.lukeTest}
      >
        <HStack>
          <Flex
            {...radio}
            __css={styles.checkboxOutline}
            _checked={styles.onSelectedOutline}
          >
            <Box
              {...radio}
              as="span"
              __css={styles.onSelectedCenter}
            />
          </Flex>
          <Text
            {...radio}
            as="span"
            __css={styles.label}
          >
            {description}
          </Text>
        </HStack>
      </Box>
    </Box>
  );
};

RadioCustom.propTypes = propTypes;

export default RadioCustom;
