import { Box, Text, Flex } from '@chakra-ui/react';
import React from 'react';
import { AmexIcon, DiscoverIcon, MastercardIcon, VisaIcon } from '../Icons';
import { sizes } from '../../theme';
import useUnitData from '../../hooks/UseUnitData';

const Card = ({ name }) => {
  const cardOptions = { boxSize: 10 };
  const cardParser = {
    amex: <AmexIcon {...cardOptions} />,
    discover: <DiscoverIcon {...cardOptions} />,
    mastercard: <MastercardIcon {...cardOptions} />,
    visa: <VisaIcon {...cardOptions} />,
  };

  return cardParser[name];
};

const CardsAccepted = () => {
  const { data: { facility: { cardsAccepted } } } = useUnitData();
  return (
    <Box borderBottom="4px solid" borderColor="gray.lightBorder" paddingBottom={sizes._14px} marginBottom={sizes._16px}>
      <Text variant="mdBold" marginBottom={sizes._8px}>Cards Accepted</Text>
      <Flex align="center" gap="10px">
        {cardsAccepted.map((card) => <Card key={`cc-icon-${card}`} name={card} />)}
      </Flex>
    </Box>
  );
};
export default CardsAccepted;
