import React from 'react';
import CardsAccepted from '../CardsAccepted';
import CardInformation from '../CardInformation';
import BillingInformation from '../BillingInformation';

const propTypes = {};
const defaultProps = {};

const Step4 = () => (
  <>
    <CardsAccepted />
    <CardInformation />
    <BillingInformation />
  </>
);

Step4.propTypes = propTypes;
Step4.defaultProps = defaultProps;

export default Step4;
