import React, { useEffect } from 'react';
import { useForm, FormProvider as HookFormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import defaultValues from '../../modules/defaultFormValues';
import useSchema from '../../hooks/UseSchema';
import useUnitData from '../../hooks/UseUnitData';
import getSchema from './schema';
import { useConfig } from '../ConfigProvider';

const FormProvider = ({ children, initialValues = defaultValues }) => {
  const {
    data: { facility: { cardsAccepted, insuranceSettings, alias } = {}, formConfig },
  } = useUnitData();
  const providerId = alias?.split(':')[2];
  const { configuration } = useConfig();
  const { setSchema } = useSchema();

  const schema = getSchema({
    cardsAccepted,
    insuranceName: configuration.insurance.name || insuranceSettings?.name,
    providerId,
    formConfig: {
      // ...formConfig,
      vehicleCount: configuration.providerConfig.vehicleCount,
      contactCount: configuration.providerConfig.contactCount,
      lienholderCount: configuration.providerConfig.lienholderCount,
    },
  });

  useEffect(
    () => setSchema(schema),
    [cardsAccepted, insuranceSettings, providerId, formConfig, configuration],
  );

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: {
      ...defaultValues,
      ...initialValues,
    },
    resolver: yupResolver(
      getSchema({
        cardsAccepted,
        insuranceName: configuration.insurance.name || insuranceSettings?.name,
        providerId,
        formConfig: {
          // ...formConfig,
          vehicleCount: configuration.providerConfig.vehicleCount,
          contactCount: configuration.providerConfig.contactCount,
          lienholderCount: configuration.providerConfig.lienholderCount,
        },
      }),
    ),
  });

  return <HookFormProvider {...methods}>{children}</HookFormProvider>;
};

export default FormProvider;
