const applyReservation = ({ reservation, setValue, setUnitInfo, unitInfo }) => {
  const { contact, desiredMoveInDate } = reservation;

  const extractedPhone = contact.phoneNumber.replace(/\D/g, '');
  const lastTenDigits = extractedPhone.slice(-10);
  const phone = `(${lastTenDigits.slice(0, 3)}) ${lastTenDigits.slice(3, 6)}-${lastTenDigits.slice(6, 10)}`;
  setValue('firstName', contact.firstName, { shouldValidate: true, shouldDirty: true });
  setValue('lastName', contact.lastName, { shouldValidate: true, shouldDirty: true });
  setValue('email', contact.emailAddress, { shouldValidate: true, shouldDirty: true });
  setValue('phone', phone, { shouldValidate: true, shouldDirty: true });
  setValue('mailing.firstName', contact.firstName, { shouldValidate: true, shouldDirty: true });
  setValue('mailing.lastName', contact.lastName, { shouldValidate: true, shouldDirty: true });

  setValue('moveInDate', new Date(desiredMoveInDate), { shouldValidate: true, shouldDirty: true });
  setUnitInfo({ ...unitInfo, moveInDate: new Date(desiredMoveInDate) });
};

export default applyReservation;
