import colors from './colors';

const global = {
  body: {
    bg: 'white',
  },
  form: {
    bg: 'white',
  },
  '.SingleDatePicker_picker': {
    zIndex: 10,
  },
  ':root': {
    // Define CSS variables here
    '--chakra-colors-primary-accent': `${colors.primary.accent}`,
    '--chakra-colors-secondary-accent': `${colors.secondary.accent}`,
    '--chakra-colors-tertiary-accent': `${colors.tertiary.accent}`,
  },
};

export default global;
