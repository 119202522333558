import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Spinner,
  Text,
} from '@chakra-ui/react';
import Address from '../Address';
import * as style from './UnitDetails.module.scss';
import TodaysCharges from '../TodaysCharges';
import { sizes, themeSizes } from '../../theme';
import useUnitData from '../../hooks/UseUnitData';
import useCostData from '../../hooks/UseCostData';
import { DESKTOP_WIDTH, MOBILE_WIDTH } from '../../theme/media';
import useWidthQuery from '../../hooks/UseWidth';
import { useConfig } from '../../contexts/ConfigProvider';
import { EVENTS_NAME, EVENTS_NAME_VALUE } from '../../modules/events';

const spacing = {
  whiteSpace: 'pre',
  display: 'inline-block',
};

const defaultProps = {};

const UnitDetails = ({ step }) => { // Add step as a prop to control which step is active
  const { triggerEvent } = useConfig();

  const getViewDetails = (expand) => {
    if (expand) {
      return EVENTS_NAME_VALUE.UNIT_VIEW_DETAILS_CLICK[0];
    }
    return EVENTS_NAME_VALUE.UNIT_VIEW_DETAILS_CLICK[1];
  };

  const {
    data: {
      unit: {
        amenities = [],
        size,
        inventory: { available, availableInTier },
        rates: { baseRate, effectiveRate },
      },
      facility: { address, displayName },
    },
  } = useUnitData();
  const { configuration: {
    providerConfig: { unitsAvailabilityCountDisplayThreshold },
  } } = useConfig();
  const availableCount = availableInTier || available;
  const { isLoading } = useCostData();
  const isLargerThan1280 = useWidthQuery(DESKTOP_WIDTH);
  const isLargerThan480 = useWidthQuery(MOBILE_WIDTH);
  const handleViewDetailsClick = (expand) => {
    triggerEvent(EVENTS_NAME.ON_CLICK, { action: getViewDetails(expand) });
  };
  const unitCountThreshold = unitsAvailabilityCountDisplayThreshold !== undefined
    && availableCount > 0
    && availableCount <= Number(unitsAvailabilityCountDisplayThreshold);

  const expandedDetails = (
    <>
      <Box
        sx={{
          border: '2px solid',
          borderColor: 'gray.lightBorder !important',
          borderRadius: 'md !important',
          padding: `${sizes._12px}`,
          marginBottom: `${sizes._12px}`,
        }}
      >
        <Text
          variant="smBold"
          color="gray.lightText"
          className={style.marginBottom}
        >
          UNIT DETAILS
        </Text>
        <Address address={address} className={style.marginBottom} />
        <Text variant="mdBold" className={style.marginBottom}>
          {size} Unit
        </Text>
        <Text variant="smLight" className={style.marginBottom}>
          {amenities.map(({ name }) => name).join(', ')}
        </Text>
        {
          unitCountThreshold && (
            <Text sx={{ ...spacing }} variant="lgLight" color="tertiary.accent">
              {`${availableCount} left at `}
            </Text>
          )
        }
        <Text sx={{ ...spacing }} variant="lgBold" color="tertiary.accent">
          {`$${parseFloat(effectiveRate) === 0 ? baseRate : effectiveRate} `}
        </Text>
        <Text sx={{ ...spacing }} variant="mdLight">
          {'per month '}
        </Text>
        {parseFloat(baseRate) > parseFloat(effectiveRate)
          && parseFloat(effectiveRate) !== 0 && (
            <Text
              sx={{
                ...spacing,
                textDecoration: 'line-through',
                display: 'block',
              }}
              variant="smLight"
              color="gray.lightText"
            >
              {`$${baseRate} `}
            </Text>
        )}
      </Box>
      {isLoading ? (
        <Box bgColor="gray.lightBorder" opacity="0.5" position="relative">
          <Center position="absolute" w="100%" h="100%">
            <Spinner size="xl" />
          </Center>
          <TodaysCharges />
        </Box>
      ) : (
        <TodaysCharges />
      )}
    </>
  );

  return isLargerThan1280 ? (
    <Box
      border="2px solid"
      borderColor="gray.lightBorder"
      padding={`${themeSizes._8px} ${themeSizes._16px}`}
      borderRadius="6px !important"
    >
      <Text variant="lgBold">{displayName}</Text>
      <Text variant="lgBold">
        {`${address.streetAddress1} ${address.city}, ${address.region} ${address.postalCode}`}
      </Text>
      {expandedDetails}
    </Box>
  ) : (
    <>
      { isLargerThan480 && (
        <Box
          borderBottom="4px solid"
          borderColor="gray.lightBorder"
          paddingBottom={sizes._18px}
          marginBottom={sizes._16px}
        >
          <Box
            border="2px solid"
            borderColor="gray.lightBorder"
            borderRadius="md"
            padding={sizes._12px}
            marginBottom={sizes._12px}
          >
            <Grid templateColumns="3fr 5px 1fr">
              <GridItem>
                <Text variant="mdBold">{size} Unit</Text>
                <Address address={address} />
              </GridItem>
              <GridItem>
                <div className={style.divider} />
              </GridItem>
              <GridItem align="right">
                <Text variant="mdBold">
                  {`$${parseFloat(effectiveRate) === 0 ? baseRate : effectiveRate}`}
                </Text>
                <Text
                  variant="xs"
                  color="gray.lightText"
                  sx={{ lineHeight: sizes._18px }}
                >
                  Monthly Rent
                </Text>
                {parseFloat(baseRate) > parseFloat(effectiveRate)
                  && parseFloat(effectiveRate) !== 0 && (
                    <Text
                      variant="xs"
                      sx={{ textDecoration: 'line-through' }}
                      color="gray.lightText"
                    >
                      {`$${baseRate}`}
                    </Text>
                )}
              </GridItem>
            </Grid>
          </Box>
        </Box>
      )}
      {/** Show Unit details on step 4 in mobile as expanded by default else show in collapse */}
      {
        step?.number === 4 && !isLargerThan480 ? (
        <Box
          borderBottom="4px solid"
          borderColor="gray.lightBorder"
          paddingBottom={sizes._18px}
          marginBottom={sizes._16px}
        >
          {expandedDetails}
        </Box>
        ) : isLargerThan480 && (
        <Box
          borderBottom="4px solid"
          borderColor="gray.lightBorder"
          paddingBottom={sizes._18px}
          marginBottom={sizes._16px}
        >
          <Accordion allowToggle>
              <AccordionItem>
                {({ isExpanded }) => (
                  <>
                    <Flex alignItems="left">
                      <AccordionButton
                        paddingLeft={0}
                        w="unset"
                        onClick={() => { handleViewDetailsClick(!isExpanded); }}
                      >
                        <Text variant="smBold" sx={{ textDecoration: 'underline' }}>
                          {`${isExpanded ? 'Hide' : 'View'} details`}
                        </Text>
                      </AccordionButton>
                    </Flex>
                    <AccordionPanel pb={4} marginTop={sizes._12px} p="0">
                      {expandedDetails}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
          </Accordion>
        </Box>
        )
      }
    </>
  );
};

UnitDetails.defaultProps = defaultProps;

export default UnitDetails;
