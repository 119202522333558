import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Text } from '@chakra-ui/react';
import { add } from 'date-fns';
import Submit from './Submit';
import { fetchInitializationData } from '../modules/api';
import useUnitData from '../hooks/UseUnitData';
import useCostData from '../hooks/UseCostData';
import InitialLoadingScreen from '../components/InitialLoadingScreen';
import { sizes } from '../theme';
import { useConfig } from '../contexts/ConfigProvider';
import { containsProperty } from '../modules/utils';
import { bugsnagClient } from '../index';
import { EVENTS_NAME } from '../modules/events';

const MAX_RETRIES = 3;
const RETRY_DELAY = 3000;

const MoveInContainer = ({ params }) => {
  const {
    setData: setUnitData,
    data: { isLoaded },
  } = useUnitData();
  const { configuration, triggerEvent } = useConfig();
  const { setUnitInfo } = useCostData();
  const { setValue } = useFormContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  const fetchData = () => {
    fetchInitializationData({ ...params, returnUrl: window.location.href })
      .then((res) => res.json())
      .then(({ rental }) => {
        const fmsProvider = rental.facility.alias?.split(':')[2];
        // 30 days is default value if futureMoveinLimitInDays is not set
        const defaultMoveInDays = fmsProvider === 'se' ? 0 : 30;
        const allowedDateMoveInAhead = containsProperty(
          rental.unit,
          'futureMoveinLimitInDays',
        )
          ? rental.unit.futureMoveinLimitInDays
          : defaultMoveInDays;
        if (!rental || rental?.error) {
          setTimeout(() => {
            triggerEvent(EVENTS_NAME.ERROR, {
              action: 'initialization',
              errors: [rental?.error],
            });
            setRetryCount(retryCount + 1);
          }, RETRY_DELAY);
        } else {
          setUnitInfo({
            unitId: rental.unit.id,
            facilityId: rental.facility.id,
            reservationId: rental.reservation.id,
          });
          setUnitData({
            ...rental,
            isLoaded: true,
            maxDate: add(new Date(), {
              days: allowedDateMoveInAhead,
            }),
            allowedDateMoveInAhead,
          });
          if (rental.facility.insuranceSettings.required) {
            setValue('insuranceRequired', true);
          }
          // if needed from main response then uncomment bottom lines
          // if (rental.reservation.formConfig.showSecurityQuestion) {
          //   setValue('securityQuestionsRequired', true);
          // }
          setValue(
            'securityQuestionsRequired',
            configuration.providerConfig.showSecurityQuestion,
          );
          setIsLoading(false);
        }
      })
      .catch((e) => {
        triggerEvent(EVENTS_NAME.ERROR, { action: 'initialization', errors: [e.message] });
        if (retryCount < MAX_RETRIES) {
          setTimeout(() => {
            setRetryCount(retryCount + 1);
          }, RETRY_DELAY);
        } else {
          setError(true);
          bugsnagClient.notify(e, (ev) => { ev.addMetadata('omi-widget', params); });
          triggerEvent(EVENTS_NAME.ERROR, { action: 'initialization', errors: [e.message] });
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    setIsLoading(true);
    if (retryCount >= MAX_RETRIES) {
      bugsnagClient.notify(new Error('Error Loading basic details in Movein.js'), (ev) => { ev.addMetadata('omi-widget', params); });
      setError(true);
      setIsLoading(false);
    } else {
      fetchData();
    }
  }, [retryCount]);

  if (error) {
    return (
      <Box py={sizes._12px} px={sizes._12px}>
        <Text variant="mdBold">
          There was an issue loading the online move-in application. Please
          check your email for instructions to move-in online or contact the
          facility for help. We apologize for the inconvenience.
        </Text>
      </Box>
    );
  }

  if (!isLoading && !isLoaded) {
    return null;
  }

  if (isLoading) {
    return <InitialLoadingScreen />;
  }

  return <Submit />;
};

export default MoveInContainer;
