import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import ThemeProvider from '../../contexts/ThemeProvider';
import FormProvider from '../../contexts/FormProvider';
import UnitDataProvider from '../../contexts/UnitDataProvider';
import CostDataProvider from '../../contexts/CostDataProvider';
import SchemaProvider from '../../contexts/SchemaProvider';
import WidthProvider from '../../contexts/WidthProvider';
import MoveIn from '../../containers/MoveIn';
import widgetParams from '../../../mocks/widgetParams';
import { ConfigurationProvider } from '../../contexts/ConfigProvider';

const MAINTENANCE_MODE = false;

const App = (params) => {
  if (MAINTENANCE_MODE || params?.enableMaintenanceMode === 'true') {
    return (
      <ThemeProvider>
        <Box p="10px">
          <Text variant="lgBold" pb="12px">
            Online move-in is temporarily unavailable.
          </Text>
          <Text variant="md">
            Our online move-in experience is currently unavailable due to a
            technical issue on our end. Your unit has been reserved and you may
            try to move-in online once the issue has been resolved. You can do
            so via a link in your reservation confirmation email. If you need
            immediate assistance please contact the facility directly. Sorry for
            the inconvenience.
          </Text>
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ConfigurationProvider
      params={process.env.NODE_ENV === 'development' ? widgetParams : params}
    >
      <ThemeProvider>
        <UnitDataProvider>
          <CostDataProvider>
            <SchemaProvider>
              <FormProvider>
                <WidthProvider>
                  <div
                    className="OMI_CSS_CONTAINER"
                  >
                      <MoveIn
                        params={
                          process.env.NODE_ENV === 'development'
                            ? widgetParams
                            : params
                        }
                      />
                  </div>
                </WidthProvider>
              </FormProvider>
            </SchemaProvider>
          </CostDataProvider>
        </UnitDataProvider>
      </ThemeProvider>
    </ConfigurationProvider>
  );
};

export default App;
