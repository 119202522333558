import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import FormRadio from '../FormRadio';
import FormMulti from '../FormMulti';
import VehicleForm from '../VehicleForm';

const propTypes = {};
const defaultProps = {};

const Vehicles = () => {
  const { control } = useFormContext();
  const hasVehicles = useWatch({ control, name: 'hasVehicle' }) === 'yes';

  return (
    <>
      <FormRadio
        name="hasVehicle"
        options={[{ value: 'yes', description: 'Yes' }, { value: 'no', description: 'No' }]}
        subHeader="Add an automobile, truck, motorcycle, SUV, boat, RV, 4-wheeler, jet ski, or snowmobille stored in the unit."
        title="Are You Storing A Vehicle?"
      />
      {hasVehicles ? (
        <FormMulti
          descriptor="vehicle"
          name="vehicles"
          title="Stored Vehicle"
        >
          <VehicleForm />
        </FormMulti>
      ) : null}
    </>
  );
};

Vehicles.propTypes = propTypes;
Vehicles.defaultProps = defaultProps;

export default Vehicles;
