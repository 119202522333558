import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fetchCostData } from '../modules/api';
import useCostData from '../hooks/UseCostData';
import useUnitData from '../hooks/UseUnitData';
import { useConfig } from '../contexts/ConfigProvider';
import { EVENTS_NAME } from '../modules/events';

const MAX_RETRIES = 3;
const RETRY_DELAY = 3000;
const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node, PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

const defaultProps = {};

const CostData = ({ children }) => {
  const { unitInfo, setData, setIsLoading } = useCostData();
  const { data: { formConfig, facility } } = useUnitData();
  const { facilityId, unitId, moveInDate, insurance, lock } = unitInfo;
  const [retries, setRetries] = useState(0);
  const { triggerEvent } = useConfig();
  useEffect(() => {
    if (unitInfo.facilityId && unitInfo.unitId && unitInfo.moveInDate && formConfig && facility
    ) {
      setIsLoading(true);
      fetchCostData(
        unitInfo,
        formConfig.insuranceKey,
        facility?.timezone,
        facility?.geo,
        facility?.invoicePeriod,
      )
        .then((res) => res.json())
        .then(({ charges }) => {
          setIsLoading(false);

          if (charges.error) {
            if (retries < MAX_RETRIES) {
              setTimeout(() => {
                setRetries(retries + 1);
              }, RETRY_DELAY);
            } else {
              triggerEvent(EVENTS_NAME.ERROR, { action: 'retrieveCosts', errors: [charges.message] });
            }
          } else {
            setData(charges);
          }
        })
        .catch((e) => {
          if (retries < MAX_RETRIES) {
            setTimeout(() => {
              setRetries(retries + 1);
            }, RETRY_DELAY);
          } else {
            triggerEvent(EVENTS_NAME.ERROR, { action: 'retrieveCosts', errors: [e.message] });
            setIsLoading(false);
          }
        });
    }
  }, [facilityId, unitId, moveInDate, insurance, lock, formConfig, retries]);

  return children;
};

CostData.propTypes = propTypes;
CostData.defaultProps = defaultProps;

export default CostData;
