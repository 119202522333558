import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import App from './components/App';
import FallBackComponent from './FallbackComponent';

// Find all widget divs
const widgetDivs = document.querySelectorAll('.storable-webapps-onlinemovein');

// eslint-disable-next-line import/prefer-default-export
export const bugsnagClient = Bugsnag.start({
  apiKey: process.env.BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.BUGSNAG_ENVIRONMENT,
});

BugsnagPerformance.start({
  apiKey: process.env.BUGSNAG_API_KEY,
  bugsnag: bugsnagClient,
});

const BugSnagErrorBoundary = bugsnagClient
  .getPlugin('react')
  .createErrorBoundary(React);

// Inject our React App into each class
widgetDivs.forEach((div) => {
  ReactDOM.render(
    <React.StrictMode>
      <BugSnagErrorBoundary
        onError={(ev) => {
          ev.addMetadata('omi-widget', div.dataset);
        }}
        FallbackComponent={FallBackComponent}
      >
        <App {...div.dataset} />
      </BugSnagErrorBoundary>
    </React.StrictMode>,
    div,
  );
});

// const injectApp = (params) => {
//   const container = document.getElementById('storable-webapps-onlinemovein');
//   const root = ReactDOM.createRoot(container);

//   root.render(
//     <React.StrictMode>
//       <App params={params} />
//     </React.StrictMode>,
//   );
// };

// const ONLINE_MOVE_IN = {
//   initialize: (params) => injectApp(params),
// };

window.STORABLE_WEBAPPS = {
  ...(window.STORABLE_WEBAPPS || {}),
};
