import React from 'react';
import { createIcon } from '@chakra-ui/icons';

const ShieldCheckIcon = createIcon({
  displayName: 'ShieldCheckIcon',
  viewBox: '0 0 18 20',
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.98098 2.6784L6.08188 4.5976C6.03069 4.63149 5.97702 4.66149 5.92134 4.68735C5.47772 4.89334 4.96939 4.90525 4.51829 4.72308L2.63061 4.09504V12.1852C2.63061 13.8618 3.26613 15.1125 4.28198 15.9857C5.3233 16.8807 6.84567 17.444 8.68052 17.5327L8.68392 17.5329C10.652 17.6335 12.3449 17.1568 13.5138 16.2821C14.6499 15.432 15.3691 14.1538 15.3696 12.4105C15.3696 12.4099 15.3696 12.4093 15.3696 12.4087L15.3459 4.09265L13.4399 4.72387C12.9943 4.90261 12.4929 4.8908 12.0547 4.68843C11.9988 4.66265 11.945 4.63271 11.8937 4.59885L8.98098 2.6784ZM9.9471 0.376061C9.36453 -0.0237461 8.59434 -0.0234654 8.01204 0.376903L5.07411 2.32181L2.4556 1.45061L2.45226 1.44951C1.92121 1.27451 1.33646 1.36558 0.88349 1.69709C0.434806 2.02547 0.169046 2.55138 0.176674 3.11209V12.1852C0.176674 14.5576 1.10963 16.4948 2.68243 17.8467C4.22944 19.1763 6.31533 19.875 8.56064 19.9837C10.965 20.1062 13.2589 19.5377 14.984 18.2469C16.7418 16.9316 17.8236 14.9237 17.8236 12.4087L17.797 3.10979C17.8039 2.54994 17.5382 2.02502 17.0902 1.69709C16.6372 1.36557 16.0524 1.27451 15.5214 1.44951L12.8946 2.31946L9.9471 0.376061Z"
        fill="#2C9A00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9231 6.89128C13.3996 7.37308 13.3954 8.14994 12.9136 8.62645L8.35434 13.1356C7.8763 13.6084 7.10679 13.6084 6.62875 13.1356L5.11345 11.6369C4.63164 11.1604 4.62735 10.3835 5.10386 9.90174C5.58037 9.41993 6.35723 9.41564 6.83903 9.89215L7.49155 10.5375L11.188 6.88169C11.6698 6.40518 12.4466 6.40948 12.9231 6.89128Z"
        fill="#2C9A00"
      />
    </>
  ),
});

export default ShieldCheckIcon;
