import React from 'react';
import FormRadio from '../FormRadio';

const MilitaryStatus = () => (
  <FormRadio
    name="militaryStatus"
    options={[{ value: 'yes', description: 'Yes' }, { value: 'no', description: 'No' }]}
    title="Are You An Active Member of the Military?"
  />
);

export default MilitaryStatus;
