import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from '@chakra-ui/react';
import { useFormContext, useController } from 'react-hook-form';
import { useIMask } from 'react-imask';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { sizes } from '../../theme';
import useSchema from '../../hooks/UseSchema';
import { useConfig } from '../../contexts/ConfigProvider';
import { EVENTS_NAME } from '../../modules/events';
import { eventPayload } from '../../modules/utils';

const removeLastPassFrame = () => {
  const lastpass = document.querySelector('[data-lastpass-root]');
  if (lastpass) {
    lastpass.remove();
  }
};

const propTypes = {
  autoComplete: PropTypes.string,
  icon: PropTypes.object,
  label: PropTypes.string,
  mask: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  rules: PropTypes.object,
  onChange: PropTypes.func,
};

const defaultProps = {
  autoComplete: undefined,
  icon: undefined,
  label: undefined,
  mask: undefined,
  placeholder: undefined,
  width: '100%',
  rules: {},
  onChange: undefined,
};

const FormInput = ({
  autoComplete,
  icon,
  label,
  mask,
  name,
  placeholder,
  rules,
  width,
  onChange,
  ...rest
}) => {
  const { formState: { errors, control }, getValues } = useFormContext();
  const [inputPlaceholder, updateInputPlaceholder] = useState(' ');
  const [lastValues, setLastValues] = useState({});
  const { getIsRequired } = useSchema();
  const { field } = useController({ name, control, rules });
  const { ref } = useIMask({ mask, unmask: true }, { onAccept: (value) => field.onChange(value) });

  const { triggerEvent } = useConfig();

  useEffect(() => {
    removeLastPassFrame();
    document.addEventListener('scrollend', removeLastPassFrame);
    return () => {
      document.removeEventListener('scrollend', removeLastPassFrame);
    };
  }, []);

  useEffect(() => {
    setLastValues(cloneDeep(getValues()));
  }, [control]);

  const handleBlur = (e) => {
    updateInputPlaceholder(' ');
    field.onBlur(e);
    const fieldName = e.target.name;
    triggerEvent(
      EVENTS_NAME.FORM_FIELD_CHANGE,
      eventPayload(fieldName, lastValues, field.value),
    );
    setLastValues((prev) => ({
      ...prev,
      [fieldName]: field.value, // Update last values with the current state
    }));
  };
  return (
    <FormControl
      isInvalid={get(errors, name)}
      marginBottom={sizes._12px}
      variant="floating"
      width={width}
      isRequired={getIsRequired(name)}
    >
      <InputGroup>
        {icon ? <InputLeftElement pointerEvents="none" children={icon} /> : <div />}
        <Input
          {...rest}
          {...field}
          autoComplete={autoComplete || 'always-off'}
          id={name}
          onChange={(e) => {
            field.onChange(e); // Ensure React Hook Form updates state
            if (onChange) { onChange(e); } // Call additional onChange handler if provided
          }}
          onBlur={handleBlur}
          onFocus={() => updateInputPlaceholder(placeholder || inputPlaceholder)}
          placeholder={inputPlaceholder}
          ref={ref}
        />
        <FormLabel onClick={() => ref.current.focus()}>
          <Text variant="md">
            {label}
            {rules?.required && (
              <Text as="span" color="red" marginLeft="4px">
                *
              </Text>
            )}
          </Text>
        </FormLabel>
      </InputGroup>
      <FormErrorMessage>
        <Text variant="sm" marginTop={sizes._4px} color="red.accent">
          {get(errors, name)?.message}
        </Text>
      </FormErrorMessage>
    </FormControl>
  );
};

FormInput.propTypes = propTypes;
FormInput.defaultProps = defaultProps;

export default FormInput;
