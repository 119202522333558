import React from 'react';
import { createIcon } from '@chakra-ui/icons';

const Tooltip = createIcon({
  displayName: 'Tooltip',
  viewBox: '0 0 16 16',
  path: (
    <path d="M8.00019 0.730469C3.98577 0.730469 0.730957 3.98528 0.730957 7.9997C0.730957 12.0141 3.98577 15.2689 8.00019 15.2689C12.0146 15.2689 15.2694 12.0141 15.2694 7.9997C15.2694 3.98528 12.0146 0.730469 8.00019 0.730469ZM9.51461 11.9973C9.13961 12.1439 8.84154 12.2569 8.61798 12.3338C8.39442 12.4108 8.13721 12.4492 7.84154 12.4492C7.38961 12.4492 7.03625 12.3386 6.78384 12.1175C6.53384 11.8987 6.40884 11.6175 6.40884 11.2761C6.40884 11.1439 6.41846 11.0093 6.43769 10.8723C6.45452 10.7329 6.48577 10.579 6.52663 10.4035L6.99538 8.7497C7.03625 8.59105 7.07231 8.44201 7.10115 8.30018C7.13 8.15836 7.14202 8.02855 7.14202 7.91076C7.14202 7.70162 7.09875 7.55259 7.01221 7.47085C6.92327 7.38672 6.7574 7.34585 6.51221 7.34585C6.39202 7.34585 6.26702 7.36509 6.13961 7.40114C6.01221 7.4396 5.90404 7.47566 5.81269 7.50932L5.93769 6.9997C6.24298 6.8747 6.53625 6.76893 6.8175 6.67999C7.09634 6.59105 7.36317 6.54537 7.61077 6.54537C8.06029 6.54537 8.40884 6.65595 8.65163 6.8723C8.89442 7.08864 9.01702 7.3723 9.01702 7.71845C9.01702 7.79057 9.00981 7.91797 8.99298 8.09826C8.97615 8.27855 8.9449 8.44441 8.89923 8.59826L8.43288 10.2473C8.39442 10.3795 8.36077 10.531 8.32952 10.6992C8.30067 10.8675 8.28625 10.9973 8.28625 11.0838C8.28625 11.3026 8.33432 11.454 8.43288 11.5334C8.52904 11.6127 8.69971 11.6511 8.9425 11.6511C9.05548 11.6511 9.18288 11.6319 9.32711 11.5935C9.46894 11.5526 9.57231 11.5189 9.63721 11.4877L9.51461 11.9973ZM9.43048 5.30259C9.21413 5.50451 8.95211 5.60547 8.64682 5.60547C8.34154 5.60547 8.07711 5.50451 7.85836 5.30259C7.63961 5.10066 7.53144 4.85547 7.53144 4.56941C7.53144 4.28335 7.64202 4.03576 7.85836 3.83143C8.07711 3.6271 8.34154 3.52614 8.64682 3.52614C8.95211 3.52614 9.21413 3.6271 9.43048 3.83143C9.64923 4.03576 9.7574 4.28335 9.7574 4.56941C9.7574 4.85547 9.64923 5.10066 9.43048 5.30259Z" />
  ),
});

export default Tooltip;
