import React from 'react';
import { GridItem, SimpleGrid, Text } from '@chakra-ui/react';
import { sizes } from '../../theme';
import FormInput from '../FormInput';
import StateSelector from '../StateSelector';

const MailingInformation = () => (
  <>
    <Text variant="mdBold" marginBottom={sizes._8px}>Mailing Information</Text>
    <SimpleGrid columns={{ sm: 1, md: 2 }} spacingX={2}>
      <FormInput
        name="mailing.firstName"
        autoComplete="given-name"
        label="First name"
      />
      <FormInput
        name="mailing.lastName"
        autoComplete="family-name"
        label="Last name"
      />
      <FormInput
        name="mailing.addressOne"
        autoComplete="address-line1"
        label="Street address"
      />
      <FormInput
        name="mailing.addressTwo"
        autoComplete="address-line2"
        label="Unit/Apartment number"
      />
    </SimpleGrid>
    <SimpleGrid columns={{ sm: 2, md: 3 }} spacingX={2}>
      <GridItem colSpan={{ sm: 2, md: 1 }}>
        <FormInput
          name="mailing.city"
          autoComplete="address-level2"
          label="City"
        />
      </GridItem>
      <StateSelector
        name="mailing.state"
      />
      <FormInput
        name="mailing.zipCode"
        autoComplete="postal-code"
        label="ZIP code"
        type="number"
        pattern="[0-9]*"
      />
    </SimpleGrid>
  </>
);

export default MailingInformation;
